import React, { useEffect } from 'react';
import Route, { HomeRoute, PrivateRoute } from './routes';
import HomePage from './containers/HomePage';
import ContactPage from './containers/ContactPage';
import AboutPage from './containers/AboutPage';
import MissionPage from './containers/MissionPage';
import FaqsPage from './containers/FaqsPage';
import PartnersPage from './containers/PartnersPage';
import ServicesPage from './containers/ServicesPage';
import CongregationContinuityPage from './containers/CongregationContinuityPage';
import CongregationConnectionsPage from './containers/CongregationConnectionsPage';
import StaffPage from './containers/StaffPage';
import BoardAdvisorsPage from './containers/BoardAdvisorsPage';
import PressPage from './containers/PressPage';
import NewslettersPage from './containers/NewslettersPage';
import CaseStudiesPage from './containers/CaseStudiesPage';
import ClientsPage from './containers/ClientsPage';
import WebinarsPage from './containers/WebinarsPage';
import VideosPage from './containers/VideosPage';
import TestimonialsPage from './containers/TestimonialsPage';
import ForumPage from './containers/ForumPage';
import DonationPage from './containers/DonationPage';
import DonationThanksPage from './containers/DonationPage/thanks';
import InKindSupportPage from './containers/InKindSupportPage';
import PledgePage from './containers/PledgePage';
import TributePage from './containers/TributePage';
import BlogPage from './containers/BlogPage';
import YouTubePage from './containers/YoutubePage';
import VideoProgramsPage from './containers/VideoProgramsPage';
import JoinPage from './containers/JoinPage';
import { useHistory } from 'react-router-dom';

import NotFoundPage from './containers/NotFoundPage';
import { BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';

// import { withTracker } from './withTracker';

export const ScrollToTop = () => {
	const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};

const App = () => {
	return (
		<>
			<Router>
				<React.Fragment>
					<ScrollToTop />
					<Switch>
						<HomeRoute exact path="/" component={HomePage} />

						<HomeRoute path="/About" component={AboutPage} />
						<HomeRoute path="/Mission" component={MissionPage} />
						<HomeRoute path="/Partners" component={PartnersPage} />
						<HomeRoute path="/Services" component={ServicesPage} />
						<HomeRoute path="/Faq" component={FaqsPage} />
						<HomeRoute path="/Congregation-Continuity" component={CongregationContinuityPage} />
						<HomeRoute path="/Congregation-Connections" component={CongregationConnectionsPage} />
						<HomeRoute path="/Staff" component={StaffPage} />
						<HomeRoute path="/Board-Advisors" component={BoardAdvisorsPage} />
						<HomeRoute path="/Press" component={PressPage} />
						<HomeRoute path="/Newsletters" component={NewslettersPage} />
						<HomeRoute path="/Case-Studies" component={CaseStudiesPage} />
						<HomeRoute path="/Clients" component={ClientsPage} />
						{/* <HomeRoute path="/FAQ" component={FAQPage} /> */}
						<HomeRoute exact path="/Testimonials" component={TestimonialsPage} />
						<HomeRoute path="/Testimonials/videos" component={YouTubePage} />
						<HomeRoute path="/Video-Programs" component={VideoProgramsPage} />
						<HomeRoute path="/Donation" component={DonationPage} />
						<HomeRoute path="/Donation-thanks" component={DonationThanksPage} />
						<HomeRoute path="/In-Kind-Support" component={InKindSupportPage} />
						<HomeRoute path="/Pledge" component={PledgePage} />
						<HomeRoute path="/Tribute" component={TributePage} />
						<HomeRoute path="/Contact" component={ContactPage} />
						<HomeRoute path="/Join" component={JoinPage} />
						<HomeRoute path="/Videos" component={VideosPage} />
						<HomeRoute path="/Testimonials" component={TestimonialsPage} />
						<HomeRoute path="/Webinars" component={WebinarsPage} />
						<HomeRoute path="/Blog" component={BlogPage} />

						<PrivateRoute exact path="/Login" component={ForumPage} />
						<HomeRoute path="/Forum" component={ForumPage} />

						<Route component={NotFoundPage} />
					</Switch>
				</React.Fragment>
			</Router>
		</>
	);
};

export default App;
