import React, { useState } from 'react';
import Parallax from '../../components/LazyParallax';
import Image from '../../images/partners.jpg';

import img01 from './shalomaustin.png';
import img02 from './buffalojewishfederation.png';
import img03 from './jewishcharleston.png';
import img04 from './jewishcharlotte.png';
import img05 from './jewishcincinnati.png';
import img06 from './jewishcleveland.png';
import img07 from './jewishdallas.png';
import img08 from './jewishdayton.png';
import img09 from './jewishdetroit.png';
import img10 from './shalomgreensboro.png';
import img11 from './houstonjewish.jpg';
import img12 from './jewishindianapolis.png';
import img13 from './jewisharkansas.png';
import img14 from './jewishfedny.png';
import img15 from './jewishomaha.png';
import img16 from './jewishpgh.png';
import img17 from './jewishrichmond.png';
import img18 from './jfedstl.png';
import img19 from './simokyfed.png';
import img20 from './jewishfederationcny.png';

import image1 from './aja-logo-top.png';
import image2 from './JFNA-network.png';
import image3 from './JFNA.png';
import image4 from './urj-logo.png';
import image5 from './uscj-logo.png';
import image6 from './coastalcommunityfoundation.JPG';
import image7 from './fftc.jpg';
import image8 from './jewishcolumbus.png';
import image9 from './djcf.jpg';
import image10 from './houstonjewishfoundation.jpg';
import image11 from './jewishphilanthropies.png';
import image12 from './pajewishendowment.png';
import image13 from './jcfkc.png';
import image14 from './jfofoundation.JPG';
import image15 from './rjfoundation.png';
import image16 from './topjewishfoundation.jpg';
import image17 from './ajr-logo.jpg';
import { Helmet } from 'react-helmet';
import useGtag from '../../hooks/useGtag';

const Page = () => {
	useGtag();

	const national = [
		{ id: 1, image: image1, link: 'http://americanjewisharchives.org/' },
		{ id: 3, image: image3, link: 'https://www.jewishfederations.org/' },
		{ id: 2, image: image2, link: 'https://jewishfederations.org/network-communities' },
		{ id: 4, image: image4, link: 'https://urj.org/' },
		{ id: 5, image: image5, link: 'https://uscj.org/' },
		{ id: 17, image: image17, link: 'http://ajr.edu/' },
	];
	const federations = [
		{ id: 2, image: img02, link: 'https://buffalojewishfederation.org/' },
		{ id: 11, image: img11, link: 'https://www.houstonjewish.org/' },
		{ id: 13, image: img13, link: 'https://www.jewisharkansas.org/' },
		{ id: 3, image: img03, link: 'https://www.jewishcharleston.org/' },
		{ id: 4, image: img04, link: 'https://jewishcharlotte.org/' },
		{ id: 5, image: img05, link: 'https://www.jewishcincinnati.org/' },
		{ id: 6, image: img06, link: 'https://www.jewishcleveland.org/' },
		{ id: 7, image: img07, link: 'https://www.jewishdallas.org/' },
		{ id: 8, image: img08, link: 'https://jewishdayton.org/' },
		{ id: 9, image: img09, link: 'https://jewishdetroit.org/' },
		{ id: 20, image: img20, link: 'https://jewishfederationcny.org/' },
		{ id: 14, image: img14, link: 'https://www.jewishfedny.org/' },
		{ id: 12, image: img12, link: 'https://www.jewishindianapolis.org/' },
		{ id: 15, image: img15, link: 'https://www.jewishomaha.org/' },
		{ id: 16, image: img16, link: 'https://jewishpgh.org/' },
		{ id: 17, image: img17, link: 'https://www.jewishrichmond.org/' },
		{ id: 18, image: img18, link: 'https://www.jfedstl.org/' },
		{ id: 1, image: img01, link: 'https://shalomaustin.org/federation' },
		{ id: 10, image: img10, link: 'https://www.shalomgreensboro.org/' },
		{ id: 19, image: img19, link: 'http://www.simokyfed.com/' },
	];
	const presses = [
		{ id: 6, image: image6, link: 'https://coastalcommunityfoundation.org/' },
		{ id: 9, image: image9, link: 'https://www.djcf.org/' },
		{ id: 7, image: image7, link: 'https://www.fftc.org/fcjc' },
		{ id: 10, image: image10, link: 'http://www.houstonjewishfoundation.com/' },
		{ id: 13, image: image13, link: 'https://www.jcfkc.org/' },
		{ id: 8, image: image8, link: 'http://www.jewishcolumbus.org/' },
		{ id: 3, image: image3, link: 'https://www.jewishfederations.org/' },
		{ id: 11, image: image11, link: 'https://www.jewishphilanthropies.org/' },
		{ id: 14, image: image14, link: 'https://www.jfofoundation.org/' },
		{ id: 12, image: image12, link: 'https://pajewishendowment.org/en/' },
		{ id: 15, image: image15, link: 'https://www.rjfoundation.org/' },
		{ id: 16, image: image16, link: 'https://topjewishfoundation.org/' },
	];

	return (
		<div>
			<Helmet>
				<title>Partners : Jewish Community Legacy Project</title>
			</Helmet>
			<Parallax source={Image}>
				<h1>
					PARTNERS
					{/* <br />
					<span className="subTittle">Lorem ipsum dolor sit amet consectetur</span> */}
				</h1>
			</Parallax>
			<div className="container row">
				<br />
				<br />
				<h2 className="JCLP-blue-text">National Partners:</h2>
				{national.map((p, i) => (
					<div className="col s6 m4 l3" key={i}>
						<a href={p.link} target="_blank">
							<div className="card square">
								<div className="card-content content valign-wrapper">
									<img src={p.image} style={{ maxWidth: '100%', margin: '0 auto' }} alt="" />
								</div>
							</div>
						</a>
					</div>
				))}
				<div className="clearfix"></div>
				<br />
				<br />
				<h2 className="JCLP-blue-text">Jewish Federations:</h2>
				{federations.map((p, i) => (
					<div className="col s6 m4 l3" key={i}>
						<a href={p.link} target="_blank">
							<div className="card square">
								<div className="card-content content valign-wrapper">
									<img src={p.image} style={{ maxWidth: '100%', margin: '0 auto' }} alt="" />
								</div>
							</div>
						</a>
					</div>
				))}
				<div className="clearfix"></div>
				<br />
				<br />
				<h2 className="JCLP-blue-text">Community Partners:</h2>
				{presses.map((p, i) => (
					<div className="col s6 m4 l3" key={i}>
						<a href={p.link} target="_blank">
							<div className="card square">
								<div className="card-content content valign-wrapper">
									<img src={p.image} style={{ maxWidth: '100%', margin: '0 auto' }} alt="" />
								</div>
							</div>
						</a>
					</div>
				))}
				<div className="clearfix"></div>
				<br />
				<br />
			</div>
		</div>
	);
};

export default Page;
