import React from 'react';

export const categories = [
	{ id: 2, parentId: 1, title: 'Congregation 1', description: 'All members must read these rules before posting on the forums. The majority of your questions about signatures and why your posts might be moderated are answered in here', date: new Date() },
	{ id: 3, parentId: 1, title: 'Congregation 2', description: 'All members must read these rules before posting on the forums. The majority of your questions about signatures and why your posts might be moderated are answered in here', date: new Date() },
	{ id: 4, parentId: 1, title: 'Congregation 3', description: 'All members must read these rules before posting on the forums. The majority of your questions about signatures and why your posts might be moderated are answered in here', date: new Date() },
	{ id: 5, parentId: 0, title: 'Congregation B', description: 'All members must read these rules before posting on the forums. The majority of your questions about signatures and why your posts might be moderated are answered in here', date: new Date() },
	{ id: 6, parentId: 5, title: 'Congregation 5', description: 'All members must read these rules before posting on the forums. The majority of your questions about signatures and why your posts might be moderated are answered in here', date: new Date() },
	{ id: 7, parentId: 5, title: 'Congregation 6', description: 'All members must read these rules before posting on the forums. The majority of your questions about signatures and why your posts might be moderated are answered in here', date: new Date() },
	{ id: 8, parentId: 5, title: 'Congregation 7', description: 'All members must read these rules before posting on the forums. The majority of your questions about signatures and why your posts might be moderated are answered in here', date: new Date() },
	{ id: 9, parentId: 5, title: 'Congregation 8', description: 'All members must read these rules before posting on the forums. The majority of your questions about signatures and why your posts might be moderated are answered in here', date: new Date() },
];

export const threads = [
	{
		categoryid: 2,
		title: 'Would you buy this item?',
		authorid: 1,
		date: new Date(),
	},
	{
		categoryid: 2,
		title: 'Would you buy this item?',
		authorid: 2,
		date: new Date(),
	},
	{
		categoryid: 2,
		title: 'What is the price of this item?',
		authorid: 1,
		date: new Date(),
	},
	{
		categoryid: 1,
		title: 'What is the price of this item?',
		authorid: 2,
		date: new Date(),
	},
];

export const comments = [
	{
		threadid: 2,
		title: 'I would not',
		authorid: 1,
		date: new Date(),
	},
	{
		threadid: 2,
		title: 'I would buy that',
		authorid: 2,
		date: new Date(),
	},
	{
		threadid: 2,
		title: 'What is the price of this item?',
		authorid: 1,
		date: new Date(),
	},
	{
		threadid: 1,
		title: 'What is the price of this item?',
		authorid: 2,
		date: new Date(),
	},
];
