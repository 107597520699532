import React from 'react';
import interHome from '../../images/inter-hero01.jpg';
import Parallax from '../../components/LazyParallax';
import PaImg from '../../images/board.jpg';
import { Helmet } from 'react-helmet';
import useGtag from '../../hooks/useGtag';

const Page = () => {
	useGtag();

	return (
		<div>
			<Helmet>
				<title>Board of Trustees : Jewish Community Legacy Project</title>
			</Helmet>
			<Parallax source={PaImg}>
				<h1>BOARD OF TRUSTEES</h1>
			</Parallax>
			<div className="container content800">
				<br />
				<br />
				{/* <img src={interHome} /> */}
				<h2 className="JCLP-blue-text">Officers</h2>
				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Leslie Dannin Rosenthal</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Board Chair, South Orange, NJ
						</p>
						<p>Chair: Network of Independent Communities Advisory Committee, Jewish Federations of North America (JFNA); Board Member: Jewish Federations of North America (JFNA); Past President: Jewish Federation of Greater Metro West, NJ; Past President: Oheb Shalom Congregation, South Orange, NJ.</p>
					</div>
				</div>
				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Samuel Morris Bernstine</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Vice President/Secretary, New Galilee, PA
						</p>
						<p>
							Past President: Temple Hadar Israel, New Castle, PA; Principal, Management Consultant and Board Member: international business consultancy Kepner-Tregoe (KT); Past Board Member: Penn State Renaissance Scholarship Board; avid supporter of Penn State University and its athletics; Award recipient: Volunteer of the Year Award in 2010 and 2013, Penn State University, 2020
							Board Member of the Year award, Penn State College of Health & Human Development Alumni Society.
						</p>
					</div>
				</div>

				{/* <div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Michael Alan Berkelhammer</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Vice President/Secretary, Greensboro, NC
						</p>
						<p>Past President and Current Board Member: Beth David Synagogue; Past President and Current Board Member: Greensboro Jewish Federation; Past Chair and Current Executive Committee Member: Jewish Foundation of Greensboro; Past Board Member: NC Hillel; Past Board Member: Camp Ramah Darom; Past Board Member: Women’s Resource Center.</p>
					</div>
				</div> */}

				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">David I. Sarnat</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Treasurer, Atlanta, GA
						</p>
						<p>Past Executive Director: Jewish Federation of Greater Atlanta; Board Member: Georgia Innocence Project; Past Board Member: Jewish Kids Group; Past Board and Executive Committee Member: The Weber School; Past Board Member: Greenfield Hebrew Academy; Past Board Member: William Breman Jewish Home.</p>
					</div>
				</div>
				{/* <div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Michael Z. Kay</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Past Chair of the Board, Atlanta, GA
						</p>
						<p>Executive Committee Member: The Weber School; Past Board Chair: Jewish Family & Career Services; Past Chair: Community Foundation of Metropolitan Atlanta; Past Chair of Planning & Allocations Committee: United Way of Metropolitan Atlanta; Past Board Officer: Jewish Federation of Greater Atlanta.</p>
					</div>
				</div> */}

				<h2 className="JCLP-blue-text">Board of Directors</h2>

				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Marilyn Forman Chandler</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Greensboro, NC
						</p>
						<p>Executive Director, Greensboro Jewish Federation, Chair of Community Outreach and Board of Directors, Women of the Shoah: Jewish Placemaking, Wake Forest University Jewish Life Advisory Committee Member and Adjunct Faculty Member, Guilford College.</p>
					</div>
				</div>
				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Dana Herman, PhD</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Cincinnati, OH
						</p>
						<p>
							A native of Val-David, Quebec, Dana Herman holds a PhD in modern Jewish history from McGill University, Montreal. Since 2007, she has served as managing editor and academic associate at The Jacob Rader Marcus Center of the American Jewish Archives and is currently its Director of Research & Collections. She has lectured in Amsterdam, Berlin, Jerusalem, New York, and
							Cincinnati, and has published articles and edited volumes in the field of twentieth-century Jewish history.
						</p>
					</div>
				</div>
				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Etta Raye Hirsch</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Atlanta, GA
						</p>
						<p>Board Member: Jewish Women’s Fund of Atlanta; Board Member: Atlanta Women’s Foundation; Board Member: Jewish Home Life Communities Foundation; Board Member: Atlanta Scholars Kollel; Board Member: Temima High School for Girls; Past Board Member: The Epstein School; Past Board Member: Congregation Beth Jacob.</p>
					</div>
				</div>
				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">David Hoguet</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Cincinnati, OH
						</p>
						<p>Board Chair: Plum Street Temple Historic Preservation Fund; Member: Wise Temple Investment Committee; Board Member: Talbert House; Board Member: GRAD Cincinnati; Past Board Member: Seven Hills School; Past Board Member: Public Media Connect – CET/Think TV.</p>
					</div>
				</div>
				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Bernard King-Smith</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Kingston, NY
						</p>
						<p>International Board Member: United Synagogue of Conservative Judaism (USCJ); President: Hevre Kadisha of Ulster County (NY); Vice President Leadership Development: Ulster County Jewish Federation; Treasurer: Jewish Family Services of Ulster County; Past President and Board Member: Congregation Ahavath Israel, Kingston NY.</p>
					</div>
				</div>
				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Simone Knego</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Sarasota, FL
						</p>
						<p>
							Keynote Speaker and Author; Chair, Network of Independent Communities Advisory Committee, Jewish Federations of North America; Intermediate Federation Trustee, Jewish Federations of North America; Member, Board of Directors, The Jewish Federation of Sarasota-Manatee; Past Co-Chair, Jewish Federations of North America, National Young Leadership; Author of{' '}
							<a href="https://simoneknego.com/my-book/" target="_blank">
								The Extraordinary UnOrdinary You
							</a>
							.
						</p>
					</div>
				</div>
				{/* <div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Richard A. Klein</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Charlotte, NC
						</p>
						<p>Scriptwriter for 65 produced programs; Past President: Jewish Federation of Greater Charlotte; Past President: Beth Shalom congregation, Charlotte, NC; Past Vice President: Temple Beth El, Charlotte, NC; Past Board Member: Temple Israel, Charlotte, NC; Author: Putting the Fun into Fund Raising and children’s book Something to Crow About.</p>
					</div>
				</div> */}
				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Robert Loeb</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Houston, TX
						</p>
						<p>
							Past President: Temple B’Nai Israel, Victoria, TX; Past Board Chair: Victoria Business and Education Coalition; Past Board Chair: Victoria YMCA; Past Board Chair: Victoria Regional Museum Association; Past Board Chair: Victoria Preservation, Victoria County Club; Past Board Chair: Victoria County Navigation District; Past Board Chair: Texas Self Storage Association;
							Award recipient: two time recipient of YMCA CM Ferguson Award Volunteer of the Year and Victoria Rotary Citizen of the Year.
						</p>
					</div>
				</div>
				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Rabbi David Lyon</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Houston, TX
						</p>
						<p>
							Senior Rabbi, Congregation Beth Israel, Houston, TX; Board Member and Chair of Professional Development Committee: CCAR (Central Conference of America Rabbis); Past President: SWARR (Southwest Association of Reform Rabbis); Board and Community Impact Committee Member: United Way of Greater Houston Board; Board Member: Interfaith Ministries of Houston; Past Board Chair
							and Lifetime Honorary Board Member: Institute for Spirituality and Health at the Texas Medical Center.
						</p>
					</div>
				</div>
				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Marc Newman</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Floral Park, NY
						</p>
						<p>
							Board Member: Union for Reform Judaism (URJ) North America; Chair, URJ Legacy Congregation Committee; Board Member: Early Childhood Educators of Reform Judaism; Board Member: Reform Pension Fund, Pension and Welfare Funds of Local 638 United Association of Steamfitters, Metal Trades Division; Chair of URJ Early Childhood Education and Young Family Engagement; Past Board
							Chair: URJ Eisner Camp; Past Board Chair: NYC UJA Federation Association of Jewish Sponsored Camps.
						</p>
					</div>
				</div>
				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Howard M. Rieger</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Chicago, IL and Pittsburgh, PA
						</p>
						<p>
							Past President/CEO, Jewish Federations of North America; Past President/CEO, Jewish Federation of Greater Pittsburgh; Past Chair of path-breaking social innovation incubator for Pittsburgh Public High School students; Board Member, Jack Buncher Foundation; Convener of Southwestern PA Resident-Led Town Halls to combat air pollution; Founder and President Emeritus of
							Jewish Neighborhood Development Council of Chicago; PhD in Government from Southern Illinois University
						</p>
					</div>
				</div>
				{/* <div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Leslie Dannin Rosenthal</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							South Orange, NJ
						</p>
						<p>Chair: Network of Independent Communities Advisory Committee, Jewish Federations of North America (JFNA); Board Member: Jewish Federations of North America (JFNA); Past President: Jewish Federation of Greater Metro West, NJ; Past President: Oheb Shalom Congregation, South Orange, NJ.</p>
					</div>
				</div> */}
				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Marcia Storch</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Gainesville, FL
						</p>
						<p>Past President: Temple B’Nai Israel, Olean, NY; Board President: Temple Shir Shalom, Gainesville, FL; Vice President: Democratic Women’s Club of Alachua County.</p>
					</div>
				</div>
				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Ann Tettlebaum</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Jefferson City, MO
						</p>
						<p>Treasurer and Board Member, Temple Beth EI of Jefferson City, Missouri; President and past treasurer, Hadassah Mid-Missouri Deborah Chapter; Treasurer, Friends of Moniteau County Library Services; Volunteer Income Tax assistance.</p>
					</div>
				</div>

				<h2 className="JCLP-blue-text">JCLP Community Advisory Committee</h2>
				<div className="row">
					<div className="col s6 m4 l3">
						<p>
							Nancy Banker
							<br />
							Trenton, MI
						</p>
					</div>
					<div className="col s6 m4 l3">
						<p>
							Robert Loeb
							<br />
							Victoria, TX
						</p>
					</div>
					<div className="col s6 m4 l3">
						<p>
							Debby Cherry
							<br />
							Revere, MA
						</p>
					</div>
					<div className="col s6 m4 l3">
						<p>
							Marilyn Posner
							<br />
							Washington, PA
						</p>
					</div>
					<div className="col s6 m4 l3">
						<p>
							Myron Chijner
							<br />
							Steubenville, OH
						</p>
					</div>
					<div className="col s6 m4 l3">
						<p>
							Marcia Storch
							<br />
							Olean, NY
						</p>
					</div>
					<div className="col s6 m4 l3">
						<p>
							Jamie Farrell
							<br />
							Revere, MA
						</p>
					</div>
				</div>
				<h2 className="JCLP-blue-text">Historian Advisor</h2>
				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Lee Shai Weissbach</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Philadelphia, PA
						</p>
						<p>Professor of History Emeritus: University of Louisville; Author: Jewish Life in Small Town America: A History (Yale University Press, 2005).</p>
					</div>
				</div>
				<p>
					For more information contact
					<br />
					Noah Levine: 678-429-8895
					<br />
					<a className="btn" href="mailto:nlevine@jclproject.org">
						Email
					</a>
				</p>

				<br />
				<br />
			</div>
		</div>
	);
};

export default Page;
