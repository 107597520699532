import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch } from 'react-router-dom';
import BlogContext from '../../context/blog-context';
import Blog, { Post_Loader } from './page';
import { CssHero as Parallax } from '../../components/LazyParallax';
// import PaImg from '../../images/case-studies.jpg';
// import HeroImg from '../HomePage/JCLP-Hero.jpg';
import Image1 from './book-300x900.jpg';
import Image2 from './book-300x2500.jpg';

export const Page = ({ match }) => {
	let { path, url, ...rest } = useRouteMatch();

	return (
		<div className="blog">
			<Parallax source={[Image1, Image2]} height={400}>
				<h1>BLOG</h1>
			</Parallax>
			<br />
			<br />
			<BlogContext>
				<Switch>
					<Route exact path={`${path}`} component={Blog} />
					<Route exact path={`${path}/:post`} component={Post_Loader} />
					<Route exact path={`${path}/search/:q`} component={Blog} />
					{/* <Route exact path={`${path}/new`} component={CreateTopic} />
               <Route exact path={`${path}/p/:topic`} component={Topic} /> */}
				</Switch>
			</BlogContext>
		</div>
	);
};

export default Page;

// match: isExact: true;
// params: {
// }
// path: '/Forum';
// url: '/Forum';
