import React from 'react';
import { Link } from 'react-router-dom';
import M from 'materialize-css';

const Navlinks = ({ closeMobileNav, ...rest }) => {
	// function navlinks({ closeMobileNav, ...rest }) {
	// const [open, setOpen] = React.useState(null);

	React.useEffect(() => {
		const elems = document.querySelectorAll('.collapsible');
		M.Collapsible.init(elems, { accordion: true });
	}, []);

	return (
		<React.Fragment>
			
			<li className="menu-item hide-on-small-only">
			
				<Link className="SBB-brown-text goldtext" to="/About">
					About JCLP
				</Link>
				<ul className="sub-menu">
					<li className="menu-item sub-menu-item">
						<Link to="/About">Mission</Link>
					</li>
					<li className="menu-item sub-menu-item">
						<Link to="/Board-Advisors">Board and Advisors</Link>
					</li>
					<li className="menu-item sub-menu-item">
						<Link to="/Staff">Staff</Link>
					</li>
					<li className="menu-item sub-menu-item">
						<Link to="/Clients">Clients</Link>
					</li>
					<li className="menu-item sub-menu-item">
						<Link to="/Partners">Partners</Link>
					</li>
				</ul>
			</li>
			<li className="menu-item hide-on-small-only">
				<Link className="SBB-brown-text" to="/Services">
					Services
				</Link>
				<ul className="sub-menu">
					<li className="menu-item sub-menu-item">
						<Link to="/Congregation-Continuity">CONGREGATION CONTINUITY</Link>
					</li>
					<li className="menu-item sub-menu-item">
						<Link to="/Congregation-Connections">CONGREGATION CONNECTIONS</Link>
					</li>
					<li className="menu-item sub-menu-item">
						<Link to="/Faq">FAQ</Link>
					</li>
				</ul>
			</li>
			<li className="menu-item hide-on-small-only">
				<Link className="SBB-brown-text" to="/">
					Resources
				</Link>
				<ul className="sub-menu">
					<li className="menu-item sub-menu-item">
						<Link to="/Blog">Blog</Link>
					</li>
					<li className="menu-item sub-menu-item">
						<Link to="/Case-Studies">Case Studies</Link>
					</li>
					
					<li className="menu-item sub-menu-item">
						<a href="/static/media/Handbook.8328adea.pdf" download>JCLP Handbook</a>
					</li>
					
					<li className="menu-item sub-menu-item">
						<Link to="/Newsletters">Newsletters</Link>
					</li>
					<li className="menu-item sub-menu-item">
						<Link to="/Press">Press</Link>
					</li>
					<li className="menu-item sub-menu-item">
						<Link to="/Testimonials">Testimonials</Link>
					</li>
					<li className="menu-item sub-menu-item">
						<Link to="/Testimonials/videos">Testimonial Videos</Link>
					</li>
					<li className="menu-item sub-menu-item">
						<Link to="/Video-Programs">Video Programs</Link>
					</li>
					<li className="menu-item sub-menu-item">
						<Link to="/Webinars">Webinars</Link>
					</li>
					
				</ul>
			</li>
			<li className="menu-item hide-on-small-only">
				<Link className="SBB-brown-text" to="/Forum">
					Forum
				</Link>
				{/* <ul className="sub-menu">
					<li className="menu-item sub-menu-item">
						<Link to="/">Login</Link>
					</li>
					<li className="menu-item sub-menu-item">
						<Link to="/">Register</Link>
					</li>
				</ul> */}
			</li>
			<li className="menu-item hide-on-small-only">
				<Link className="SBB-brown-text" to="/Contact">
					Contact
				</Link>
				<ul className="sub-menu">
					<li className="menu-item sub-menu-item">
						<Link to="/Contact">Get In Touch</Link>
					</li>
					{/* <li className="menu-item sub-menu-item">
						<Link to="/">Registration</Link>
					</li> */}
				</ul>
			</li>
			<li className="menu-item hide-on-small-only">
				<Link className="deep-orange-text text-darken-1" to="/Donation">
					Donate
				</Link>
				{/* <ul className="sub-menu">
					<li className="menu-item sub-menu-item">
						<Link to="/Donation">Donation</Link>
					</li>
					<li className="menu-item sub-menu-item">
						<Link to="/Tribute">Tribute</Link>
					</li>
				</ul> */}
			</li>
			{/* <li className="menu-item">
				<Link className="hvr-underline-reveal" to="/" onClick={() => closeMobileNav()}>
					Forum
				</Link>
			</li>

			<li className="menu-item">
				<Link className="hvr-underline-reveal" to="/" onClick={() => closeMobileNav()}>
					Contact
				</Link>
			</li>
			<li className="menu-item">
				<Link className="hvr-underline-reveal deep-orange-text text-darken-1" to="/" onClick={() => closeMobileNav()}>
					Donate
				</Link>
			</li> */}
			<li className="hide-on-med-and-up">
				<ul className="collapsible">
				<li>
						<Link to="/Join" onClick={() => closeMobileNav()} className="collapsible-header" style={{ display: 'block', padding: '0 5px 0 25px', fontSize: '16px', fontWeight: '500' }}>
							EVENT
						</Link>
					</li>
					<li>
						<div className="collapsible-header" style={{ display: 'block', padding: '0 5px 0 25px', fontSize: '16px', fontWeight: '500' }}>
							About JCLP<i className="material-icons right">add</i>
						</div>
						<div className="collapsible-body" style={{ padding: '0 !important' }}>
							<ul className="">
								<li className="mobileSubLi">
									<Link to="/About" onClick={() => closeMobileNav()}>
										Mission
									</Link>
								</li>
								<li className="mobileSubLi">
									<Link to="/Board-Advisors" onClick={() => closeMobileNav()}>
										Board and Advisors
									</Link>
								</li>
								<li className="mobileSubLi">
									<Link to="/Staff" onClick={() => closeMobileNav()}>
										Staff
									</Link>
								</li>
								<li className="mobileSubLi">
									<Link to="/Clients" onClick={() => closeMobileNav()}>
										Clients
									</Link>
								</li>
								<li className="mobileSubLi">
									<Link to="/Partners" onClick={() => closeMobileNav()}>
										Partners
									</Link>
								</li>
							</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header" style={{ display: 'block', padding: '0 5px 0 25px', fontSize: '16px', fontWeight: '500' }}>
							Services<i className="material-icons right">add</i>
						</div>
						<div className="collapsible-body" style={{ padding: '0 !important' }}>
							<ul className="">
								<li className="mobileSubLi">
									<Link to="/Congregation-Continuity" onClick={() => closeMobileNav()}>
										CONGREGATION CONTINUITY
									</Link>
								</li>
								<li className="mobileSubLi">
									<Link to="/Congregation-Connections" onClick={() => closeMobileNav()}>
										CONGREGATION CONNECTIONS
									</Link>
								</li>
								<li className="mobileSubLi">
									<Link to="/Faq" onClick={() => closeMobileNav()}>
										FAQ
									</Link>
								</li>
							</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header" style={{ display: 'block', padding: '0 5px 0 25px', fontSize: '16px', fontWeight: '500' }}>
							RESOURCES<i className="material-icons right">add</i>
						</div>
						<div className="collapsible-body" style={{ padding: '0 !important' }}>
							<ul className="">
								<li className="mobileSubLi">
									<Link to="/Blog" onClick={() => closeMobileNav()}>
										Blog
									</Link>
								</li>
								<li className="mobileSubLi">
									<Link to="/Case-Studies" onClick={() => closeMobileNav()}>
										Case Studies
									</Link>
								</li>
								
								<li className="mobileSubLi">
									<Link to="/static/media/Handbook.8328adea.pdf" onClick={() => closeMobileNav()}>
										JCLP Handbook
									</Link>
								</li>
								
								<li className="mobileSubLi">
									<Link to="/Newsletters" onClick={() => closeMobileNav()}>
										Newsletters
									</Link>
								</li>
								<li className="mobileSubLi">
									<Link to="/Press" onClick={() => closeMobileNav()}>
										Press
									</Link>
								</li>
								<li className="mobileSubLi">
									<Link to="/Testimonials" onClick={() => closeMobileNav()}>
										Testimonials
									</Link>
								</li>
								<li className="mobileSubLi">
									<Link to="/Testimonials/videos" onClick={() => closeMobileNav()}>
										Testimonial Videos
									</Link>
								</li>
								<li className="mobileSubLi">
									<Link to="/Video-Programs" onClick={() => closeMobileNav()}>
										Video Programs
									</Link>
								</li>
								<li className="mobileSubLi">
									<Link to="/Webinars" onClick={() => closeMobileNav()}>
										Webinars
									</Link>
								</li>
							</ul>
						</div>
					</li>
					<li>
						<Link to="/Forum" onClick={() => closeMobileNav()} className="collapsible-header" style={{ display: 'block', padding: '0 5px 0 25px', fontSize: '16px', fontWeight: '500' }}>
							FORUM
						</Link>
						{/* <div className="collapsible-body" style={{ padding: '0 !important' }}>
							<ul className="">
								<li className="mobileSubLi">
									<Link to="/" onClick={() => closeMobileNav()}>
										Login
									</Link>
								</li>
								<li className="mobileSubLi">
									<Link to="/" onClick={() => closeMobileNav()}>
										Register
									</Link>
								</li>
							</ul>
						</div> */}
					</li>
					<li>
						<Link to="/Contact" onClick={() => closeMobileNav()} className="collapsible-header" style={{ display: 'block', padding: '0 5px 0 25px', fontSize: '16px', fontWeight: '500' }}>
							CONTACT
						</Link>
					</li>
					<li>
						<Link to="/Donation" onClick={() => closeMobileNav()} className="collapsible-header deep-orange-text text-darken-1" style={{ display: 'block', padding: '0 5px 0 25px', fontSize: '16px', fontWeight: '500' }}>
							DONATE
						</Link>
					</li>
				</ul>
			</li>
		</React.Fragment>
	);
};

export default Navlinks;
