import React, { useState, useEffect } from 'react';
import HeroImg from './JCLP-Hero.jpg';
import CTA01 from '../../images/JCLP-cta-01.jpg';
import CTA02 from '../../images/JCLP-cta-02.jpg';
import CTA03 from '../../images/JCLP-cta-03.jpg';
import Partner01 from '../../images/JFNA.jpg';
import Partner02 from '../../images/JFNA-network.png';
import Partner03 from '../../images/aja-logo-top.png';
import Partner04 from '../../images/uscj-logo.png';
import Partner05 from '../../images/stacked_logo_notag_RGB-sm.jpg';
import Parallax from '../../components/LazyParallax';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';

import moment from 'moment';

import M from 'materialize-css';

import Axios from 'axios';
import { API_URL } from '../../config';

const HomePage = () => {
	// const [post, setPost] = useState(null);

	// useEffect(() => {
	// 	const getPost = () => {
	// 		let url = window.location.href;
	// 		let arr = url.split('/');
	// 		let arr2 = arr[2].split(':');
	// 		const URL = arr[0] + '//' + arr2[0] + '/cfc/api/';

	// 		Axios({
	// 			url: `${URL}pages.cfc?method=getPage&page=/`,
	// 			method: 'GET',
	// 			headers: {
	// 				'Content-Type': 'application/json',
	// 			},
	// 		})
	// 			.then((res) => res.status === 200 && res.data)
	// 			.then((d) => setPost(d))
	// 			.catch((e) => console.log({ e }));
	// 	};

	// 	getPost();
	// }, []);

	const settings02 = {
		dots: true,
		arrows: false,
		infinite: true,
		speed: 2000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 10000,
		useTransform: true,
	};

	return (
		<div className="home-page">
			<div className="clearfix"></div>
			<Parallax source={HeroImg} noShade={true} height={400} />

			{/* <img src={HeroImg} style={{ width: '100%' }} alt="" /> */}
			<div className="JCLP-blue white-text z-depth-3 titleLine center-align">
				<div className="container row">
					<p>
						Providing planning resources and solutions specific to the unique needs of <b>small Jewish congregations</b>, empowering each to secure its own future.
						<br />
						Welcome to JCLP.
					</p>
				</div>
			</div>

			<br />
			<br />

			<div className="container row">
				<div className="col s12 m10 offset-m1 l8 offset-l2 center" style={{ padding: '0' }}>
					<div className="row" style={{ padding: '0 11px' }}>
						<div className="col s12 m4 l4 noPadding">
							<Link to="/Services">
								<img src={CTA02} alt="" style={{}} />
							</Link>
							<br />
							<Link to="/Services" className="btn" style={{ fontSize: '25px' }}>
								PLAN
							</Link>
						</div>
						<div className="col s12 m4 l4 noPadding">
							<Link to="/Forum">
								<img src={CTA03} alt="" style={{}} />
							</Link>
							<br />
							<Link to="/Forum" className="btn" style={{ fontSize: '25px' }}>
								SHARE
							</Link>
						</div>
						<div className="col s12 m4 l4 noPadding">
							<Link to="/Donation">
								<img src={CTA01} alt="" style={{}} />
							</Link>
							<br />
							<Link to="/Donation" className="btn" style={{ fontSize: '25px' }}>
								GIVE
							</Link>
						</div>
					</div>
				</div>
			</div>

			<br />
			<br />

			<div className="JCLP-blue white-text z-depth-3 titleLine center-align">
				<div className="container row">
					<p style={{ fontSize: '2.5rem', padding: '5px' }}>Latest JCLP News and Events</p>
				</div>
			</div>

			<br />

			<Latest />

			<br />

			<div className="JCLP-blue white-text z-depth-3 titleLine center-align" style={{ zIndex: '100', position: 'relative' }}>
				<div className="container row">
					<p style={{ fontSize: '2.5rem', padding: '5px' }}>Our Partners</p>
				</div>
			</div>
			<div className="white">
				<br />
				<br />
				<div className="container row center-align">
					<div className="flexAdj">
						<div className="col s12 m4 l4 partnerImg">
							<a href="https://www.jewishfederations.org/" target="_blank">
								<img src={Partner01} className="responsive-img" alt="" />
							</a>
						</div>
						<div className="col s12 m4 l4 partnerImg">
							<a href="https://jewishfederations.org/network-communities" target="_blank">
								<img src={Partner02} className="responsive-img" alt="" />
							</a>
						</div>
						<div className="col s12 m4 l4 partnerImg">
							<a href="http://americanjewisharchives.org/" target="_blank">
								<img src={Partner03} className="responsive-img" alt="" />
							</a>
						</div>
					</div>
					<div className="clearfix"></div>
					<div className="col s12 m10 offset-m1 l8 offset-l2 partnerImg">
						<div className="flexAdj">
							<div className="col s12 m6 l6 partnerImg">
								<a href="https://uscj.org/" target="_blank">
									<img src={Partner04} alt="" className="responsive-img" style={{ width: '200px' }} />
								</a>
							</div>
							<div className="col s12 m6 l6 partnerImg">
								<a href="https://urj.org/" target="_blank">
									<img src={Partner05} className="responsive-img" style={{ width: '165px' }} alt="" />
								</a>
							</div>
						</div>
					</div>
				</div>

				<div className="clearfix"></div>
				<br />
			</div>
		</div>
	);
};

const Latest = () => {
	const [posts, setPosts] = React.useState([]);
	const [data, setData] = React.useState([]);

	React.useEffect(() => {
		const fetch = () => {
			const data = {
				limit: 1,
			};

			Axios({
				method: 'POST',
				url: `${API_URL}blog.cfc?method=getPosts`,
				headers: {
					'Content-Type': 'application/json',
				},
				data,
			})
				.then((r) => r.status === 200 && r.data)
				.then((d) => d.success && setPosts(d.data));
			// .then((d) => setFetching(false));
		};

		const get = () => {
			const data = { page: 'newsletters' };

			Axios({
				method: 'POST',
				url: `${API_URL}resources.cfc?method=get`,
				headers: {
					'Content-Type': 'application/json',
				},
				data,
			})
				.then((r) => r.status === 200 && r.data)
				.then((d) => {
					setData(d.data);
				});
		};

		get();
		fetch();
	}, []);

	return (
		<>
			<div className="container row">
				{posts.length > 0 &&
					posts.map((el, i) => (
						<div key={i} className="col s12 m12 l12 newsBox">
							<h3 className="JCLP-blue-text">
								<Link to={`/blog/${el.slug}`}>{el.title}</Link>
							</h3>
							<p style={{ marginTop: '0' }}>
								<b>JCLP | {moment(el.publishedat).format('MMM DD, YYYY')}</b>
							</p>
							<div dangerouslySetInnerHTML={{ __html: el.excerpt }} />
						</div>
					))}

				{data && data.length > 0 && (
					<div className="col s12 m12 l12 newsBox">
						<h3 className="JCLP-blue-text">
							<a target="_blank" href="/static/media/jclp-fall-2020.6ee3945a.pdf">
								{data[0].title}
							</a>
						</h3>
						<p style={{ marginTop: '0' }}>
							{/* <b>Administrator | </b> */}
							{/* <br /> */}
							Click <Link to="/Newsletters">HERE</Link> for more information
						</p>
					</div>
				)}
				<div className="col s12 m12 l12 newsBox">
					<h3 className="JCLP-blue-text">
						<a target="_blank" href="/Case-Studies">
							{' '}
							Case Study : Temple Beth El - Jefferson City, Missouri
						</a>
					</h3>
					<p style={{ marginTop: '0' }}>
						<b>Administrator | Nov 6, 2020</b>
						<br />
						<a target="_blank" href="/Case-Studies">
							How JCLP can help the members of a thriving lay-led community plan and document their intentions regarding the future of their congregation’s assets.
						</a>
					</p>
				</div>
			</div>
		</>
	);
};

export default HomePage;
