import React from 'react';
import { Helmet } from 'react-helmet';
import { Hero as Parallax } from '../../components/LazyParallax';
import useGtag from '../../hooks/useGtag';
import Image from '../../images/staff.jpg';

const Page = () => {
	useGtag();

	return (
		<div>
			<Helmet>
				<title>JCLP Staff : Jewish Community Legacy Project</title>
			</Helmet>
			<Parallax source={Image}>
				<h1>JCLP STAFF</h1>
			</Parallax>
			<div className="container content800">
				<br />
				<br />
				{/* <img src={interHome} /> */}
				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">David I. Sarnat</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							President
						</p>
						<p>
							Longtime Jewish community professional David Sarnat brings 40 years of experience, knowledge, connections and relationships to Jewish Community Legacy Project. He served the Jewish Federation in Cleveland as Director for Planning, was Executive Director in Atlanta for 21 years and was also the U.S. Representative to the Federation System for the Jewish Agency for
							Israel. Sarnat's recognition of an urgent need to preserve and honor the legacies of declining small Jewish communities around the country set in motion the establishment of JCLP.
						</p>

						<a className="btn" href="mailto:dsarnat@gmail.com">
							EMAIL
						</a>
					</div>
				</div>

				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Noah Levine</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Senior Vice President
						</p>
						<p>
							Noah Levine is a non-profit consultant and philanthropic advisor. He retired in August 2011 after 30 years of service with the Jewish Federation of Greater Atlanta. As Associate Executive Director there he oversaw human resource development, community relations and young leadership departments. Before joining Federation, he worked for Jewish Family Services of
							Philadelphia in their services for aging department. Mr. Levine served as president of Congregation Etz Chaim, a conservative synagogue in the metropolitan Atlanta area, and has consulted with area congregations on planning, fundraising and leadership and board development issues.
						</p>

						<a className="btn" href="mailto:l_noah@bellsouth.net">
							EMAIL
						</a>
					</div>
				</div>

				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Steven J. Edelstein</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Consultant
						</p>
						<p>
							Steven has had forty years of experience in the Jewish Federation field, having been chief executive officer of Federations in Nashville, TN, Toledo, OH and New Bedford, MA. A native of Johnstown, PA, who grew up in Youngstown, Ohio, Steve is a graduate of Kent State University and holds master's degrees from the University of Maryland School of Social Work and
							Baltimore Hebrew University. As Midwest Area Director of the Council of Jewish Federations, Steve counselled small and intermediate Federations on personnel, strategic planning and fundraising issues. He and his wife Elaine reside in Columbus, Ohio.
						</p>

						<a className="btn" href="mailto:sjedelstein0405@yahoo.com">
							EMAIL
						</a>
					</div>
				</div>

				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Max L. Kleinman</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Consultant
							{/* Consultant, Philadelphia */}
						</p>
						<p>
							Max Kleinman has more than 40 years of experience in non- profit management and resource development. He served for nearly 20 years as CEO of Jewish Federation of Greater MetroWest, one of the largest in the country. He was CEO of the Minneapolis Jewish Federation and also has communal experience at federations in Milwaukee and Atlanta. He is an author and a prolific
							fundraising consultant. Mr. Kleinman has taught non-profit management at Rutgers University and currently conducts a seminar on contemporary Jewish issues at Congregation Agudath Israel in Caldwell, New Jersey.
						</p>

						<a className="btn" href="mailto:mkleinmanconsulting@gmail.com">
							EMAIL
						</a>
					</div>
				</div>

				<div className="card">
					<div className="card-content">
						<span className="card-title JCLP-blue-text text500">
							<span className="">Rabbi Andy Bachman</span>
						</span>
						<p className="text500" style={{ margin: '0' }}>
							Consultant
							{/* Consultant, Philadelphia */}
						</p>
						<p>
							Andy Bachman is a rabbi who has served in synagogue and campus life from the Congregation Beth Elohim in Park Slope, Brooklyn, to the Edgar M. Bronfman Center for Jewish Student Life at NYU. A graduate of Hebrew Union College and the University of Wisconsin-Madison. A Milwaukee native, Andy is returning home to work on issues related to engaging and sustaining small
							town Jewish life in North America.
						</p>
					</div>
				</div>

				<br />
				<br />
			</div>
		</div>
	);
};

export default Page;
