import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import M from 'materialize-css';

const Accordion = ({ elements, active = 0 }) => {
	const uuid = uuidv4();
	const [accordion, setAccordion] = useState(null);

	useEffect(() => {
		setAccordion(
			M.Collapsible.init(document.querySelectorAll(`.collapsible.c-${uuid}`), {
				// const accordion = M.Collapsible.init([accordionEl.current], {
				accordion: true,
				onOpenStart: function (e) {
					e.querySelector('.material-icons').innerHTML = 'remove';
				},
				onCloseStart: function (e) {
					e.querySelector('.material-icons').innerHTML = 'add';
				},
			})
		);

		return () => {
			accordion && accordion.destroy();
		};
	}, []);

	const icon = (a) => {
		return active === a ? 'remove' : 'add';
	};

	return (
		<ul className={`collapsible c-${uuid}`}>
			{elements.map((el, i) => (
				<li className="active">
					<div className="collapsible-header">
						<i className="material-icons">{icon(i)}</i>
						<b>{el.title}</b>
					</div>
					<div className="collapsible-body">{el.body}</div>
				</li>
			))}
		</ul>
	);
};

export default Accordion;
