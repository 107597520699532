import Axios from 'axios';
import React, { createContext, useReducer, useEffect } from 'react';
import { API_URL } from '../../config';
import { createReducer } from './authReducer';

export const AuthContext = createContext();

const store_user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : null;
const store_token = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).token : null;

const initialState = { user: null || store_user, token: null || store_token, loading: false, errors: [] };

const serialize = (obj) => {
	const str = Object.keys(obj)
		.reduce(function (a, k) {
			a.push(k + '=' + encodeURIComponent(obj[k]));
			return a;
		}, [])
		.join('&');
	return str;
};

const AuthContextProvider = ({ children }) => {
	const memorizedReducer = React.useCallback(createReducer(), []);
	const [state, dispatch] = useReducer(memorizedReducer, initialState);

	useEffect(() => {
		const store = {
			user: state.user,
			token: state.token,
		};
		state.user && localStorage.setItem('user', JSON.stringify(store));
	}, [state.user]);

	const login = async (d = {}, token = '') => {
		const { username, password } = d;

		const auth_header = token.length > 0 ? { authorization: token } : {};

		const auth =
			username && password
				? {
						auth: {
							username,
							password,
						},
				  }
				: {};

		// dispatch({ type: 'REQUEST_LOGIN' });

		return Axios({
			method: 'POST',
			url: `${API_URL}users.cfc?method=login`,
			headers: {
				'Content-Type': 'application/json',
				...auth_header,
			},
			...auth,
		})
			.then((r) => r.status === 200 && r.data)
			.then((d) => {
				if (d && 'message' in d) {
					dispatch({ type: 'LOGIN_ERROR', error: d.message });
					return d;
				}
				if (d.user) {
					dispatch({ type: 'LOGIN_ERROR', error: '' });
					dispatch({ type: 'LOGIN_SUCCESS', payload: d });
					return d;
				}
				dispatch({ type: 'LOGIN_ERROR', error: 'Invalid Credentials' });
				return {};
			})
			.catch((error) => {
				dispatch({ type: 'LOGIN_ERROR', error: 'Invalid Credentials' });
				console.log('error in login context', { error });
			});
	};

	const logout = async () => {
		dispatch({ type: 'LOGOUT' });
		localStorage.removeItem('user');
	};

	const contextValues = {
		...state,
		login,
		logout,
	};

	return <AuthContext.Provider value={contextValues}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
