import Axios from 'axios';
import React, { createContext, useReducer, useEffect } from 'react';
import { API_URL } from '../../config';
import { createReducer } from './forumReducer';

export const ForumContext = createContext();

// const storage = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
const initialState = { categories: [], topics: [], quoted: null, posts: [], user: {}, category_focus: 1 };

const serialize = (obj) => {
	const str = Object.keys(obj)
		.reduce(function (a, k) {
			a.push(k + '=' + encodeURIComponent(obj[k]));
			return a;
		}, [])
		.join('&');
	return str;
};

const slugify = (string) => {
	const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
	const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
	const p = new RegExp(a.split('').join('|'), 'g');

	return string
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
		.replace(/&/g, '-and-') // Replace & with 'and'
		.replace(/[^\w\-]+/g, '') // Remove all non-word characters
		.replace(/\-\-+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, ''); // Trim - from end of text
};

const ForumContextProvider = ({ children }) => {
	const memorizedReducer = React.useCallback(createReducer(), []);
	const [state, dispatch] = useReducer(memorizedReducer, initialState);

	useEffect(() => {
		// localStorage.setItem('f-categories', JSON.stringify(state.categories.length > 0 ? state.categories : []));
		fetchCategories().then((d) => setCategories(d));
	}, []);

	useEffect(() => {
		// state.categories && localStorage.setItem('f-categories', JSON.stringify(state.categories.length > 0 ? state.categories : []));
	}, [state.categories]);

	const fetchCategories = async (d = {}) => {
		const uri_q = '' || serialize(d);

		return Axios({
			method: 'GET',
			url: `${API_URL}forum.cfc?method=getCategories&${uri_q}`,
			// auth: {
			// 	username: 'janedoe',
			// 	password: 's00pers3cret',
			// },
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((r) => r.status === 200 && r.data)
			.then((d) => d && d)
			.catch((e) => console.log('error in fetch categories context', { e }));
	};

	const fetchTopics = async (d = {}) => {
		const uri_q = '' || serialize(d);

		console.log({ d });

		return Axios({
			method: 'GET',
			url: `${API_URL}forum.cfc?method=getTopics&${uri_q}`,
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((r) => r.status === 200 && r.data)
			.then((d) => d && d)
			.catch((e) => console.log('error in fetch topics context', { e }));
	};

	const fetchPosts = async (d = {}) => {
		const uri_q = '' || serialize(d);

		return Axios({
			method: 'GET',
			url: `${API_URL}forum.cfc?method=getPosts&${uri_q}`,
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((r) => r.status === 200 && r.data)
			.then((d) => d && d)
			.catch((e) => console.log('error in fetch topics context', { e }));
	};

	const fetchPopularTopics = async () => {
		return Axios({
			method: 'GET',
			url: `${API_URL}forum.cfc?method=getPopularTopics`,
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((r) => r.status === 200 && r.data)
			.then((d) => d && d)
			.catch((e) => console.log('error in fetch topics context', { e }));
	};

	const getTopic = (i = 0) => {
		return Axios({
			method: 'GET',
			url: `${API_URL}forum.cfc?method=getTopic&i=${i}`,
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((r) => r.status === 200 && r.data)
			.then((d) => d && d)
			.catch((e) => console.log('error in fetch topics context', { e }));
	};

	const createPost = async (data = {}, user) => {
		if (!user) return false;

		return Axios({
			method: 'POST',
			url: `${API_URL}forum.cfc?method=createPost`,
			headers: {
				'Content-Type': 'application/json',
			},
			data,
		})
			.then((r) => r.status === 200 && r.data)
			.then((d) => {
				getPosts(data.topicid);
				return d && d;
			})
			.catch((e) => console.log('error in fetch topics context', { e }));
	};

	const createTopic = async (data = {}, user) => {
		if (!user) return false;

		return Axios({
			method: 'POST',
			url: `${API_URL}forum.cfc?method=createTopic`,
			headers: {
				'Content-Type': 'application/json',
			},
			data,
		})
			.then((r) => r.status === 200 && r.data)
			.then((d) => {
				fetchCategories().then((x) => setCategories(x));
				return d && d;
			})
			.catch((e) => console.log('error in fetch topics context', { e }));
	};

	const approvePost = (i = 0, user) => {
		if (!user || user.permission === 0) return false;

		const uri_q = '' || serialize({ i, get: 1 });

		return Axios({
			method: 'POST',
			url: `${API_URL}forum.cfc?method=approvePost&${uri_q}`,
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((r) => r.status === 200 && r.data)
			.then((d) => {
				if (d && 'id' in d) getPosts(d.id);
				return d && d;
			})
			.catch((e) => console.log('error in fetch topics context', { e }));
	};

	const deletePost = (i = 0, user) => {
		if (!user || user.permission === 0) return false;

		const uri_q = '' || serialize({ i, get: 1 });

		return Axios({
			method: 'POST',
			url: `${API_URL}forum.cfc?method=deletePost&${uri_q}`,
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((r) => r.status === 200 && r.data)
			.then((d) => {
				if (d && 'id' in d) getPosts(d.id);
				return d && d;
			})
			.catch((e) => console.log('error in fetch topics context', { e }));
	};

	const likePost = (i = 0, user) => {
		if (!user) return false;

		const uri_q = '' || serialize({ i, get: 1 });

		return Axios({
			method: 'POST',
			url: `${API_URL}forum.cfc?method=likePost&${uri_q}`,
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((r) => r.status === 200 && r.data)
			.then((d) => {
				return d && d;
			})
			.catch((e) => console.log('error in fetch topics context', { e }));
	};

	const dislikePost = (i = 0, user) => {
		if (!user) return false;

		const uri_q = '' || serialize({ i, get: 1 });

		return Axios({
			method: 'POST',
			url: `${API_URL}forum.cfc?method=dislikePost&${uri_q}`,
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((r) => r.status === 200 && r.data)
			.then((d) => {
				return d && d;
			})
			.catch((e) => console.log('error in fetch topics context', { e }));
	};

	const setCategories = (payload) => dispatch({ type: 'SET_CATEGORIES', payload });

	const setCategory_Focus = (payload) => dispatch({ type: 'SET_CATEGORY_FOCUS', payload });

	const setQuoted = (payload) => dispatch({ type: 'SET_QUOTED', payload });

	const getTopics = (i = 0) => {
		console.log({ i });
		if (i > 0) {
			return fetchTopics({ c: i }).then((t) => dispatch({ type: 'SET_TOPICS', payload: t }));
		} else {
			return fetchTopics().then((t) => dispatch({ type: 'SET_TOPICS', payload: t }));
		}
	};

	const getPosts = (i) => {
		fetchPosts({ t: i }).then((t) => dispatch({ type: 'SET_POSTS', payload: t }));
	};

	const contextValues = {
		...state,
		setCategory_Focus,
		setQuoted,
		getTopics,
		getTopic,
		getPosts,
		fetchPopularTopics,
		createTopic,
		createPost,
		approvePost,
		deletePost,
		likePost,
		dislikePost,
	};

	return <ForumContext.Provider value={contextValues}>{children}</ForumContext.Provider>;
};

export default ForumContextProvider;
