import React from 'react';
import { Link } from 'react-router-dom';
import img01 from '../../images/continity01.jpg';
import Parallax from '../../components/LazyParallax';
import PaImg from '../../images/cong-cont.jpg';
import useGtag from '../../hooks/useGtag';
import { Helmet } from 'react-helmet';

const Page = () => {
	useGtag();

	return (
		<div>
			<Helmet>
				<title>Congregation Continuity : Jewish Community Legacy Project</title>
			</Helmet>
			<Parallax source={PaImg}>
				<h1 className="white-text">
					CONGREGATION
					<br />
					<span className="JCLP-orange-text">CONTINUITY</span>
					{/* <span className="subTittle">HELPING SMALL CONGREGATIONS</span> */}
				</h1>
			</Parallax>
			<div className="container content800">
				<br />
				<br />
				{/* <img src={interHome} /> */}

				<p className="black-text text500">Individualized, One-on-One Congregation Collaboration</p>
				<p>
					With <b className="black-text">Congregation</b> <b className="JCLP-orange-text">Continuity</b>, JCLP collaborates with congregations on an individual basis to address a range of needs through to resolution. JCLP’s deep expertise provides an effective one-stop solution for congregations anywhere along the congregational life cycle spectrum, whether in a period of growth, that
					wishes to be maintained, a stage of decline, that is in need of turnaround, or facing ultimate closure, and in need of legacy planning. With experience in daily and long-term congregation operations, fiduciary and asset management, congregation engagement and more, JCLP is THE destination for congregations focused on continuity .
				</p>
				<p>The congregation life cycle, post-inception, brings opportunities at each stage for needed, constructive JCLP intervention:</p>
				<p className="center">
					<img src={img01} />
				</p>
				<p>
					A key value proposition of <b className="black-text">Congregation</b> <b className="JCLP-orange-text">Continuity</b> is the dedicated, one-on-one work that is done through to completion between JCLP staff and the client congregation, which is unique to offerings from other organizations operating in the Jewish congregation field. Some key areas of expertise include, but are not
					limited to:
				</p>
				<ul className="discStyle">
					<li>
						<span className="black-text text500">Engagement:</span> Increasing participation and volunteers
					</li>
					<li>
						<span className="black-text text500">Leadership Succession:</span> Options based on a range of models
					</li>
					<li>
						<span className="black-text text500">Outreach:</span> Finding prospective members
					</li>
					<li>
						<span className="black-text text500">Fundraising:</span> Capital and annual campaigns and financial forecasting
					</li>
					<li>
						<span className="black-text text500">Historic Preservation:</span> Oral histories, archives and memorial plaques
					</li>
					<li>
						<span className="black-text text500">Clergy Support:</span> Network of partner resources
					</li>
					<li>
						<span className="black-text text500">Artifact Donation:</span> Sifrei Torah, Judaica and more
					</li>
					<li>
						<span className="black-text text500">Cemetery Support:</span> Financial and administrative oversight
					</li>
					<li>
						<span className="black-text text500">Legacy Endowments:</span> Perpetuating a synagogue’s heritage in the community
					</li>
					<li>
						<span className="black-text text500">Concierge services:</span> Custom assistance for congregations' unique needs
					</li>
				</ul>
				<p>For areas in need of solutions outside JCLP’s historical scope, JCLP leverages its extensive network of partner organizations with corresponding specialties to field the proper expertise for client congregations through to plan completion.</p>
				<p>
					Click <Link to="/Case-Studies">here</Link> to read a recent case study that illustrates the successful outcomes of using JCLP’s customized <b className="black-text">Congregation</b> <b className="JCLP-orange-text">Continuity</b> services or <Link to="/Clients">here</Link> to see a list of client congregations. Please <Link to="/Contact">reach out</Link> to learn more about how
					JCLP can help best support your congregation.
				</p>
				<br />
				<br />
			</div>
		</div>
	);
};

export default Page;
