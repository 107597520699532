import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Parallax from '../../components/LazyParallax';
import useGtag from '../../hooks/useGtag';
import PaImg from '../../images/about.jpg';
import Handbook from '../../images/Handbook.pdf';

const Page = () => {
	useGtag();

	return (
		<div>
			<Helmet>
				<title>About Us : Jewish Community Legacy Project</title>
			</Helmet>
			<Parallax source={PaImg}>
				<h1>
					OUR MISSION
					<br />
					<span className="subTittle">Solutions for small Jewish congregations</span>
				</h1>
			</Parallax>
			<div className="container content800">
				<br />
				<br />
				{/* <img src={interHome} /> */}
				<h2 className="JCLP-blue-text">About Statement</h2>
				<p>The Jewish Community Legacy Project (JCLP) is the only organization exclusively providing dedicated resources and solutions for small Jewish congregations in the US and Canada to navigate successfully current challenges, prepare responsibly for the future and ensure an enduring community legacy.</p>
				<h2 className="JCLP-blue-text">About JCLP</h2>
				<p>
					Jewish Community Legacy Project (JCLP) is the only organization focused exclusively on empowering small Jewish congregations in the US and Canada to secure their futures. With a deep understanding of the needs unique to these, JCLP provides the resources and solutions specific to the challenges associated with operational growth, sustainability, or decline. Leveraging its
					proven experience, as well as relationships with local and national partners, JCLP facilitates effective plans of action for the sustainability of congregational life and long-range planning. JCLP is an independent 501(c)3 organization, and its customized planning solutions per congregation come free of charge.
				</p>
				<p>JCLP supports small Jewish congregations through two unique programs: </p>
				<p>
					<span className="black-text text500">Congregation</span>{' '}
					<Link to="/Congregation-Continuity" className="JCLP-orange-text text500">
						Continuity
					</Link>
					: Individualized, One-on-One Congregation Collaboration
				</p>
				<p>
					With Congregation Continuity, JCLP collaborates with congregations on an individual basis to address a range of needs through to resolution. JCLP’s deep expertise provides an effective one-stop solution for congregations anywhere along the congregational life cycle spectrum, whether in a period of growth, that wishes to be maintained, a stage of decline, that is in need of
					turnaround, or facing ultimate closure, and in need of legacy planning. With experience in daily and long-term congregation operations, fiduciary and asset management, congregation engagement and more, JCLP is THE destination for congregations focused on continuity.
				</p>
				<p>
					<span className="black-text text500">Congregation</span>{' '}
					<Link to="/Congregation-Connections" className="JCLP-green-text text500">
						Connections
					</Link>
					: Facilitated, Peer Congregation Network Collaboration
				</p>
				<p>
					With Congregation Connections, JCLP is the convener, facilitator and provider of expert resources and solution-oriented support for peer congregation networks through ongoing, collaborative forums. The Congregation Connections regional cohorts are THE destination for congregations looking for a peer community, networking opportunities, operational resources and problem-solving
					support. Monthly cohort meetings provide the opportunity for members to build relationships with other like congregations, share challenges and successes, engage in collaborative programming, and learn from JCLP-provided resources and thought leadership on best practices.
				</p>
				<a href={Handbook} target="_blank">
					<p>JCLP Handbook</p>
				</a>
				<br />
				<br />
			</div>
		</div>
	);
};

export default Page;
