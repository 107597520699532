import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import ArrowToTop from '../ScrollTopArrow';
import '../../styles/app.css';
import '../../styles/dropdown.css';
import '../../styles/imageeffect.css';
import NavLinks from './navlinks';
import { AuthContext } from '../../context/auth-provider';
import M from 'materialize-css';
import logo from '../../images/JCLP-logo.jpg';
import { SignupTrigger } from '../newsletter';

const topStyle = {
	position: 'absolute',
	border: '0',
	boxShadow: '0 0 0',
};

const Home = (props) => {
	const { user, logout } = useContext(AuthContext);

	// useEffect(() => {
	// 	M.Sidenav.init(document.getElementById('mobile-nav'));
	// 	const elems = document.querySelectorAll('.collapsible');
	// 	M.Collapsible.init(elems, {
	// 		accordion: false,
	// 		onOpenStart: function (e) {
	// 			e.querySelector('.material-icons').innerHTML = 'remove';
	// 		},
	// 		onCloseStart: function (e) {
	// 			e.querySelector('.material-icons').innerHTML = 'add';
	// 		},
	// 	});
	// }, []);

	const closeMobileNav = () => {
		let elem = document.getElementById('mobile-nav');
		let instance = M.Sidenav.getInstance(elem);
		instance.close();
	};

	return (
		<div style={{ height: '115px' }}>
			{/* <div className={`container ${props.path !== '/' && 'red'}`}> */}
			<div className="container" style={{ backgroundColor: '#f3f3f3' }}>
				<div className="nav-wrapper" style={{ height: '115px' }}>
					<header className="row" style={{ position: 'relative', zIndex: 100, marginBottom: '0' }}>
						<div className="ssIcon hide-on-med-and-down" style={{ float: 'right' }}>
							<a className="fbIcon" target="_blank" href="https://www.facebook.com/TheJCLP">
								<i className="fab fa-facebook-square"></i>
							</a>
							<a className="twIcon" target="_blank" href="https://www.linkedin.com/company/jewish-community-legacy-project-inc/about/">
								<i className="fab fa-linkedin"></i>
							</a>
						</div>
						<div className="hide-on-med-and-down">
							<ul
								className="topNav"
								style={{
									marginBottom: '0',
								}}
							>
								<li>
									<SignupTrigger />
								</li>
								<li>
									{!user && <Link to="/login">FORUM LOGIN/REGISTER</Link>}
									{user && <span onClick={logout}>LOGOUT</span>}
								</li>
							</ul>
						</div>

						<div className="clearfix"></div>
						<nav className="transparent" style={{ boxShadow: 'none' }}>
							<Link className="brand-logo" to="/">
								<img src={logo} alt="" className="logoAdj" />
							</Link>
							<ul className="right hide-on-med-and-down" style={{ margin: '25px 0px 0 0' }}>
								<NavLinks closeMobileNav={closeMobileNav} />
							</ul>
							<a href="#!" data-target="mobile-nav" className="sidenav-trigger">
								<i className="material-icons">menu</i>
							</a>
						</nav>
					</header>
				</div>
			</div>
			<ArrowToTop />
			<ul className="sidenav JCLP-blue-text" id="mobile-nav">
				<li style={{ height: '140px' }}>
					<Link className="" to="/" onClick={() => closeMobileNav()}>
						<img src={logo} alt="" className="responsive-img" style={{ marginTop: '30px' }} />
					</Link>
				</li>
				<NavLinks closeMobileNav={closeMobileNav} />
			</ul>
			{props.path !== '/' && <br />}
		</div>
	);
};

export default React.memo(Home);
