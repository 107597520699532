import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/materializeOverride.scss';
import './styles/materialize.modern.admin.scss';
import App from './app';
import AuthProvider from './context/auth-provider';
// import ReactGA from 'react-ga';
//import CartContextProvider from './context/CartContext';

const config = {
	trackerName: 'AnalyticsTracker',
	trackerId: 'UA-2385886075-1',
};

// const trackingId = 'G-9152JH3W1D';
//new via stephen

// ReactGA.initialize(trackingId);
ReactDOM.render(
	<React.StrictMode>
		<AuthProvider>
			<Router>
				<App />
			</Router>
		</AuthProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
