import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Axios from 'axios';
import { API_URL } from '../../config';

const Register = () => {
	const { handleSubmit, register, errors, getValues, reset } = useForm();
	const [loading, setLoading] = useState(false);

	const onSubmit = (values) => {
		const { username, password, firstname, lastname, ...rest } = values;
		// const { from } = this.props.location.state || {
		// 	from: { pathname: '/authenticated' },
		// };
		userRegister({
			firstname,
			lastname,
			username,
			password,
			...rest,
		}).then((d) => console.log({ d }));
	};

	const [notice, setNotice] = useState('');

	const userRegister = async (data = {}) => {
		return Axios({
			method: 'POST',
			url: `${API_URL}users.cfc?method=register`,
			headers: {
				'Content-Type': 'application/json',
			},
			data,
		})
			.then((r) => r.status === 200 && r.data)
			.then((d) => {
				// fetchCategories().then((x) => setCategories(x));
				if (d.user && 'id' in d.user && d.user.id > 0) {
					reset();
					setNotice('Thank you. You will receive an email shortly.');
				} else {
					setNotice(d.message);
				}

				return d && d;
			})
			.catch((e) => console.log('error in fetch topics context', { e }));
	};

	return (
		<form name="register-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="form-holder">
				<span>{notice}</span>
				<div className="input-field col s6">
					<input type="text" className="input" ref={register()} name="firstname" id="reg_firstname" placeholder="First Name" />
					{/* <label className="active" htmlFor="reg_username">
											Username
										</label> */}
					<span className="helper-text red-text">{errors.firstname && errors.firstname.message}</span>
				</div>
				<div className="input-field col s6">
					<input type="text" className="input" ref={register()} name="lastname" id="reg_lastname" placeholder="Last Name" />
					{/* <label className="active" htmlFor="reg_username">
											Username
										</label> */}
					<span className="helper-text red-text">{errors.lastname && errors.lastname.message}</span>
				</div>
				<div className="input-field col s6">
					<input
						type="text"
						className="input"
						ref={register({
							required: 'Username is required!',
							pattern: {
								value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
								message: 'Invalid Email Address',
							},
						})}
						name="username"
						id="reg_username"
						placeholder="email@domain.com"
					/>
					{/* <label className="active" htmlFor="reg_username">
											Username
										</label> */}
					<span className="helper-text red-text">{errors.username && errors.username.message}</span>
				</div>
				<div className="input-field col s6">
					<input type="password" ref={register({ required: 'Password is required!' })} className="input" name="password" id="reg_password" placeholder="Password" />
					{/* <label className="active" htmlFor="reg_password">
											Password
										</label> */}
					<span className="helper-text red-text">{errors.password && errors.password.message}</span>
				</div>
				<div className="input-field col s6">
					<input
						type="password"
						ref={register({
							required: 'Password is required!',
							validate: {
								matchesPreviousPassword: (value) => {
									const { password } = getValues();
									return password === value || 'Passwords should match!';
								},
							},
						})}
						name="confirmPassword"
						id="confirmPassword"
						className="input"
						placeholder="Confirm Password"
					/>
					{/* <label className="active" htmlFor="confirmPassword">
											Confirm Password
										</label> */}
					<span className="helper-text red-text">{errors.confirmPassword && errors.confirmPassword.message}</span>
				</div>
			</div>
			<button type="submit" className="btn submit-btn">
				Sign up
			</button>
		</form>
	);
};

export default Register;
