import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useLocation } from 'react-router-dom';
import ForumContext from '../../context/forum-context';
import Forum, { Category, Topic, CreateTopic, ForumParallax } from './page';
import Image from '../../images/forum.jpg';
import useGtag from '../../hooks/useGtag';
import { Helmet } from 'react-helmet';

export const Page = ({ match }) => {
	const location = useLocation();

	useGtag();

	let { path, url } = useRouteMatch();

	const getTitle = () => {
		var title = location.pathname.toLowerCase().replaceAll('/forum', '');
		title = title.replaceAll('/t/', '');
		title = title.replaceAll('-', ' ');

		var words = title.split(' ');
		words.pop();

		for (let i = 0; i < words.length; i++) {
			if (words[i].toLowerCase() === 'jclp') {
				words[i] = 'JCLP';
				continue;
			}
			words[i] = words[i][0].toUpperCase() + words[i].substr(1);
		}

		title = words.join(' ');

		return title.length > 4 ? `- ${title}` : '';
	};

	return (
		<>
			<ForumContext>
				<Helmet>
					<title>Forum {getTitle()} : Jewish Community Legacy Project</title>
				</Helmet>
				<ForumParallax source={Image} />
				<Switch>
					<Route exact path={`${path}`} component={Category} />
					<Route exact path={`${path}/new`} component={CreateTopic} />
					<Route exact path={`${path}/:category`} component={Category} />
					<Route exact path={`${path}/t/:topic`} component={Topic} />
				</Switch>
			</ForumContext>
		</>
	);
};

export default Page;

// match: isExact: true;
// params: {
// }
// path: '/Forum';
// url: '/Forum';
