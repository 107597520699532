import React from 'react';
import { Route } from 'react-router-dom';
import { AuthContext } from './context/auth-provider';
// import { useGA4React } from 'ga-4-react';
// import { useAnalytics } from '@arranjae/react-analytics';

import HomePageLayout from './Layout/homePage';
//import ProductPageLayout from "./Layout/productPage";
//import LoginPageLayout from "./Layout/login";
import DefaultLayout from './Layout/default';
//import AdminLayout from "./Layout/admin";
import LoginPage from './containers/LoginPage';

//

// export function LoginRoute({ component: Component, ...rest }) {
//    const auth = useAuthentication();
//    const history = useHistory();

//    if (auth.user) {
//       history.push('/authenticated');
//    }

//    return (
//       <Route
//          {...rest}
//          render={(props) => (
//             <LoginPageLayout>
//                <Component {...props} />
//             </LoginPageLayout>
//          )}
//       />
//    );
// }

export function HomeRoute({ component: Component, ...rest }) {
	// window.gtag('event', 'screen_view', { app_name: 'JCLProject.org', screen_name: rest.location.pathname });
	// window.gtag('event', 'page_view', { page_path: rest.location.pathname });
	// 'page_path': '/my-virtualpage-path'
	return (
		<Route
			{...rest}
			render={(props) => (
				<HomePageLayout>
					<Component {...props} />
				</HomePageLayout>
			)}
		/>
	);
}

// export function ProductRoute({ component: Component, ...rest }) {
//    return (
//       <Route
//          {...rest}
//          render={(props) => (
//             <ProductPageLayout>
//                <Component {...props} />
//             </ProductPageLayout>
//          )}
//       />
//    );
// }

// export function AdminRoute({ component: Component, ...rest }) {
//    return (
//       <Route
//          {...rest}
//          render={(props) => (
//             <AdminLayout history={props.history}>
//                <Component {...props} />
//             </AdminLayout>
//          )}
//       />
//    );
// }

export function PrivateRoute({ component: Component, ...rest }) {
	const { user } = React.useContext(AuthContext);

	// const auth = useAuthentication();
	const Output = user ? Component : LoginPage;

	if (user) return <HomeRoute {...rest} component={(props) => <Output {...props} />} />;

	return <HomeRoute exact path={rest.path} component={(props) => <Output {...props} />} {...rest} />;
}

export default ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => (
				<DefaultLayout>
					<Component {...props} />
				</DefaultLayout>
			)}
		/>
	);
};
