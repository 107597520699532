import React, { useState, useEffect } from 'react';
import { Hero as Parallax } from '../../components/LazyParallax';
import Image from '../../images/newsletter.jpg';

import pdf01 from './jclp-fall-2020.pdf';
import pdf02 from './JCLP_Virtual_mission_israel.pdf';
import pdf03 from './JCLP-eNewsletter-Summer-2020.pdf';
import pdf04 from './JCLP-Celebrates-Israel-Independence-Day-2020.pdf';
import pdf05 from './Community-Letter-re-COVID-19.pdf';
import pdf06 from './JCLP-eNewsletter-Spring-2020.pdf';
import pdf07 from './JCLP eNewsletter Fall 2019.pdf';
import pdf08 from './JCLP eNewsletter Late Spring 2019.pdf';
import pdf09 from './JCLP eNewsletter Late Fall 2018.pdf';
import pdf10 from './JCLP The Legacy Spring 2018.pdf';
import pdf11 from './JCLP eNewsletter Winter 2017-2018.pdf';
import pdf12 from './The-Legacy-JCLP-eNewsletter-Summer-2017.pdf';
import pdf13 from './JCLP Spring 2016 Newsletter.pdf';
import { API_URL } from '../../config';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import useGtag from '../../hooks/useGtag';
import { Helmet } from 'react-helmet';

const Page = ({ data }) => {
	useGtag();

	const [q, setQ] = useState(null);
	const presses = [
		{ id: 'awwe3', title: 'JCLP Newsletter', content: 'Helping small Jewish communities', date: 'Fall 2020', link: pdf01 },
		{ id: 'awwe3', title: 'JCLP Virtual Mission', content: 'Virtual Mission to Israel with Jewish Federations of North America', date: 'September 4, 2020', link: pdf02 },
		{ id: 'awwe3', title: 'JCLP Newsletter', content: 'Helping small Jewish communities', date: 'Summer 2020', link: pdf03 },
		{ id: 'awwe3', title: 'Israel Independence Day', content: 'JCLP Celebrates Israel Independence Day 2020', date: 'April 27, 2020', link: pdf04 },
		{ id: 'awwe3', title: 'Community Letter re:COVID-19', content: 'We are going through a difficult time. No community, large or small', date: 'March 20, 2020', link: pdf05 },
		{ id: 'awwe3', title: 'JCLP Newsletter', content: 'Helping small Jewish communities', date: 'Spring 2020', link: pdf06 },
		{ id: 'awwe3', title: 'JCLP Newsletter', content: 'Helping small Jewish communities', date: 'Fall 2019', link: pdf07 },
		{ id: 'awwe3', title: 'JCLP Newsletter', content: 'Helping small Jewish communities', date: 'Spring 2019', link: pdf08 },
		{ id: 'awwe3', title: 'JCLP Newsletter', content: 'Helping small Jewish communities', date: 'Fall 2018', link: pdf09 },
		{ id: 'awwe3', title: 'JCLP Newsletter', content: 'Helping small Jewish communities', date: 'Spring 2018', link: pdf10 },
		{ id: 'awwe3', title: 'JCLP Newsletter', content: 'New Castle congregation closes but offers help to worldwide Jewish communities', date: 'Winter 2017-2018', link: pdf11 },
		{ id: 'awwe3', title: 'JCLP Newsletter', content: 'Helping small Jewish communities', date: 'Summer 2017', link: pdf12 },
		{ id: 'awwe3', title: 'JCLP Newsletter', content: 'Helping small Jewish communities', date: 'Spring 2016', link: pdf13 },
	];

	return (
		<div>
			<Helmet>
				<title>Newsletters : Jewish Community Legacy Project</title>
			</Helmet>
			<Parallax source={Image}>
				<h1>
					NEWSLETTERS
					{/* <br />
					<span className="subTittle">Lorem ipsum dolor sit amet consectetur</span> */}
				</h1>
			</Parallax>

			<div className="container row">
				<div className="row">
					<br />
					<div className="col s12 m10 offset-m1 l8 offset-l2" style={{ position: 'relative' }}>
						<input type="text" className="rightTagInput" placeholder="Search any key word(s)" name="bla" id="ss" onChange={(e) => setQ(e.target.value)} />
						<button className="inputTag goldBtn">
							<i className="fas fa-search"></i>
						</button>
					</div>
					<div className="clearfix"></div>
					<br />
					{data
						.filter((p) => {
							if (!q) return true;

							if (p.title.toLowerCase().search(q.toLowerCase()) >= 0 || p.quote.toLowerCase().search(q.toLowerCase()) >= 0) return true;
							return false;
						})
						.map((p, i) => (
							<div className="col s6 m4 l3" key={i}>
								<div className="card square">
									<div className="card-content content">
										<span className="card-title JCLP-blue-text ">
											<span style={{ fontWeight: '400', fontSize: '22px' }}>{p.title}</span>
										</span>
										<p style={{ margin: '0', fontSize: '16px' }}>{p.date}</p>
										{p.quote && p.quote.length > 0 && (
											<p style={{ fontSize: '17px' }}>
												<i>"{p.quote}"</i>
											</p>
										)}

										<a className="pressReadMore" href={p.link} target="_blank">
											Read More
										</a>
									</div>
								</div>
							</div>
						))}
					<div className="clearfix"></div>
					<br />
					<br />
				</div>
			</div>
		</div>
	);
};

const PageLoader = () => {
	const [data, setData] = useState([]);
	const [fetching, setFetching] = useState(false);

	useEffect(() => {
		const get = () => {
			const data = { page: 'newsletters' };

			setFetching(true);
			Axios({
				method: 'POST',
				url: `${API_URL}resources.cfc?method=get`,
				headers: {
					'Content-Type': 'application/json',
				},
				data,
			})
				.then((r) => r.status === 200 && r.data)
				.then((d) => {
					setData(d.data);
					setFetching(false);
				});
		};

		get();
	}, []);

	return fetching ? <>LOADING . . .</> : <Page data={data} />;
};

export default PageLoader;
