import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Accordion from '../../components/Collapsible';
import Parallax from '../../components/LazyParallax';
import PaImg from '../../images/faq.jpg';

const Page = () => {
	const faqs = [
		{
			title: 'What is the mission of the Jewish Community Legacy Project (JCLP)?',
			body: <span>The Jewish Community Legacy Project (JCLP) is the only organization exclusively providing dedicated resources and solutions for small Jewish congregations in the US and Canada to navigate successfully current challenges, prepare responsibly for the future and ensure an enduring community legacy.</span>,
		},
		{
			title: 'What does JCLP do?',
			body: (
				<div>
					<span>
						Jewish Community Legacy Project (JCLP) is the only organization focused exclusively on empowering small Jewish congregations in the US and Canada to secure their futures. With a deep understanding of the needs unique to these congregations, JCLP provides the planning resources and solutions specific to the challenges associated with operational growth, sustainability, or
						decline. Leveraging its proven experience, as well as relationships with local and national partners, JCLP facilitates effective plans of action for the sustainability of congregational life and long-range planning. JCLP supports small Jewish congregations through two unique programs, both offered free of charge. At their core, these services help drive increased
						congregational sustainability and growth in today’s communities. Please click on the links below to learn more:{' '}
					</span>
					<ul>
						<li>
							<Link to="/Congregation-Continuity">Congregation Continuity: Individualized, One-on-One Congregation Collaboration</Link>
						</li>
						<li>
							<Link to="/Congregation-Connections">Congregation Connections: Facilitated, Peer Congregation Networks</Link>
						</li>
					</ul>
				</div>
			),
		},
		{
			title: 'How can JCLP help my synagogue build upon its current stability and/or growth? Alternatively, how can JCLP help my synagogue better manage its current state of decline? ',
			body: (
				<span>
					No matter where a synagogue is along the operations life cycle, JCLP brings opportunities at each stage for needed, constructive support and intervention. With experience in daily and long-term congregation operations, fiduciary and asset management, congregation engagement and more, JCLP is the destination for congregations focused on continuity through one-on-one planning and
					consulting. Key areas of expertise include membership growth and engagement, leadership succession, fundraising, clergy support, historic preservation cemetery support and more. JCLP also facilitates peer congregation cohort networks. These regional congregation cohorts are the destination for synagogues looking for a peer community, networking opportunities, operational
					resources and problem-solving support. Monthly cohort meetings provide the opportunity for congregation leadership – both professional and lay - to build relationships with other like congregations, share challenges and successes, engage in collaborative programming, and learn from JCLP-provided resources and thought leadership on best practices. The result of JCLP’s
					individualized consulting and/or peer congregation networks is the greater sustainability and longevity of small Jewish congregations.{' '}
				</span>
			),
		},
		{
			title: 'What are the benefits of JCLP’s facilitated peer networks?',
			body: (
				<span>
					Participation in JCLP-facilitated congregation cohorts is ideal for congregations looking for a peer community, networking opportunities, operational resources and collaborative problem-solving support. Monthly cohort meetings provide the opportunity for congregation leadership – both professional and lay - to build relationships with other like congregations, share challenges
					and successes, engage in relevant programming, and learn from JCLP-provided resources and thought leadership on best practices. As congregations bond, learn from and become resources to one another, this facilitation also minimizes any isolation that may be felt by small Jewish congregations, searching for colleagues experiencing similar challenges and situations. The result is
					proven, real time mentoring and collaboration from JCLP, associated experts and peer congregations.
				</span>
			),
		},
		{
			title: 'What is a Legacy Plan and what is the process for developing one?',
			body: (
				<span>
					JCLP aims to help small, mature Jewish congregations face the challenges brought on by dwindling membership and changing demographics by creating a “Communal Legacy Plan” to honor the past and perpetuate the heritage of these congregations into the future. JCLP facilitates the planning process on such issues as current congregational activity, perpetual cemetery maintenance,
					placement of religious and historical artifacts, and continuation of philanthropy. Legacy Plans establish endowments that indicate how congregational assets can be distributed to support a variety of Jewish causes reflective of communities’ interests and values. Plans have included provisions for youth camping and Israel travel scholarships, social welfare projects, and
					Holocaust education. New and growing congregations and other Jewish institutions in the United States, Israel, and around the world have received donations of Torah scrolls and other ritual objects. Historical documents are now in the care of both local and national Jewish museums and archives. Photographic essays of the synagogue and oral histories of congregations are also
					recorded.{' '}
				</span>
			),
		},
		{
			title: 'How do I learn more about how JCLP can help my congregation?',
			body: (
				<span>
					To determine how JCLP can best meet the needs of your congregation, please <Link to="/Contact">reach out</Link> to learn more, or call 678-429-8895.{' '}
				</span>
			),
		},
		{
			title: 'What is the cost of JCLP’s services?',
			body: (
				<span>
					There is no charge to work with JCLP. Congregations are asked to pay for out-of-pocket expenses. Operational funding for JCLP is provided by individual gifts and grants from foundations, Jewish Federations, and congregations. Client congregations are invited to consider an allocation to JCLP in their plans to fund outreach to other congregations in need of similar support.
				</span>
			),
		},
		{
			title: 'How is JCLP managed?',
			body: (
				<span>
					The Jewish Community Legacy Project is a 501 (c) 3 organization governed by a distinguished Board of Trustees comprised of Jewish Federation, synagogue and educational institution executives and volunteers, clergy, and representatives of partner organizations. Additionally, there is a community lay advisory committee. Please <Link to="/Board-Advisors">click here</Link> to see a
					complete list of current Board Members.
				</span>
			),
		},
		{
			title: 'Must congregations belong to one of the partner organizations?',
			body: <span>No. JCLP works with congregations of all Jewish denominations regardless of affiliation, as well as with those that are unaffiliated. </span>,
		},
	];

	return (
		<div>
			<Parallax source={PaImg}>
				<h1>
					FAQ
					<br />
					<span className="subTittle">Answers for Jewish congregations</span>
				</h1>
			</Parallax>
			<div className="container content800">
				<br />
				<br />

				<Accordion elements={faqs} />

				<br />
				<p>
					For more information please contact:
					<br />
					Noah Levine
					<br />
					<a href="mailto:nlevine@jclproject.org">nlevine@jclproject.org</a>
				</p>

				<br />
				<br />
			</div>
		</div>
	);
};

export default Page;
