import React, { useEffect, useContext, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch } from 'react-router-dom';
import { BlogContext } from '../../context/blog-context';
import { useForm } from 'react-hook-form';
import Blog from './page';
import Axios from 'axios';
import { API_URL } from '../../config';
import moment from 'moment';
import Parallax from '../../components/LazyParallax';
import { Helmet } from 'react-helmet';
import useGtag from '../../hooks/useGtag';

export const Posts_Loader = ({ match }) => {
	// posts = []{}title,excerpt,content,by{}(name,(date)posted),#comments
	const { setPosts, setFetching, fetching, posts } = useContext(BlogContext);

	console.log('posts via posts loader', { posts });

	return <>{fetching ? <>LOADING . . .</> : <Posts posts={posts} match={match} />}</>;
};

const Posts = ({ posts, match }) => {
	useGtag();

	const showPosts =
		posts &&
		posts.filter((el) => {
			const qs = el.title + ' ' + el.content;
			return !('q' in match.params) ? true : 'q' in match.params && (el.category.toLowerCase() === match.params.q.toLowerCase() || qs.toLowerCase().includes(match.params.q.toLowerCase()));
		});

	return (
		<>
			<Helmet>
				<title>Blog : Jewish Community Legacy Project</title>
			</Helmet>
			<div className="container max-width inner">
				<div className="white" style={{ padding: '10px' }}>
					<div className="row">
						<div className="col s12 l8">
							<div className="post-list">
								{showPosts && showPosts.length === 0 && <h5>NO POSTS FOUND</h5>}
								{showPosts &&
									showPosts.map((el, i) => (
										<div className="col s12 blog-entry d-md-flex" key={i}>
											<div className="img">
												<div className="square">
													<div className="sq-content valign-wrapper circle">
														{/* <img src={`${el.feature_image.length > 0 ? el.feature_image : 'http://develop-jclproject-org.janus-thesabresolution-com.vps.ezhostingserver.com/static/media/JCLP-logo.45c203c8.jpg'}`} alt={el.title} className="responsive-img" style={{ minHeight: '100%', minWidth: '100%', margin: '0 auto' }} /> */}
													</div>
												</div>
											</div>
											<div className="text">
												<h2 className="robotoSlab">
													<Link to={`/blog/${el.slug}`}>{el.title}</Link>
												</h2>
												<div className="meta-wrap">
													<p className="meta ">
														<nobr className="flex">
															<span className="valign-wrapper">
																<i className="material-icons">date_range</i>
																{moment(el.publishedat).format('MMM DD, YYYY')}
															</span>
															{/* {el.category.length > 0 && (
																<span className="valign-wrapper link">
																	<i className="material-icons">folder_open</i>
																	{el.category}
																</span>
															)} 
															<span className="valign-wrapper link">
																<i className="material-icons">comment</i>
																{el.comments} Comment
															</span>
															*/}
														</nobr>
													</p>
												</div>
												<div className="excerpt">
													<div dangerouslySetInnerHTML={{ __html: el.excerpt }} />
												</div>
												<p>
													<Link className="btn btn-small " to={`/blog/${el.slug}`}>
														Read more
														<i className="material-icons right">chevron_right</i>
													</Link>
												</p>
											</div>
										</div>
									))}
							</div>
						</div>
						<div className="hide-on-med-and-down col s12 l4">
							<SidebarRecent />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const SidebarRecent = () => {
	const { posts, setFetching, setPosts } = useContext(BlogContext);
	const [f, setF] = useState(false);
	// const [categories, setCategories] = useState(null);
	useEffect(() => {
		if (posts.length === 0 && !f) {
			const data = {};
			setFetching(true);
			Axios({
				method: 'POST',
				url: `${API_URL}blog.cfc?method=getPosts`,
				headers: {
					'Content-Type': 'application/json',
				},
				data,
			})
				.then((r) => r.status === 200 && r.data)
				.then((d) => {
					console.log({ d });
					return d;
				})
				.then((d) => d.success && setPosts(d.data))
				.then((d) => setF(true));
		}

		// Axios({
		// 	method: 'GET',
		// 	url: `${API_URL}blog.cfc?method=getPostMetas`,
		// 	headers: {
		// 		'Content-Type': 'application/json',
		// 	},
		// })
		// 	.then((r) => r.status === 200 && r.data)
		// 	// .then((d) => console.log({ d })),
		// 	.then((d) => d.success && setCategories(d.categories));
	}, []);

	return (
		<>
			<div style={{ paddingLeft: '20px' }}>
				{/* <div className="categories">
					<h5>Categories</h5>
					<div className="collection">
						{categories &&
							categories.map((el, i) => (
								<Link style={{ padding: '5px 20px' }} to={`/blog/search/${el.content}`} className="collection-item black-text">
									<i className="material-icons right">chevron_right</i>
									{el.content}
								</Link>
							))}
					</div>
					<br />
				</div> */}
				<div className="recent-posts">
					<h5>Recent Posts</h5>
					{posts &&
						posts.map((el, i) => (
							<div className="flex" key={i}>
								{/* <div className="img" style={{ minWidth: '80px', height: '80px' }}>
									<div className="square">
										<Link to={`/blog/${el.slug}`}>
											<div className="sq-content valign-wrapper circle">
												<img src={`${el.feature_image.length > 0 ? el.feature_image : 'http://develop-jclproject-org.janus-thesabresolution-com.vps.ezhostingserver.com/static/media/JCLP-logo.45c203c8.jpg'}`} alt={el.title} className="responsive-img" style={{ minHeight: '100%', minWidth: '100%', margin: '0 auto' }} />
												
											</div>
										</Link>
									</div>
								</div> */}
								<div className="text">
									<h3>
										<Link to={`/blog/${el.slug}`}>{el.title}</Link>
									</h3>
								</div>
							</div>
						))}
				</div>
			</div>
		</>
	);
};

export const Post_Loader = ({ match }) => {
	const { posts } = useContext(BlogContext);

	const [post, setPost] = useState(null);

	useEffect(() => {
		const filter = posts.filter((e) => {
			console.log('filtering', e.slug, match.params.post);
			return e.slug === match.params.post;
		})[0];

		// console.log('useeffect',filter)
		if (filter) {
			console.log('setting to', { filter });
			setPost(filter);
		}
	}, [posts, match.params.post]);

	// useEffect(() => {
	// 	setFetching(true);

	// 	// try searching posts
	// 	let _post = posts.filter((e) => e.slug === match.params.post);

	// 	if (_post.length > 0) {
	// 		setPost(_post[0]);
	// 		setFetching(false);
	// 		return;
	// 	}

	// 	const data = { slug: match.params.post };

	// 	Axios({
	// 		method: 'POST',
	// 		url: `${API_URL}blog.cfc?method=getPosts`,
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 		},
	// 		data,
	// 	})
	// 		.then((r) => r.status === 200 && r.data)
	// 		.then((d) => {
	// 			setPost(d);
	// 			setFetching(false);
	// 		});
	// }, []);

	console.log('in post_loader', { posts }, { post });
	return <>{!post ? <></> : <Post post={post} />}</>;
};

const Post = ({ post }) => {
	useGtag();

	return (
		<>
			<Helmet>
				<title>{post.title} : Jewish Community Legacy Project</title>
			</Helmet>
			<div className="container max-width inner">
				<div className="white" style={{ padding: '10px' }}>
					<div className="row">
						<div className="-flex -fill">
							<div class="col s12 l8">
								<div className="post-list">
									{post && (
										<div className="post-page">
											<h1 style={{ marginTop: '0' }}>{post.title}</h1>
											<div className="content" style={{ position: 'relative' }}>
												<div dangerouslySetInnerHTML={{ __html: post.content }} />
											</div>
										</div>
									)}
								</div>
								{/* <div className="row">
									<CommentsWidget post={post} />
								</div> */}
							</div>
							<div className="hide-on-med-and-down col s12 l4">
								<SidebarRecent />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const CommentsWidget = ({ post }) => {
	const createCommentRef = React.useRef(null);
	const [comments, setComments] = useState(null);

	const goToReply = () => {
		if (createCommentRef) window.scrollTo({ top: createCommentRef.current.offsetTop - 200, behavior: 'smooth' });
	};

	useEffect(() => {
		// get comments
		if (post) {
			const data = {
				postid: post.id,
			};

			console.log({ post });
			console.log({ data });

			Axios({
				method: 'POST',
				url: `${API_URL}blog.cfc?method=getComments`,
				headers: {
					'Content-Type': 'application/json',
				},
				data,
			})
				.then((r) => r.status === 200 && r.data)
				.then((d) => {
					d.success && setComments(d.comments);
				})
				.catch((e) => setComments(null));
		}
	}, [post]);

	const sanitise = (text) => {
		var s = text;
		// smart single quotes and apostrophe
		s = s.replace(/[\u2018|\u2019|\u201A]/g, "'");
		// smart double quotes
		s = s.replace(/[\u201C|\u201D|\u201E]/g, '"');
		// ellipsis
		s = s.replace(/\u2026/g, '...');
		// dashes
		s = s.replace(/[\u2013|\u2014]/g, '-');
		// circumflex
		s = s.replace(/\u02C6/g, '^');
		// open angle bracket
		s = s.replace(/\u2039/g, '<');
		// close angle bracket
		s = s.replace(/\u203A/g, '>');
		// spaces
		s = s.replace(/[\u02DC|\u00A0]/g, ' ');

		return s;
	};

	const createComment = (values) => {
		const data = {
			...values,
			postid: post.id,
			author: sanitise(values.author),
			content: sanitise(values.content),
		};

		// Axios({
		// 	method: 'POST',
		// 	url: `${API_URL}blog.cfc?method=createComment`,
		// 	headers: {
		// 		'Content-Type': 'application/json',
		// 	},
		// 	data,
		// })
		// 	.then((r) => r.status === 200 && r.data)
		// 	.then((d) => {
		// 		d.success && d.comment.id && setComments([...comments, ...[d.comment]]);
		// 	});
	};

	return (
		<>
			<div className="col s12">
				<h5>
					{post && post.count} Comments
					<div className="right">
						<button type="button" onClick={goToReply}>
							REPLY
						</button>
					</div>
				</h5>
				<ul className="comment-list">
					{comments &&
						comments.map((el, i) => (
							<li key={i} className="comment">
								<div className="comment-body">
									<h3>{el.author}</h3>
									<div className="meta">{moment(el.createdBy).format('MMM D, YYYY')}</div>
									<div className="content">{el.content}</div>
								</div>
							</li>
						))}
				</ul>
				<div ref={createCommentRef} className="create-comment">
					<CreateComment post={post} onSubmit={createComment} />
				</div>
			</div>
		</>
	);
};

const CreateComment = ({ post, onSubmit }) => {
	const { handleSubmit, register, reset } = useForm();

	return (
		<>
			<form
				onSubmit={handleSubmit((v) => {
					onSubmit(v);
					reset();
				})}
			>
				<div className="row" style={{ margin: '0' }}>
					<div className="input-field col s6">
						<input type="text" name="author" ref={register({ required: 'Username is required' })} placeholder="Name / Username" />
					</div>
				</div>
				<div className="row" style={{ margin: '0' }}>
					<div className="input-field col s12">
						<input type="text" name="content" ref={register({ required: 'Comment is required' })} placeholder="Comment . . ." />
					</div>
				</div>
				<div className="row" style={{ margin: '0' }}>
					<div className="col s12">
						<button className="btn btn-small green white-text" type="submit">
							POST
						</button>
					</div>
				</div>
			</form>
		</>
	);
};

export default Posts_Loader;

// match: isExact: true;
// params: {
// }
// path: '/Forum';
// url: '/Forum';
