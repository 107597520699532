import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import scriptLoader from 'react-async-script-loader';
import { v4 as uuidv4 } from 'uuid';
import Form from './form';
import M from 'materialize-css';
import Axios from 'axios';
import { API_URL } from '../../config';
import { useForm } from 'react-hook-form';
import CryptoJS from 'crypto-js';
// import JSEncrypt from 'jsencrypt';

const ENV = process.env.NODE_ENV === 'production' ? 'production' : 'sandbox';

//PAYPAL U : sb-1c3h13946610@personal.example.com
//PAYPAL P : *9Ah1R%k

const CLIENT = {
	sandbox: 'AVPRZByhOvd_h2Q-10UiSxp78GJC-qKobgZAVIgZZguhMoGxUuS8hWofUW1rK9OFad3dj1q0EKheL_pE',
	production: 'ARx7ouuRRivfjWE9MNHEeLYQywS13LyoTZoh26u-_llZlkJ5dXvoTGtc7e1qufGY4mpGKQ2VeJurdG7N',
	// sandbox: 'AfyYYswWAFV9yOafrefQnllVfWuX7WwdrkTau3-3IznNidFYutjvYgiwhIDptG6N7PuOg-owzUuMieGR',
	// production: 'AbjRZUyXWkaRUyD_NGo0s1CznLsqgxYci30WP-gQYQck0GDF7Js3FKFINSpuT1O_VkzNvFi4pj2ydjOc',
};

const PaypalWrapper = ({ isScriptLoaded, isScriptLoadSucceed }) => {
	const [[state, loaded], setState] = useState([null, false]);

	useEffect(() => {
		if (isScriptLoaded && isScriptLoadSucceed) {
			// load finished
			console.log('loaded script', window.paypal);
			setState([{ paypal: window.paypal }, true]);
		}
	}, [isScriptLoaded, isScriptLoadSucceed]);

	return loaded ? <Page {...state} /> : <>loading. . .. . </>;
};

const Page = ({ paypal }) => {
	const PaypalButton = paypal.Buttons.driver('react', { React, ReactDOM });
	const { register, handleSubmit, errors, trigger, watch, formState, getValues } = useForm({ mode: 'onChange' });

	const [[amount, description], setPayment] = useState([0.01, 'Donation']);
	// const [doSetActive, setActive] = useState((a) => console.log('actions not setup', { a }));
	const [enabled, setEnabled] = useState(false);
	const [method, setMethod] = useState('');

	const w_amount = watch('gift.amount');
	const history = useHistory();

	const custom_id = `${uuidv4()}`;

	React.useEffect(() => {
		setPayment([w_amount, 'Donation']);
	}, [w_amount]);

	const createOrder = (data, actions) => {
		return actions.order.create({
			// intent: 'AUTHORIZE',
			purchase_units: [
				{
					amount: {
						value: amount,
					},
					description,
					custom_id: custom_id,
				},
			],
		});
	};

	const onApprove = (data, actions) => {
		// console.log('onApprove', { data }, { actions });

		// return;
		return actions.order.capture().then((d) => {
			console.log('after capture', { d });

			Axios({
				method: 'POST',
				url: `${API_URL}webmail.cfc?method=_donation`,
				headers: {
					'Content-Type': 'application/json',
				},
				data: {
					...data,
					...getValues(),
					payment: {
						type: 'Paypal',
						email: d.payer.email_address,
						amount: d.purchase_units.reduce((total, el) => (total += el.amount.value), 0),
					},
				},
			})
				.then((r) => r.status === 200 && r.data)
				.then((d) => {
					if (d.success) {
						M.toast({ html: 'Donation successful! Thank you!' });
						history.push('/donation-thanks');
					} else {
						M.toast({ html: d.message });
					}
				});
		});
	};

	// const createSubscription = (d, actions) => {
	// 	return actions.subscription.create({
	// 		plan_id: 'new plan', // Creates the subscription
	// 	});
	// };

	const onClick = (d, actions) => {
		return trigger().then((r) => {
			if (r) {
				return actions.resolve();
			} else {
				M.toast({ html: 'Please review required fields' });
			}
			return actions.reject();
		});
	};

	const getEncrypted = (v, base64Key = 'WMKTWiiQuVh3pOIuif41RA==', base64IV = 'g6ktXZkMi/xll1oMso+Huw==') => {
		const key = CryptoJS.enc.Base64.parse(base64Key);
		const iv = CryptoJS.enc.Base64.parse(base64IV);

		return CryptoJS.AES.encrypt(v, key, { iv: iv }).toString();
	};

	const onSubmit = (v) => {
		const data = {
			...v,
			e: getEncrypted(`${JSON.stringify(v.payment)}`),
		};

		Axios({
			method: 'POST',
			url: `${API_URL}donation.cfc?method=donate`,
			headers: {
				'Content-Type': 'application/json',
			},
			data,
		})
			.then((r) => r.status === 200 && r.data)
			.then((d) => {
				if (d.success) {
					M.toast({ html: 'Donation successful! Thank you!' });
					history.push('/donation-thanks');
				} else {
					M.toast({ html: d.message });
				}
			})
			.catch((e) => M.toast({ html: 'Unable to process your donation.' }));
	};

	const FormData = {
		setEnabled,
		register,
		errors,
		setMethod,
		method,
	};

	return (
		<>
			{/* <button onClick={() => setEnabled(true)}>CLICK ME</button> */}
			<form onSubmit={handleSubmit(onSubmit)}>
				{/* <button type="button" onClick={onSubmit}>
					CLICK
				</button> */}
				<div className="card">
					<div className="card-content">
						<Form {...FormData} />
						{method === 'paypal' && (
							<>
								<br />
								<fieldset>
									<legend>Paypal</legend>
									<div className="center-align">
										{/* <PaypalButton createSubscription={(d, actions) => createSubscription(d, actions)} onClick={(data, actions) => onClick(data, actions)} createOrder={(data, actions) => createOrder(data, actions)} onApprove={(data, actions) => onApprove(data, actions)} /> */}
										<PaypalButton onClick={(data, actions) => onClick(data, actions)} createOrder={(data, actions) => createOrder(data, actions)} onApprove={(data, actions) => onApprove(data, actions)} />
									</div>
								</fieldset>
							</>
						)}
					</div>
				</div>
			</form>
		</>
	);
};

// export default PaypalWrapper;
export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${ENV === 'production' ? CLIENT.production : CLIENT.sandbox}`)(PaypalWrapper);
