import React from 'react';
import Modal from './Modal';
import { useForm } from 'react-hook-form';
import M from 'materialize-css';
import Axios from 'axios';
import { API_URL } from '../config';

const Form = ({ onSubmit }) => {
	const { register, handleSubmit, errors } = useForm();

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<h5>Newsletter Sign-up</h5>
			<p style={{ fontSize: '1em' }}>Please enter your name and email address to enable JCLP to deliver our newsletter to you! JCLP will not share your contact information and you will be able to unsubscribe from the newsletter list at any time. Thank you!</p>
			<div className="form-group">
				<label htmlFor="name">Name</label>
				<input className="form-control" name="name" id="name" ref={register} />
			</div>
			<div className="form-group">
				<label htmlFor="email">Email address</label>
				<input type="email" className="form-control" name="email" id="email" placeholder="email@domain.com" ref={register({ required: 'Email is required' })} />
				{errors && errors.email && <span className="red-text helper-text">{errors.email.message}</span>}
			</div>
			<div className="form-group">
				<button className="form-control btn btn-primary" type="submit">
					Submit
				</button>
			</div>
		</form>
	);
};

export default Form;

export const SignupTrigger = () => {
	const [shown, setShown] = React.useState(false);
	const modalRef = React.useRef(null);
	const buttonRef = React.useRef(null);

	const showModal = () => {
		// console.log('trigger modal');
		setShown(true);
	};

	const closeModal = () => {
		// console.log('trigger modal');
		setShown(false);
	};

	const onKeyDown = (e) => {
		if (e.keyCode === 27) {
			closeModal();
		}
	};

	const onClickOutside = (e) => {
		if (modalRef && modalRef.current && modalRef.current.contains(e.target)) return;
		console.log(modalRef.current, e.target, modalRef.current.contains(e.target));
		closeModal();
	};

	const toggleScrollLock = () => {
		document.querySelector('html').classList.toggle('scroll-lock');
	};

	const onSubmit = (v) => {
		console.log('submitted', { v });

		const secret = 'IHqDa854nuBgg3BLLnhU';
		const email = v.email;
		const name = v.email;
		const e = btoa(`${email}:${secret}`);

		const data = {
			e,
			Name: v.name,
			// CustomFields: [
			// 	{
			// 		Key: 'website',
			// 		Value: 'http://example.com',
			// 	},
			// 	{
			// 		Key: 'interests',
			// 		Value: 'magic',
			// 	},
			// 	{
			// 		Key: 'interests',
			// 		Value: 'romantic walks',
			// 	},
			// ],
			Resubscribe: true,
			RestartSubscriptionBasedAutoresponders: true,
			ConsentToTrack: 'Yes',
		};

		Axios({
			url: `${API_URL}campainmonitor.cfc?method=addSubscriber`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			data,
		})
			.then((r) => r.status === 200 && r.data)
			.then((d) => {
				console.log({ d });
				if (d.Statuscode === '201 Created' || (d.status_code === 201 && d.status_text === 'Created')) {
					M.toast({ html: 'Thank you for joining our newsletter!' });
				} else {
					M.toast({ html: 'Unable to add you to our newsletter!' });
				}
			});
	};

	const style = {
		border: '0',
		cursor: 'pointer',
		color: '#002677',
	};

	return (
		<>
			<button style={style} type="button" onClick={showModal} className="waves-effect waves-teal">
				SUBSCRIBE TO OUR NEWSLETTER
			</button>
			{shown && <Modal ref={[modalRef, buttonRef]} Form={Form} onSubmit={onSubmit} modalRef={(x) => (modalRef = x)} buttonRef={buttonRef} closeModal={closeModal} onKeyDown={onKeyDown} onClickOutside={onClickOutside}></Modal>}
		</>
	);
};

// export default SignupTrigger;
