import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { CssHero as Parallax } from '../../components/LazyParallax';
import PaImg from '../../images/contact.jpg';
import M from 'materialize-css';
import interHome from '../../images/inter-hero02.jpg';
import { useForm } from 'react-hook-form';
import Image1 from './stained-glass-300x900.jpg';
import Image2 from './stained-glass-300x2500.jpg';
import Axios from 'axios';
import { API_URL } from '../../config';
import { useHistory } from 'react-router-dom';
import useGtag from '../../hooks/useGtag';
import { Helmet } from 'react-helmet';

const Page = () => {
	useGtag();
	const history = useHistory();
	

	useEffect(() => {
		// M.Sidenav.init(document.getElementById('mobile-nav'));
		// const elems = document.querySelectorAll('.collapsible');

		const elems = document.querySelectorAll('select');
		const instances = M.FormSelect.init(elems, []);
	}, []);

	const { register, handleSubmit, errors } = useForm();
	const onSubmit = (data) => {
		Axios({
			method: 'POST',
			url: `${API_URL}joinform.cfc?method=_contact`,
			headers: {
				'Content-Type': 'application/json',
			},
			data,
		})
			.then((r) => r.status === 200 && r.data)
		//	.then((d) => M.toast({ html: 'Thank you for your submission.' }))
			.then((d) => document.getElementById('confirm').style.display = "block");
	};
	 console.log(errors);

	return (
		<div>
			<Helmet>
				<title>Gathering of Small Communities</title>
			</Helmet>
			<Parallax source={[Image1, Image2]}>
				<h1>
				Gathering of Small Communities
				
				</h1>
			</Parallax>
			<div className="confirmText" id="confirm">Thank you for registering! <br/>We are looking to seeing you on December 3!</div>
			<div className="allblue">
				<br/>
			<div className="container row content800 bluecol goldcol">
				<br />
				<br />
				{/* <img src={interHome} /> */}
				
				<div className="clearfix"></div>
				<br />
				<br />
				<h2 className="JCLP-blue-text-center goldtext">Event Completed</h2>
				
			</div>
			</div>
		</div>
	);
};

export default Page;
