import Axios from 'axios';
import React, { createContext, useReducer, useEffect } from 'react';
import { API_URL } from '../../config';
import { createReducer } from './blogReducer';

export const BlogContext = createContext();

// const storage = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
const initialState = { posts: [], fetching: false };

const serialize = (obj) => {
	const str = Object.keys(obj)
		.reduce(function (a, k) {
			a.push(k + '=' + encodeURIComponent(obj[k]));
			return a;
		}, [])
		.join('&');
	return str;
};

const slugify = (string) => {
	const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
	const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
	const p = new RegExp(a.split('').join('|'), 'g');

	return string
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
		.replace(/&/g, '-and-') // Replace & with 'and'
		.replace(/[^\w\-]+/g, '') // Remove all non-word characters
		.replace(/\-\-+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, ''); // Trim - from end of text
};

const BlogContextProvider = ({ children }) => {
	const memorizedReducer = React.useCallback(createReducer(), []);
	const [state, dispatch] = useReducer(memorizedReducer, initialState);

	useEffect(()=>{
		Axios({
			method: 'POST',
			url: `${API_URL}blog.cfc?method=getPosts`,
			headers: {
				'Content-Type': 'application/json',
			},
			data:{}
		})
			.then((r) => r.status === 200 && r.data)
			// .then((d) => console.log({ d })),
			.then((d) => {
				console.log('fetched in context',{d});
				setPosts(d.data);
				// d.contructor === Array ? setPosts(d.data) : setPosts([d.data]);
			})
			.catch((de) => {
				console.log({de})
				setPosts([]);
			});
		// .then((d) => setFetching(false));
	},[]);

	useEffect(() => {
		// state.categories && localStorage.setItem('f-categories', JSON.stringify(state.categories.length > 0 ? state.categories : []));
	}, [state.categories]);

	const setPosts = (payload) => {
		if (payload.length > 0) dispatch({ type: 'SET_POSTS', payload });
	};

	const setFetching = (payload) => {
		dispatch({ type: 'SET_FETCHING', payload });
	};

	const contextValues = {
		...state,
		setPosts,
		setFetching,
	};

	return <BlogContext.Provider value={contextValues}>{children}</BlogContext.Provider>;
};

export default BlogContextProvider;
