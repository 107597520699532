import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import M from 'materialize-css';
import interHome from '../../images/inter-hero02.jpg';
import { useForm } from 'react-hook-form';

const Page = () => {
	useEffect(() => {
		// M.Sidenav.init(document.getElementById('mobile-nav'));
		// const elems = document.querySelectorAll('.collapsible');

		const elems = document.querySelectorAll('select');
		const instances = M.FormSelect.init(elems, []);
	}, []);

	const { register, handleSubmit, errors } = useForm();
	const onSubmit = (data) => console.log('submit', { data });
	// console.log(errors);

	return (
		<div>
			<div className="parallax parallaxImg02">
				<h1>
					CONTACT JCLP
					{/* <br />
					<span className="subTittle">PLanning for SMALL CONGREGATIONS</span> */}
				</h1>
			</div>
			<div className="container row content800">
				<br />
				<br />
				{/* <img src={interHome} /> */}
				<div style={{ textAlign: 'center' }}>
					<div className="col s6 m3 l3 ssIcon" style={{ padding: '20px 0' }}>
						<a href="mailto:Info@JCLProject.org" className="eIcon">
							<i className="fas fa-envelope-square"></i>
						</a>
						<p style={{ marginTop: '0' }}>Info@JCLProject.org</p>
					</div>
					<div className="col s6 m3 l3 lineBtw ssIcon" style={{ padding: '20px 0' }}>
						<a href="tel:9123544047" className="phIcon">
							<i className="fas fa-phone-square-alt"></i>
						</a>
						<p style={{ marginTop: '0' }}>912-354-4047</p>
					</div>
					<div className="col s6 m3 l3 lineBtw ssIcon" style={{ padding: '20px 0' }}>
						<a href="https://www.facebook.com/TheJCLP" className="fbIcon" target="_blank">
							<i className="fab fa-facebook-square"></i>
						</a>
						<p style={{ marginTop: '0' }}>Facebook</p>
					</div>

					<div className="col s6 m3 l3 lineBtw ssIcon" style={{ padding: '20px 0' }}>
						<a href="https://www.linkedin.com/company/jewish-community-legacy-project-inc/about/" className="liIcon" target="_blank">
							<i className="fab fa-linkedin"></i>
						</a>
						<p style={{ marginTop: '0' }}>LinkedIn</p>
					</div>
				</div>
				<div className="clearfix"></div>
				<br />
				<br />
				<h2 className="JCLP-blue-text">Send Us a Message:</h2>
				<div className="card">
					<div className="card-content">
						<form onSubmit={handleSubmit(onSubmit)}>
							<fieldset>
								<legend>Contact Information</legend>
								<div className="row">
									<div className="input-field col s12 m6 l6 ">
										<input type="text" name="first_name" ref={register({ required: 'First Name is required' })} />
										<label htmlFor="first_name">First Name *</label>
										{errors && errors.first_name && <span className="red-text helper-text">{errors.first_name.message}</span>}
									</div>
									<div className="input-field col s12 m6 l6 ">
										<input type="text" name="last_name" ref={register({ required: 'Last Name is required' })} />
										<label htmlFor="last_name">Last Name *</label>
										{errors && errors.last_name && <span className="red-text helper-text">{errors.last_name.message}</span>}
									</div>
									<div className="input-field col s12 m6 l6 ">
										<input type="text" name="organization" ref={register({ required: 'Organization is required' })} />
										<label htmlFor="organization">Organization *</label>
										{errors && errors.organization && <span className="red-text helper-text">{errors.organization.message}</span>}
									</div>
									<div className="input-field col s12 m6 l6 ">
										<input type="text" name="title" ref={register({ required: 'Title is required' })} />
										<label htmlFor="title">Title *</label>
										{errors && errors.title && <span className="red-text helper-text"> {errors.title.message}</span>}
									</div>
									<div className="clearfix"></div>
									<div className="input-field col s12 m6 l6 ">
										<input type="email" name="email" ref={register({ required: 'Email is required' })} />
										<label htmlFor="email">Email *</label>
										{errors && errors.email && <span className="red-text helper-text">{errors.email.message}</span>}
									</div>
									<div className="input-field col s12 m6 l6 ">
										<input type="text" name="phone" ref={register({ required: 'Phone is required' })} />
										<label htmlFor="phone">Phone *</label>
										{errors && errors.phone && <span className="red-text helper-text">{errors.phone.message}</span>}
									</div>
									<div className="clearfix"></div>
									<div className="input-field col s12 m6 l6 ">
										<input type="text" name="address" ref={register} />
										<label htmlFor="address">Address</label>
									</div>
									<div className="input-field col s12 m6 l6 ">
										<input type="text" name="apt_suite_bldg" ref={register} />
										<label htmlFor="apt_suite_bldg">Apt, Suite, Bldg. (optional)</label>
									</div>
									<div className="input-field col s12 m6 l6 ">
										<input type="text" name="city" ref={register} />
										<label htmlFor="city">City</label>
									</div>
									<div className="input-field col s12 m6 l6 ">
										<input type="text" name="state" ref={register} />
										<label htmlFor="state">State / Province / Region</label>
									</div>
									<div className="clearfix"></div>
									<div className="input-field col s12 m6 l6 ">
										<input type="text" name="zip" ref={register} />
										<label htmlFor="zip">Postal / Zip Code</label>
									</div>
									<div className="input-field col s12 m6 l6 ">
										<input type="text" name="country" ref={register} />
										<label htmlFor="country">Country</label>
									</div>
									<div className="clearfix"></div>
									<div className="input-field col s12 m6 l6 ">
										<input type="text" name="synagogue_affiliation" ref={register({ required: 'Synagogue Affiliation is required' })} />
										<label htmlFor="synagogue_affiliation">Synagogue Affiliation *</label>
										<span className="red-text helper-text">{errors && errors.synagogue_affiliation && errors.synagogue_affiliation.message}</span>
									</div>
									<div className="input-field col s12 m6 l6 ">
										<select defaultValue="" name="synagogue_relationship" ref={register({ required: 'Choose your synagogue relationship' })}>
											<option value="" disabled>
												Choose your option
											</option>
											<option value="congregant">congregant</option>
											<option value="clergy">clergy</option>
											<option value="professional">professional</option>
											<option value="lay leader">lay leader</option>
											<option value="partner organization">partner organization</option>
											<option value="n/a">n/a</option>
										</select>
										<label>Your synagogue relationship:</label>
										<span className="red-text helper-text">{errors && errors.synagogue_relationship && errors.synagogue_relationship.message}</span>
									</div>
								</div>
							</fieldset>
							<br />
							<fieldset>
								<legend>Keep Informed</legend>
								<div className="row">
									<div className="input-field col s12 m6 l6 ">
										<p style={{ fontSize: '16px', margin: '0' }}>
											Would you like to sign up for Newsletter?
											<label>
												<input id="indeterminate-checkbox" type="checkbox" name="signup[newsletter]" ref={register} />
												<span style={{ marginLeft: '10px' }}>&nbsp;</span>
											</label>
										</p>
									</div>
									<div className="input-field col s12 m6 l6 ">
										<p style={{ fontSize: '16px', margin: '0' }}>
											Would you like to register Forum?
											<label>
												<input id="indeterminate-checkbox" type="checkbox" name="signup[forum]" ref={register} />
												<span style={{ marginLeft: '10px' }}>&nbsp;</span>
											</label>
										</p>
									</div>
								</div>
							</fieldset>
							<br />
							<fieldset>
								<legend>Request Information</legend>
								<div className="row">
									<div className="input-field col s12 m12 l12">
										<textarea className="materialize-textarea" name="message" style={{ height: '100px' }} ref={register}></textarea>
										<label>Questions or Comments</label>
									</div>
									<div className="input-field col s12 m12 l12">
										<p style={{ fontSize: '16px', margin: '0' }}>Want information on: (check all that apply)</p>
										<div className="col s6 m3 l3">
											<label>
												<input ref={register} id="indeterminate-checkbox" type="checkbox" name="want[support]" value=" Planning Support" />
												<span>Planning Support</span>
											</label>
										</div>
										<div className="col s6 m3 l3">
											<label>
												<input ref={register} id="indeterminate-checkbox" type="checkbox" name="want[cohorts]" value=" Peer Congregation Cohorts" />
												<span>Peer Congregation Cohorts</span>
											</label>
										</div>
										<div className="col s6 m3 l3">
											<label>
												<input ref={register} id="indeterminate-checkbox" type="checkbox" name="want[donation]" value=" Artifact Donation" />
												<span>Artifact Donation</span>
											</label>
										</div>
										<div className="col s6 m3 l3">
											<label>
												<input ref={register} id="indeterminate-checkbox" type="checkbox" name="want[inquiry]" value=" General Inquiry" />
												<span>General Inquiry</span>
											</label>
										</div>
									</div>
									<div className="input-field col s12 m6 l6 ">
										<select defaultValue="" name="hear_from" ref={register}>
											<option value="" disabled>
												Choose your option
											</option>
											<option>referral</option>
											<option>word of mouth</option>
											<option>marketing</option>
											<option>facebook</option>
											<option>webinar</option>
											<option>other</option>
										</select>
										<label>How did you hear about us?</label>
									</div>
									<div className="clearfix"></div>
								</div>
							</fieldset>
							<br />
							<p className="center">
								<button type="submit" name="submit" className="btn">
									SUBMIT
								</button>
							</p>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Page;
