import React, { useState, useReducer, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { API_URL } from '../../config';

import { Parallax as SmallParallax, Hero as Parallax } from '../../components/LazyParallax';
import Image from '../../images/newsletter.jpg';
import { links } from './links';
import useGtag from '../../hooks/useGtag';
import { Helmet } from 'react-helmet';

const arrayLast = (arr) => arr[arr.length - 1];

const dataFetchReducer = (state, action) => {
	switch (action.type) {
		case 'FETCH_INIT':
			return {
				...state,
				isLoading: true,
				isError: false,
			};
		case 'FETCH_SUCCESS':
			return {
				...state,
				isLoading: false,
				isError: false,
				data: action.payload,
			};
		case 'FETCH_FAILURE':
			return {
				...state,
				isLoading: false,
				isError: true,
			};
		default:
			throw new Error();
	}
};

const useFetchApi = (initialUrl, initialData) => {
	const [url, setUrl] = useState(initialUrl);

	const [state, dispatch] = useReducer(dataFetchReducer, {
		isLoading: false,
		isError: false,
		data: initialData,
	});

	useEffect(() => {
		let didCancel = false;

		const fetchData = async () => {
			dispatch({ type: 'FETCH_INIT' });

			try {
				const data = { page: 'video-programs' };
				const results = await Axios({
					method: 'POST',
					url,
					headers: {
						'Content-Type': 'application/json',
					},
					data,
				}).then((r) => r.status === 200 && r.data);

				console.log({ results });

				if (!didCancel) dispatch({ type: 'FETCH_SUCCESS', payload: results.data });
			} catch (e) {
				if (!didCancel) dispatch({ type: 'FETCH_FAILURE' });
			}
		};

		fetchData();
	}, [url]);

	return [state, setUrl];
};

const VideoProgramsPageWrapper = () => {
	useGtag();

	const [q, setQ] = useState(null);

	const getBottomHeight = () => {
		if (window.innerWidth > 1760) return `${55}%`;
		if (window.innerWidth > 1280) return `${75}%`;
		if (window.innerWidth > 1100) return `${109}%`;
		if (window.innerWidth > 992) return `${130}%`;
		if (window.innerWidth > 830) return `${19 + 22}%`;
		if (window.innerWidth > 570) return `${35 + 22}%`;
		if (window.innerWidth > 452) return `${50 + 22}%`;
		return `${67 + 22}%`;
	};

	const bottomHeight = getBottomHeight();

	const [data, doFetch] = useFetchApi('', []);

	useEffect(() => doFetch(`${API_URL}resources.cfc?method=get`), []);

	const videoprograms = [...data.data, ...links];

	return (
		<>
			<Helmet>
				<title>Video Programs : Jewish Community Legacy Project</title>
			</Helmet>
			{window.innerWidth < 706 ? (
				<SmallParallax source={Image} height={400}>
					<h1>VIDEO PROGRAMS</h1>
				</SmallParallax>
			) : (
				<Parallax source={Image} height={400}>
					<h1>VIDEO PROGRAMS</h1>
				</Parallax>
			)}
			<div className="container">
				<div className="row">
					{/* <div className="center-align">
						<Link to="/Testimonials" className="btn">
							Text
						</Link>
					</div> */}
					<br />
					<div className="col s12 m10 offset-m1 l8 offset-l2" style={{ position: 'relative' }}>
						<input type="text" className="rightTagInput" placeholder="Search any key word(s)" name="bla" id="ss" onChange={(e) => setQ(e.target.value)} />
						<button className="inputTag goldBtn">
							<i className="fas fa-search"></i>
						</button>
					</div>
					<br />

					{videoprograms
						.filter((p) => {
							if (!q) return true;

							if (p.title.toLowerCase().search(q.toLowerCase()) >= 0 || p.description.toLowerCase().search(q.toLowerCase()) >= 0) return true;
							return false;
						})
						.map((tag, i) => (
							<div className="col s12 l6" key={i}>
								<div className="card">
									<div className="square" style={{ paddingBottom: '59%' }}>
										<div className="card-content content" style={{ padding: 0 }}>
											<iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${arrayLast(tag.link.split('v='))}`} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
										</div>
									</div>
									<div className="square hide-on-med-and-down" style={{ paddingBottom: bottomHeight }}>
										<div className="card-content content" style={{ padding: '10px' }}>
											<h5 style={{ margin: 0 }}>{tag.title}</h5>
											{tag.page && <div dangerouslySetInnerHTML={{ __html: tag.description }} />}
											{!tag.page && <p style={{ fontSize: '16px' }}>{tag.description}</p>}
										</div>
									</div>
									<div className="hide-on-large-only" style={{ padding: '10px' }}>
										<h5 style={{ margin: 0 }}>{tag.title}</h5>
										{tag.page && <div dangerouslySetInnerHTML={{ __html: tag.description }} />}
										{!tag.page && <p style={{ fontSize: '16px' }}>{tag.description}</p>}
									</div>
								</div>
							</div>
						))}
				</div>
			</div>
		</>
	);
};

export default VideoProgramsPageWrapper;
