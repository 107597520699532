import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Parallax from '../../components/LazyParallax';
import useGtag from '../../hooks/useGtag';
import PaImg from '../../images/clients.jpg';

const Page = () => {
	useGtag();

	const continuity = [
		{ location: 'Aiken, SC' },
		{ location: 'Alton, IL' },
		{ location: 'Ashtabula, OH' },
		{ location: 'Auburn, ME' },
		{ location: 'Auburn, NY' },
		{ location: 'Bainbridge, GA' },
		{ location: 'Batavia, NY' },
		{ location: 'Battle Creek, MI' },
		{ location: 'Belleville, IL' },
		{ location: 'Benton, IL' },
		{ location: 'Bluefield, WV' },
		{ location: 'Bradford, PA' },
		{ location: 'Butte, MT' },
		{ location: 'Chambersburg, PA' },
		{ location: 'Claremont, NH' },
		{ location: 'Columbus, MS' },
		{ location: 'Council Bluffs, IA' },
		{ location: 'Danville, IL' },
		{ location: 'Decatur, IL' },
		{ location: 'Du Bois, PA' },
		{ location: 'East Liverpool, OH' },
		{ location: 'El Dorado, CA' },
		{ location: 'Elyria, OH' },
		{ location: 'Erie, PA' },
		{ location: 'Fitzgerald, GA' },
		{ location: 'Fort Smith, AR' },
		{ location: 'Fort Wayne, IN' },
		{ location: 'Greensburg, PA' },
		{ location: 'Greenville, MS' },
		{ location: 'Hammonton, NJ' },
		{ location: 'Hamilton, OH' },
		{ location: 'Hattiesburg, MS' },
		{ location: 'Hazleton, PA' },
		{ location: 'Herkimer, NY' },
		{ location: 'Hornell, NY' },
		{ location: 'Hot Springs, AR' },
		{ location: 'Indiana, PA' },
		{ location: 'Iron Mountain, MI' },
		{ location: 'Jamestown, NY' },
		{ location: 'Jefferson City, MO' },
		{ location: 'Jefferson Hills, PA' },
		{ location: 'Jonesboro, AR' },
		{ location: 'Joplin, MO' },
		{ location: 'Kingston, NY' },
		{ location: 'Kokomo, IN' },
		{ location: 'Lake Charles, LA' },
		{ location: 'Laredo, TX' },
		{ location: 'LaSalle, IL' },
		{ location: 'Latrobe, PA' },
		{ location: 'Lehighton, PA' },
		{ location: 'Lewisville, TX' },
		{ location: 'Lima, OH' },
		{ location: 'Malverne, NY' },
		{ location: 'Mandeville, LA' },
		{ location: 'Manhattan, NY' },
		{ location: 'Manitowoc, WI' },
		{ location: 'Mansfield, OH' },
		{ location: 'Marion, IN' },
		{ location: 'Marion, OH' },
		{ location: 'Martinsville, VA' },
		{ location: 'McGehee, AR' },
		{ location: 'Middletown, OH' },
		{ location: 'Monessen, PA' },
		{ location: 'Monroeville, PA' },
		{ location: 'New Castle, PA' },
		{ location: 'Niagara Falls, NY' },
		{ location: 'Niagara Falls, Ontario' },
		{ location: 'Oak Ridge, TN' },
		{ location: 'Oil City, PA' },
		{ location: 'Olean, NY' },
		{ location: 'Ottumwa, IA' },
		{ location: 'Parkersburg, WV' },
		{ location: 'Petersburg, VA' },
		{ location: 'Pine Bluff, AR' },
		{ location: 'Piqua, OH' },
		{ location: 'Portsmouth, OH' },
		{ location: 'Pottsville, PA' },
		{ location: 'Quincy, IL' },
		{ location: 'Revere, MA' },
		{ location: 'Rocky Mount, NC' },
		{ location: 'Salisbury, NC' },
		{ location: 'Sandusky, OH' },
		{ location: 'Schulenburg, TX' },
		{ location: 'Selma, AL' },
		{ location: 'St. Joseph, MO' },
		{ location: 'Statesville, NC' },
		{ location: 'Steubenville, OH' },
		{ location: 'Sumter, SC' },
		{ location: 'Sunbury, PA' },
		{ location: 'Trenton, MIv' },
		{ location: 'Tupelo, MS' },
		{ location: 'Uniontown, PA' },
		{ location: 'Victoria, TX' },
		{ location: 'Washington, PA' },
		{ location: 'Waterloo, IA' },
		{ location: 'Waycross, GA' },
		{ location: 'Whiteville, NC' },
		{ location: 'Woonsocket, RI' },
		{ location: 'Zanesville, OH' },
	];
	return (
		<div>
			<Helmet>
				<title>Clients : Jewish Community Legacy Project</title>
			</Helmet>
			<Parallax source={PaImg}>
				<h1>CLIENTS</h1>
			</Parallax>
			<div className="container">
				<div className="container content800">
					<br />
					<br />
					<p>
						<b className="black-text">Congregation</b> <b className="JCLP-orange-text">Continuity</b>
					</p>
					<p>
						JCLP has or is currently working directly with Jewish congregations located in the following communities. Please <Link to="/Contact">reach out</Link> to learn more about how JCLP can help best support your congregation.
					</p>
					<br />
					<br />
				</div>
				{/*	
				<div className="row">
					<div className="col s12">
						<div className="square" style={{ position: 'relataive', paddingBottom: '60%' }}>
							<div className="sq-content" style={{ position: 'absolute', width: '100%', height: '100%' }}>
								<iframe style={{ display: 'block', width: '100%', height: '100%', border: 'none' }} src="https://www.google.com/maps/d/u/0/embed?mid=12gdj9VIa4STMGNtgvT_L5cPEJ57VklSi"></iframe>
							</div>
						</div>
					</div>
				</div>
				*/}
				<div className="row">
					{continuity.map((l, i) => {
						return (
							<div className="col s6 m3 l3" key={i} style={{ fontSize: '18px', fontWeight: '300' }}>
								{l.location}
							</div>
						);
					})}
				</div>

				<div className="clearfix"></div>

				<div className="container content800">
					<br />
					<br />
					<p>
						<b className="black-text">Congregation</b> <b className="JCLP-green-text">Connections</b>
					</p>
					<p>
						JCLP is currently managing and facilitating the following network of regional cohorts, made up of small Jewish congregations in close geographic proximity. Please <Link to="/Contact">reach out</Link> to learn more about how JCLP can help best support your congregation.
					</p>
					<br />
					<br />
				</div>

				<div className="row">
					<div className="col s6 m3 l3" style={{ fontSize: '18px', fontWeight: '300' }}>
						<b>Cohort: Central PA</b>
						<br />
						Bloomsburg, PA
						<br />
						Chambersburg, PA
						<br />
						Gettysburg, PA
						<br />
						Hanover, PA
						<br />
						Hazleton, PA
						<br />
						Huntingdon, PA
						<br />
						Lehighton, PA
						<br />
						Lock Haven, PA
						<br />
						Mechanicsburg, PA
						<br />
						Pottsville, PA
						<br />
						Sunbury, PA
						<br />
						Williamsport, PA
						<br />
					</div>
					<div className="col s6 m3 l3" style={{ fontSize: '18px', fontWeight: '300' }}>
						<b>Cohort: Western PA</b>
						<br />
						Ambridge, PA
						<br />
						Bradford, PA
						<br />
						Butler, PA
						<br />
						Carnegie, PA
						<br />
						Du Bois, PA
						<br />
						Eastmont, PA
						<br />
						Erie, PA
						<br />
						Greensburg, PA
						<br />
						Indiana, PA
						<br />
						Johnstown, PA
						<br />
						Washington, PA
						<br />
						White Oak, PA
						<br />
					</div>
					<div className="col s6 m3 l3" style={{ fontSize: '18px', fontWeight: '300' }}>
						<b>Cohort: Greater Mississippi (MS,LA,TN)</b>
						<br />
						Cleveland, MS
						<br />
						Columbus, MS
						<br />
						Greenwood, MS
						<br />
						Greenville, MS
						<br />
						Gulfport, MS
						<br />
						Hattiesburg, MS
						<br />
						Jackson, MS
						<br />
						Meridian, MS
						<br />
						Oxford, MS
						<br />
						Tupelo, MS
						<br />
						Vicksburg, MS
						<br />
						Alexandria, LA
						<br />
						Mandeville, LA
						<br />
						Monroe, LA
						<br />
						Jackson, TN
						<br />
					</div>
					<div className="col s6 m3 l3" style={{ fontSize: '18px', fontWeight: '300' }}>
						<b>Cohort: Ohio</b>
						<br />
						Ashtabula, OH
						<br />
						Athens, OH
						<br />
						Elyria, OH
						<br />
						Hamilton, OH
						<br />
						Lima, OH
						<br />
						Lorain, OH
						<br />
						Mansfield, OH
						<br />
						Marion, OH
						<br />
						Middletown, OH
						<br />
						Piqua, OH
						<br />
						Portsmouth, OH
						<br />
						Sandusky, OH
						<br />
						Springfield, OH
						<br />
					</div>
				</div>
				<br />
				<br />
			</div>
		</div>
	);
};

export default Page;
