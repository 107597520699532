export const createReducer = () => AuthReducer;

export const AuthReducer = (state, action) => {
	switch (action.type) {
		case 'REQUEST_LOGIN':
			return {
				...state,
				loading: true,
			};
		case 'LOGIN_SUCCESS':
			return {
				...state,
				user: action.payload.user,
				token: action.payload.token,
				loading: false,
			};
		case 'LOGOUT':
			return {
				...state,
				user: null,
				token: null,
			};

		case 'LOGIN_ERROR':
			return {
				...state,
				loading: false,
				errors: action.error,
			};
		default:
			return state;
	}
};
