import React from 'react';
import Parallax from '../../components/LazyParallax';
import PaImg from '../../images/donate.jpg';
import PaypalWrapper from './paypal_wrapper';

const Wrapper = () => {
	return (
		<>
			<div>
				<Parallax source={PaImg}>
					<h1>DONATE TO JCLP</h1>
				</Parallax>
				<div className="container row content800">
					<br />
					<br />
					<p className="JCLP-blue-text" style={{ fontSize: '22px', fontWeight: '400' }}>
						Your support for JCLP's mission helps ensure the increased sustainability and growth of small Jewish congregations in today’s communities. Thank you for your part in establishing this legacy.
					</p>
					<div className="clearfix"></div>
					<br />
					<br />
					<h2 className="JCLP-blue-text">Make a Donation</h2>

					<PaypalWrapper />
					{/* <form action="https://www.paypal.com/donate" method="post" target="_top">
						<input type="hidden" name="business" value="3dsanity@gmail.com" />
						<input type="hidden" name="currency_code" value="USD" />
						<input type="hidden" name="amount" value="20" />
						<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
						<img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
					</form> */}
				</div>
			</div>
		</>
	);
};

export default Wrapper;
