import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { CssHero as Parallax } from '../../components/LazyParallax';
import PaImg from '../../images/case-studies.jpg';
import temple_sinai_01 from './images/JCLP-Case-1.jpg';
import temple_sinai_02 from './images/JCLP-Case-2.jpg';
import Image1 from './wall-300x900.jpg';
import Image2 from './wall-300x2500.jpg';
import useGtag from '../../hooks/useGtag';
import { Helmet } from 'react-helmet';

const Page = () => {
	useGtag();

	const [q, setQ] = useState(null);
	const presses = [
		{
			id: 'awwe3',
			title: 'How JCLP can help the members of a thriving lay-led community plan and document their intentions regarding the future of their congregation’s assets.',
			location: (
				<>
					Temple Beth El
					<br />
					Jefferson City, Missouri
				</>
			),
			content: (
				<>
					<p>Little did the German Jewish immigrants who arrived in Jefferson City, the capital of Missouri, in the mid-19th century know what sort of community they would establish in the American Heartland. Today, small and mighty Temple Beth El worships in what is the oldest functioning synagogue building west of the Mississippi.</p>
					<p>The first documented Jewish settler arrived in 1844. Temple Beth El, along with a Jewish cemetery, was founded in 1874. In 1883, the members erected today’s synagogue on a lot that had been purchased with funds raised by the Hebrew Ladies Sewing Society.</p>
					<p>The congregation relies on visiting rabbis and lay leaders. Members and their children over the age of 13 take turns leading Shabbat services. With technology enabling a virtual gathering, even a pandemic could not prevent Friday night services.</p>
					<p>Temple Beth El was affiliated from the very beginning with the Reform movement and what is now the Union for Reform Judaism (URJ). Temple members proceeded through the decades as a proud and exemplary small-town embodiment of the movement’s observances and principles, including active involvement in the civic life of their community.</p>
					<p>A circumstance of “Jewish geography” brought members of the community halfway between St. Louis and Kansas City into conversation with JCLP Senior Vice President Noah Levine. Noah described how working with JCLP could help create a plan to record the intentions of current members regarding the future of the congregation’s assets. </p>
				</>
			),
			solution: (
				<>
					<span className="card-title center-align JCLP-green-text text500">JCLP helped the members develop a long-range plan to preserve the congregation’s assets and history.</span>
					<p>Much discussion, often heated and contentious, followed in the year it took to craft Temple Beth El’s Legacy Plan. Noah listened carefully and advised. His experienced counsel helped overcome what were at times substantive disagreements and was instrumental in bringing everyone to agreement.</p>
					<p>A key subject, and one repeated several times in the actual plan, was that the process was undertaken considering the “unlikely event that the Temple ceases to be viable.” But the plan does make clear the congregation’s intentions should the unlikely occur.</p>
					<p>“Some members were reluctant to engage at the beginning,” Noah said. “Everyone ultimately came on board and the final document was professional and excellent.”</p>
					<p>Creating a positive communal historical legacy and recording the intentions of current members for the future of the congregation’s assets are the key components of the plan.</p>
				</>
			),
			outcome: (
				<>
					<span className="card-title center-align JCLP-green-text text500">Temple Beth El continues to be the center of Jewish life in Jefferson City and has a Legacy Plan that serves as a model for others.</span>
					<p>The congregation has been building on the foundation of the plan and has already completed some major objectives.</p>
					<ul className="discStyle">
						<li>Temple Beth El now has a verified Wikipedia page highlighting its formidable historic status. </li>
						<li>All religious artifacts have been digitized with annotated comments regarding provenance. </li>
						<li>Yahrzeit plaques have been submitted to JewishGen.</li>
						<li>Oral histories have been submitted to the American Jewish Archives. Additionally, documents were added to others already with the State Historical Society of Missouri and to those with the American Jewish Archives.</li>
					</ul>
					<p>
						The poignant story of a refugee family was revealed while congregational minutes were being reviewed for the archive. In 1940,Temple Beth El sponsored the family to come to Jefferson City from Vienna, Austria. Some sleuthing discovered that the family’s small boy went on to become a prominent chemist now living on the East Coast. His recollections of his family’s time in
						Jefferson City, now recorded in an email, brought that history to life.
					</p>
					<p>“Working on the plan also allowed us to put our legal house in order,” said Gail Severance.</p>
					<p>She, along with committee co-chairs Ann and Harvey Tettlebaum and other lay leaders, did the work necessary to complete the legacy plan. In the final document the members of Beth El's Long Term Planning Committee thanked the Jewish Community Legacy Project for encouraging the congregation to undertake this effort and for expert guidance through each stage of the process.</p>
					<p>
						<i>
							The Marcus Foundation has been the primary funder of the Jewish Community Legacy Project since its inception in 2008. With an expanded mission to provide dedicated resources and solutions to small congregations throughout the United States and Canada, JCLP now seeks to ensure its own future. Individuals and organizations interested in supporting this meaningful work can
							contact David Sarnat (david@jclproject.org) or Noah Levine (noah@jclproject.org).{' '}
						</i>
					</p>
				</>
			),
			quote: 'We are a small, but strong and devoted community.',
			by: 'Gail Severance, Temple Beth El president  ',
			link: 'https://www.columbusjewishnews.com/news/local_news/organization-helps-ohio-synagogues-plan-for-future-lasting-legacy/article_f12c1b88-033a-11eb-a000-bf19983b547f.html',
		},
		{
			id: 'awwe2',
			title: 'How can JCLP help perpetuate the life and legacy of a small Jewish congregation facing a precarious decline in membership.',
			location: (
				<>
					Temple Sinai
					<br />
					Sumter, South Carolina
				</>
			),
			content: (
				<>
					<p>
						For Robert Moses, he and Temple Sinai were inextricably linked. A Union for Reform Judaism (URJ) congregation in Sumter, South Carolina, the temple was founded in the 1800s by settlers from Charleston. Robert’s grandfather, Altamont Moses, was among them. Robert’s grandfather became one of the temple’s founders. Robert’s father was the temple president. As Robert tells it,
						he was born of the temple, educated at the temple, married at the temple, and raised his family in the temple.{' '}
					</p>
					<p>Fast forward 150 years. Temple Sinai’s children were leaving the area and not coming back. Membership was declining and the once vibrant religious school, Sisterhood, Men’s Club and youth group were no longer sustainable. </p>
					<p>It was time to think realistically about the congregation’s future. While some felt the congregation should close, a reluctant Robert Moses held out hope for revitalization. Enter the Jewish Community Legacy Project (JCLP). </p>
				</>
			),
			solution: (
				<>
					<span className="card-title center-align JCLP-green-text text500">JCLP developed a Legacy Plan for Temple Sinai, including a future-facing endowment fund and facilitated partnership with a larger, proximate congregation.</span>
					<p>“We listen to issues and make connections with other organizations and institutions that can help extend the life of a congregation while they work to create a legacy that honors the past as it looks to the future,” said JCLP President David Sarnat. “We don’t come with boiler plate solutions for congregations.” </p>
					<p>
						In the case of Temple Sinai, JCLP was able to facilitate a relationship with Kahal Kadosh Beth Elohim (KKBE), a URJ congregation about 100 miles south of Sumter. Today, both congregations benefit from the relationship. A visiting rabbi from KKBE enables the Temple Sinai community to continue to gather in prayer. KKBE has expanded its community to embrace the Jews of Sumter.
						With guidance from JCLP, Temple Sinai has a fiscal management plan in place and KKBE holds an endowment fund that ensures that Temple Sinai and its history will be remembered, and Jewish charities will be funded in the future. “We are now planning for the time when it will be no longer be feasible to operate our temple,” said Temple Sinai member Roger Ackerman (Z”L). “With
						the expertise of the Jewish Community Legacy Project, we have in place an outstanding plan that will perpetuate our congregation longer than might have otherwise been possible.”{' '}
					</p>
				</>
			),
			outcome: (
				<>
					<span className="card-title center-align JCLP-green-text text500">Temple Sinai, including the new Temple Sinai Jewish History Center, is able to continue to serve as the center of Jewish life in Sumter, SC.</span>
					<p>
						In many ways, says Sarnat, the relationship of Temple Sinai to KKBE is a 360- degree story that spans more than 150 years. From Charleston, Altamont Moses brought his Jewish culture to Sumter and now the Sumter Jewish Community returns to its roots. “There are so many larger congregations that can—and want — to help smaller communities,” Sarnat said. “They just needed
						someone to bring them together. That's what we are here to do.”{' '}
					</p>
					<p>
						Another key component of the Sumter community’s Legacy Plan was realized in June 2018 with the opening, in partnership with nearby Sumter County Museum, of the Temple Sinai Jewish History Center. Roger Ackerman had been a stalwart advocate of using the temple’s social hall to house an exhibition telling the story of Sumter’s Jewish history. This walk-through presentation
						includes, among other topics, features on the community’s civic and military involvement; World War II and the Holocaust; and the story of Jewish emigration to South Carolina.
					</p>
					<p>“Temple Sinai’s Jewish History Center is a wonder to behold,” says Dale Rosengarten, founding curator of the Jewish Heritage Collection and Director of the Center for Southern Jewish Culture at the College of Charleston. “It is an inspired re-purposing of synagogue space as an educational facility, developed with extraordinary speed, efficiency, and sense of beauty.”</p>
					<p>Today, with the Temple Sinai Jewish History Center, and Congregation Sinai still able to convene services in a historically-certified sanctuary, Temple Sinai continues to serve as the center for Jewish life in Sumter.</p>
					<p>Thanks to the guidance of JCLP, the legacy of Temple Sinai of Sumter, South Carolina, is secure.</p>
					<p>
						<i>
							The Marcus Foundation has been the primary funder of the Jewish Community Legacy Project since its inception in 2008. With an expanded mission to provide dedicated resources and solutions to small congregations throughout the United States and Canada, JCLP now seeks to ensure its own future. Individuals and organizations interested in supporting this meaningful work can
							contact David Sarnat (david@jclproject.org) or Noah Levine (noah@jclproject.org).{' '}
						</i>
					</p>
				</>
			),
			quote: 'The Temple is an inherent cornerstone of my life.',
			by: 'Robert Moses, Temple Sinai lay leader ',
			link: 'https://www.columbusjewishnews.com/news/local_news/organization-helps-ohio-synagogues-plan-for-future-lasting-legacy/article_f12c1b88-033a-11eb-a000-bf19983b547f.html',
		},
		{
			id: 'awwe1',
			title: 'How can JCLP help a small congregation, when necessary, find new homes for historic ritual objects. ',
			location: (
				<>
					B’nai Israel Congregation
					<br />
					Auburn, New York
				</>
			),
			content: (
				<>
					<p>
						With the Jewish population dwindling and the members of the congregation moving away from the community, the leaders of B’nai Israel Congregation (BIC) in Auburn, NY made the difficult decision to close its doors. On hand to assist in this process was the Jewish Community Legacy Project (JCLP) and its partner organizations, the Union for Reform Judaism (URJ), United
						Synagogue of Conservative Judaism (USCJ) and Jewish Federations of North America (JFNA). Developing a meaningful plan for the future legacy of the congregation included giving new life to community assets: religious and historic artifacts.
					</p>
				</>
			),
			solution: (
				<>
					<span className="card-title center-align JCLP-green-text text500">JCLP and partners identified organizations eager to accept Torahs and arranged for transport.</span>
					<p>For more than 100 years, generations of B’nai Israel Congregation members read from three Torah scrolls that now needed new homes. This USCJ-affiliated congregation chose to gift its Torah scrolls to a Reform synagogue in Davidson, NC, to the URJ’s Eisendrath International Exchange Semester in Israel program and to the URJ’s newest camp, 6 Points Sci-Tech Academy. </p>
					<p>Two scrolls needed to be transported from Auburn to the Union for Reform Judaism headquarters in New York City and then to their new homes in Boston, MA and Israel respectively. A five-city, month-long Torah transport relay was orchestrated to move the sacred scrolls. Along the way, the journey became a message of hope and faith that could not have been anticipated. </p>
				</>
			),
			outcome: (
				<>
					<span className="card-title center-align JCLP-green-text text500">The legacy of B’nai Israel imbued in each sacred scroll resonates today and even beyond the Jewish community.</span>
					<p>
						The first leg of the relay was carried out by a non-Jewish couple who happened to be heading in the direction that the scrolls needed to go. When they went to B’nai Israel to pick up the scrolls they were treated to a history lesson that they say they will never forget. A long-time member of BIC opened the scrolls, shared the history of the congregation and the meaning of
						the Torah scrolls and other ritual objects.
					</p>
					<p>“Thank you for letting us know where the journey will take these Torah scrolls,” wrote Peg Beck about her experience. “They carry the weight of centuries of faith, and the lives of the people who have read them and believed. We will carry with us now the memory of our small participation in that journey... We feel truly blessed.”</p>
					<p>These Torah Scrolls are more than ritual objects, they are the legacy of a Jewish community and the hope for another. </p>
					<p>“As a nascent camp community, we are at the beginning of our journey to bring Jewish life, love and identity to a new set of Jewish children. Our dream to open 6 Points Sci- Tech is on its way to becoming a reality and now we can build it around Torah and its values.” — Abigail Fisher </p>
					<p>
						<i>
							The Marcus Foundation has been the primary funder of the Jewish Community Legacy Project since its inception in 2008. With an expanded mission to provide dedicated resources and solutions to small congregations throughout the United States and Canada, JCLP now seeks to ensure its own future. Individuals and organizations interested in supporting this meaningful work can
							contact David Sarnat (david@jclproject.org) or Noah Levine (noah@jclproject.org).{' '}
						</i>
					</p>
				</>
			),
			quote: '“As we stood looking at the Torah that our camp was being given, I thought of all the stories it could tell —not just the ones contained in its text, but the stories of generations of Jews who have chanted its words, learned its teachings, and danced with it on Simchat Torah. How many Kol Nidre chants has it stood silently witnessing? All that—and it is being entrusted to us.” ',
			by: 'Abigail Fisher, URJ 6 Points Sci-Tech Academy Advisor Council Chair ',
			link: 'https://www.columbusjewishnews.com/news/local_news/organization-helps-ohio-synagogues-plan-for-future-lasting-legacy/article_f12c1b88-033a-11eb-a000-bf19983b547f.html',
		},
		{
			id: 'awwe0',
			title: 'How can JCLP help perpetuate the life, and legacy, of a small congregation facing a precarious decline in membership.',
			location: (
				<>
					Temple Beth Israel
					<br />
					Steubenville, Ohio
				</>
			),
			content: (
				<>
					<p>
						For many years, Steubenville, Ohio was a thriving center of the steel industry. Within this city, a robust Jewish community flourished as well. And within that community, Temple Beth Israel was the center of Jewish religious, cultural and social life. “They started with steel mills and manufacturing and then the Jewish people came to supplement, with clothing stores and
						grocery stores, the needs of the people,” said community member Beverly Greenburg.
					</p>
					<p>When the steel mills began to close in the 1980s, the city felt its brunt. Steubenville decreased from a city of 40,000 to one of only 18,000 today. “There was no reason for our children to return to the community’” said Dottie Silberman. ”And none did. High holidays this year, we peaked at maybe 20 people.” </p>
					<p>So, facing a bleak economy, and an aging congregation, Temple Beth Israel turned to the Jewish Community Legacy Project (JCLP) for assistance in determining how to manage changing demographics while still sustaining a Jewish presence. </p>
				</>
			),
			solution: (
				<>
					<span className="card-title center-align JCLP-green-text text500">JCLP developed a Legacy Plan for Temple Beth Israel to address multiple needs.</span>
					<p>“Even though a community is declining, they’re not giving up on Jewish life,” said JCLP president David Sarnat. JCLP helped Temple Beth Israel make plans for transition and helped turn what could have been a sorrowful circumstance into a blessing. </p>
					<p>The plan addressed many areas of need, including:</p>
					<ul className="discStyle">
						<li>the administration of the sale of the temple building</li>
						<li>future placement and housing of significant ritual objects</li>
						<li>opportunities for continued worship</li>
						<li>how to best direct financial assets and proceeds</li>
					</ul>
					<p>When Temple Beth Israel’s congregants gathered one more time for a final Yom Kippur service in their revered temple, they did it with the comfort and knowledge that a sustainable plan was in place to preserve the legacy of their congregation. </p>
				</>
			),
			outcome: (
				<>
					<span className="card-title center-align JCLP-green-text text500">JCLP partners secured the legacy of Temple Beth Israel.</span>
					<p>A Union for Reform Judaism (URJ)-affiliated congregation, Temple Beth Israel was given historic status by URJ so that it remains a part of the Reform Movement in perpetuity.</p>
					<p>
						After the congregation’s final Yom Kippur service, the temple building was sold. Importantly, Beth Israel’s new physical home is now 30 miles from Steubenville in Pittsburgh at Beth El Congregation, which is affiliated with United Synagogue of Conservative Judaism (USJC). JCLP helped facilitate this partnership. The sacred space of Beth El offers not only a place of
						worship, but also a special room to house Beth Israel’s cherished artifacts and ritual objects. JCLP also eased the donation to nearby URJ Rodef Shalom Congregation of a series of bronze sculpted figures titled Procession by noted American-Jewish sculptor Elbert Weinberg.
					</p>
					<p>An additional part of the Beth Israel legacy will be a much-needed senior community center that will stand where the Temple once stood. With the proceeds from the sale, Beth Israel is also working with the Jewish Federation of Greater Pittsburgh to be part of the Fund for the Future, which will endow programs to further the Jewish identity of area youth.</p>
					<p>“Congregations can leave a legacy that continues the work they were doing,” said Sarnat. “Even if a community at some point ceases to function in a traditional sense, whatever assets they have can continue to be of service and enrich Jewish life.“ </p>
					<p>
						<i>
							The Marcus Foundation has been the primary funder of the Jewish Community Legacy Project since its inception in 2008. With an expanded mission to provide dedicated resources and solutions to small congregations throughout the United States and Canada, JCLP now seeks to ensure its own future. Individuals and organizations interested in supporting this meaningful work can
							contact David Sarnat (david@jclproject.org) or Noah Levine (noah@jclproject.org).{' '}
						</i>
					</p>
				</>
			),
			quote: 'We were in a unique position in Steubenville in that we ran out of people before we ran out of money.',
			by: 'Dottie Silberman, Steubenville, OH ',
			link: 'https://www.columbusjewishnews.com/news/local_news/organization-helps-ohio-synagogues-plan-for-future-lasting-legacy/article_f12c1b88-033a-11eb-a000-bf19983b547f.html',
		},
	];

	return (
		<div>
			<Helmet>
				<title>Case Studies : Jewish Community Legacy Project</title>
			</Helmet>
			<Parallax source={[Image1, Image2]}>
				<h1>CASE STUDIES</h1>
			</Parallax>

			<div className="container row">
				<br />
				<br />
				<div className="col s12 m8 offset-m2 l6 offset-l3" style={{ position: 'relative' }}>
					<input type="text" className="rightTagInput" placeholder="Search any key word(s)" name="bla" id="ss" onChange={(e) => setQ(e.target.value)} />
					<button className="inputTag goldBtn">
						<i className="fas fa-search"></i>
					</button>
				</div>
				<div className="clearfix"></div>
				<br />
				{/* <img src={interHome} /> */}
				{presses
					.filter((p) => {
						if (!q) return true;

						if (
							p.title.toLowerCase().search(q.toLowerCase()) >= 0 ||
							ReactDOMServer.renderToString(p.content).toLowerCase().search(q.toLowerCase()) >= 0 ||
							ReactDOMServer.renderToString(p.location).toLowerCase().search(q.toLowerCase()) >= 0 ||
							ReactDOMServer.renderToString(p.outcome).toLowerCase().search(q.toLowerCase()) >= 0 ||
							ReactDOMServer.renderToString(p.solution).toLowerCase().search(q.toLowerCase()) >= 0
						)
							return true;
						return false;
					})
					.map((p, i) => (
						<div className="col s12 m8 offset-m2" key={i}>
							<div className="card white">
								<div className="card-content">
									<Case study={p} />

									{/* <a className="pressReadMore" href={p.link} target="_blank">
										Read More
									</a> */}
								</div>
							</div>
						</div>
					))}
				<div className="clearfix"></div>
				<br />
				<br />
			</div>
		</div>
	);
};

const Case = ({ study }) => {
	const [active, setActive] = useState('challenge');

	return (
		<>
			<h2 className="JCLP-blue-text center-align" style={{ margin: '0' }}>
				{study.location}
			</h2>
			<br />
			<div className="center-align" style={{ margin: '0', fontSize: '16px' }}>
				<i>{study.quote}</i>
				<br />
				--{study.by}
			</div>
			<br />
			<div className="center-align">
				<button onClick={() => setActive('challenge')} style={{ margin: '0 5px' }} className="btn btn-small" type="button">
					Challenge
				</button>
				<button onClick={() => setActive('solution')} style={{ margin: '0 5px' }} className="btn btn-small" type="button">
					Solution
				</button>
				<button onClick={() => setActive('outcome')} style={{ margin: '0 5px' }} className="btn btn-small" type="button">
					Outcome
				</button>
			</div>
			<br />
			{active === 'challenge' && (
				<>
					<h5 className="center-align JCLP-blue-text">CHALLENGE</h5>
					<span className="card-title center-align JCLP-green-text text500">{study.title}</span>
					<div className="">{study.content}</div>
				</>
			)}
			{active === 'solution' && (
				<>
					<h5 className="center-align JCLP-blue-text">SOLUTION</h5>
					<div style={{ fontSize: '17px' }}>{study.solution}</div>
				</>
			)}
			{active === 'outcome' && (
				<>
					<h5 className="center-align JCLP-blue-text">OUTCOME</h5>
					<div style={{ fontSize: '17px' }}>{study.outcome}</div>
				</>
			)}
		</>
	);
};

export default Page;
