import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/auth-provider';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

export default () => {
	// const { handleSubmit, register, setError, errors } = useForm();
	const { handleSubmit, register, errors } = useForm();
	const [postErrors, setPostErrors] = useState('');
	const { login, ...rest } = useContext(AuthContext);
	// const [password, setPassword] = useState("");
	const history = useHistory();
	const location = useLocation();
	const params = useParams();

	const lerrors = rest.errors;

	const onSubmit = (values) => {
		const { username, password } = values;

		// const { from } = this.auth.location.state || {
		// 	from: { pathname: '/authenticated' },
		// };
		return login({ username, password }).then((response) => {
			console.log({ response });
			if (!response) {
				return false;
			}

			if (response && 'user' in response && 'token' in response) {
				try {
					history.push(location.state.redir && location.state.redir);
				} catch (e) {
					history.push('/forum');
				}
			}
		});
	};

	return (
		<form name="login-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="form-holder">
				<div className="center-align helper-text red-text">{lerrors && lerrors.length > 0 && lerrors}</div>
				<div className="input-field col s6">
					<input
						type="text"
						ref={register({
							required: 'Username is required!',
							// pattern: {
							//    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
							//    message: 'Invalid Email Address',
							// },
						})}
						className="input"
						name="username"
						id="username"
						placeholder="email@domain.com"
					/>
					<span className="helper-text red-text">{errors.username && errors.username.message}</span>
				</div>
				<div className="input-field col s6">
					<input type="password" ref={register({ required: 'Password is required!' })} className="input" name="password" id="password" placeholder="Password" />
					<span className="helper-text red-text">{errors.password && errors.password.message}</span>
				</div>
			</div>
			<button className="btn submit-btn">Log in</button>
		</form>
	);
};
