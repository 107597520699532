import React from 'react';
import Parallax from '../../components/LazyParallax';
import PaImg from '../../images/donate.jpg';
import PaypalWrapper from './paypal_wrapper';

const Wrapper = () => {
	return (
		<>
			<div>
				<Parallax source={PaImg}>
					<h1>DONATE TO JCLP</h1>
				</Parallax>
				<div className="container row content800">
					<br />
					<br />
					<div className="col s12 m8 offset-m2">
						<p className="JCLP-blue-text" style={{ fontSize: '22px', fontWeight: '400' }}>
							Your support for JCLP's mission helps ensure the increased sustainability and growth of small Jewish congregations in today’s communities. Thank you for your part in establishing this legacy.
						</p>
						<div className="clearfix"></div>
						<br />
						<br />
						<h2 className="JCLP-blue-text">Thank you for your donation</h2>

						{/* <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px">Dear {{ name }}</p> */}
						<p>On behalf of the Board of Directors of the Jewish Community Legacy Project (JCLP), I thank you for your meaningful donation.</p>
						<p>JCLP is the only organization exclusively providing dedicated resources and solutions for Jewish congregations in small communities in the United States and Canada to navigate current challenges, prepare responsibly for the future, and ensure a lasting community legacy.</p>
						<p>Your generous support allows us to help these communities effectively plan and sustain Jewish culture, and identity for as long as possible. Additionally, our guidance of congregational leadership in the creation of a Legacy Plan lays the groundwork for investment in future generations and a historic footprint.</p>
						<p>This correspondence acknowledges your contribution and that no service or goods were provided to you as a result of your gift.</p>
						<p>Sincerely,</p>
						<p>
							David I. Sarnat
							<br />
							President
							<br />
							Jewish Community Legacy Project
						</p>
						<br />
						<br />
					</div>

					{/* <form action="https://www.paypal.com/donate" method="post" target="_top">
						<input type="hidden" name="business" value="3dsanity@gmail.com" />
						<input type="hidden" name="currency_code" value="USD" />
						<input type="hidden" name="amount" value="20" />
						<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
						<img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
					</form> */}
				</div>
			</div>
		</>
	);
};

export default Wrapper;
