import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { categories as mock_categories } from './mock';
import Parallax from '../../components/LazyParallax';
import { ForumContext } from '../../context/forum-context';
import { AuthContext } from '../../context/auth-provider';
import { NewPost, NewTopic } from './create';
import M from 'materialize-css';
import moment from 'moment';
import md5 from 'crypto-js/md5';
import Image from '../../images/forum.jpg';
import KevinPage from './kevin';

import AuthorPic from '../../images/male-placeholder.png';
import { API_URL } from '../../config';

const slugify = (string) => {
	const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
	const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
	const p = new RegExp(a.split('').join('|'), 'g');

	return string
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
		.replace(/&/g, '-and-') // Replace & with 'and'
		.replace(/[^\w\-]+/g, '') // Remove all non-word characters
		.replace(/\-\-+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, ''); // Trim - from end of text
};

const hasAccess = (cat, user) => (user ? (cat === -1 ? true : user.access.indexOf(cat) > -1) : false);

export const ForumParallax = () => {
	const { categories, category_focus, setCategory_Focus } = useContext(ForumContext);

	return (
		// <Parallax source="/assets/images/JCLP2-1440.jpg">
		<Parallax source={Image}>
			<h1>
				Forum
				<br />
				<span className="subTittle">Answers for Jewish congregations</span>
			</h1>
		</Parallax>
	);
};

const DD = ({ serviceOptions, onClick }) => {
	useEffect(() => {
		let elems = document.querySelectorAll('.dropdown-trigger');
		if (elems && elems.length > 0) M.Dropdown.init(elems, {});
	}, []);

	const [label, setLabel] = React.useState('Please select forum cohort');

	const onSelect = (d) => {
		onClick(d.id);
		setLabel(d.title);
	};

	return (
		<>
			{/* <span id="dd_trigger" style={{ width: '400px', maxWidth: '100%', ...theme }} className="dropdown-trigger btn" data-target="dropdown_sel">
				{label}
			</span> */}
			<a className="dropdown-trigger btn" href="#" data-target="dropdown_sel" style={{ height: '48px', lineHeight: '48px' }}>
				{label} <i className="fas fa-caret-down"></i>
			</a>
			<ul id="dropdown_sel" className="dropdown-content">
				{serviceOptions.map((el, i) => (
					<li className="valign-wrapper" onClick={() => onSelect(el)} key={i}>
						<span className="center-align">{el.title}</span>
					</li>
				))}
			</ul>
		</>
	);
};

const ForumNav = ({ noBack }) => {
	const { categories, category_focus, setCategory_Focus } = useContext(ForumContext);
	const { user } = useContext(AuthContext);
	const history = useHistory();

	// const dd = categories.filter((c) => c.parentid === 0 && c.id != -1 && user.access.indexOf(c.id) > -1);
	const dd = categories.filter((c) => c.parentid === 0 && c.id != -1);

	return (
		<div className="row forum-nav">
			<br />
			<div className="container">
				<div className="col s12 m6 l6 valign-wrapper" style={{ position: 'relative' }}>
					{history.location.pathname.toLowerCase() !== '/forum' && (
						<>
							<button style={{ minWidth: 'auto', marginRight: '10px' }} className="btn" onClick={() => history.goBack()}>
								<i style={{ marginLeft: '10px' }} className="left material-icons">
									chevron_left
								</i>
							</button>
							<Link to="/Forum" className="left btn">
								<i class="material-icons">home</i>
							</Link>
						</>
					)}
					{/* {!noBack && } */}
					{/* {noBack && dd && dd.length > 0 && <DD onClick={setCategory_Focus} serviceOptions={dd} />} */}
				</div>
				{user && (
					<div className="col s12 m5 l4" style={{ display: 'flex', position: 'relative' }}>
						<Link to="/forum/new" style={{ minWidth: 'auto', marginRight: '10px' }} className="btn">
							Start New Topic
						</Link>
					</div>
				)}
				{/* <div className="col s12 m5 l4" style={{ display: 'flex', position: 'relative' }}>
					<input type="text" className="rightTagInput" placeholder="Search any key word(s)" name="bla" id="ss" />
					<button className="inputTag goldBtn">
						<i className="fas fa-search"></i>
					</button>
				</div> */}
				<div className="col s12 m1 l2 right-align">
					<Link to="/forum">
						<div style={{ margin: 'auto', width: '50px' }}>
							<ProfileImage image={user && user.profile_image && user.profile_image} />
						</div>
						{/* <img src={user.profile_image && user.profile_image.length > 0 ? user.profile_image : AuthorPic} className="forumEntryAuthorPic" /> */}
					</Link>
				</div>
			</div>
		</div>
	);
};

export const Forum = () => {
	return (
		<>
			<ForumNav noBack />

			<MainForumSection />
		</>
	);
};

const CategoryBox = () => {
	const { fetchPopularTopics, categories, category_focus, setCategory_Focus, ...rest } = useContext(ForumContext);

	const [topics, setTopics] = useState([]);

	useEffect(() => {
		fetchPopularTopics().then((d) => setTopics(d));
	}, []);

	return (
		<div className="col s12 m4 l4">
			<h2 className="JCLP-blue-text">Popular Topics</h2>
			<div className="card white">
				<div className="catEntry card-content">
					<div className="collection" style={{ border: 'none' }}>
						{topics &&
							topics.map((c, i1) => (
								<div key={i1}>
									<Link to={`/forum/t/${slugify(`${c.title}-${c.id}`)}`} className="catTitle collection-item">
										{c.title} <span className="badge">{c.count}</span>
									</Link>
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

const CategoryItem = ({ id, title, description, count }) => {
	return (
		<Link to={`/forum/${slugify(`${title}-${id}`)}`} style={{ display: 'block', marginBottom: '40px' }}>
			<div className="forumEntryBox">
				<div className="forumEntryAuthorInfo">
					<div className="forumEntryStatus">
						<div>
							<i className="fas fa-comment"></i> {count}
							{/* <i className="fas fa-eye" style={{ marginLeft: '15px' }}></i>100 */}
						</div>
					</div>
				</div>
				<div className="card-content">
					<div style={{ marginTop: '25px' }}>
						<p className="forumTopicTitle JCLP-blue-text">{title}</p>
						<p className="forumTopicException">{description}</p>
					</div>
				</div>
			</div>
		</Link>
	);
};

export const Category = ({ match }) => {
	//first load categories
	// const categories = mock_categories;
	const { topics, categories, category_focus, setCategory_Focus, getTopics } = useContext(ForumContext);
	const { category } = match.params;

	useEffect(() => {
		if (categories && categories.length > 0) {
			const loadTopics = () => getTopics(10);
			loadTopics();
		}
	}, [categories, category]);

	return (
		<>
			<ForumNav />
			<div className="container">
				<div className="row">
					<div className="col s12 m8 l8">
						<h2 className="JCLP-blue-text">General Topics</h2>
						<div className="forumTopicList card white">
							<div className="forumEntry card-content">{topics && topics.map((c, i1) => <TopicItem key={i1} {...c} />)}</div>
						</div>
					</div>
					<CategoryBox />
				</div>
			</div>
			{/* <div className="container">
				<div className="row">
					<div className="col s12 m8 l8"></div>update
					<CategoryBox />
				</div>
			</div> */}
		</>
	);
};

const TopicItem = ({ id, title, timestamp, firstname, lastname, profile_image, email, count }) => {
	return (
		<Link to={`/forum/t/${slugify(title + '-' + id)}`} style={{ display: 'block', marginBottom: '40px' }}>
			<div className="forumEntryBox">
				<div className="forumEntryAuthorInfo">
					<div style={{ width: '50px' }}>
						<ProfileImage image={profile_image.length && profile_image} email={email} />
					</div>

					<div className="forumEntryStatus">
						<div>
							<i className="fas fa-comment"></i> {count} <i className="fas fa-calendar-day" style={{ marginLeft: '15px' }}></i> {moment(timestamp).format('MMM D, YYYY')}
						</div>
					</div>
				</div>
				<div className="card-content">
					<p className="small forumTopicAuthor">
						By{' '}
						<span>
							{firstname} {lastname}
						</span>
					</p>
					<div>
						<p className="forumTopicTitle JCLP-blue-text">{title}</p>
						{/* <p className="forumTopicException">{description}</p> */}
					</div>
				</div>
			</div>
		</Link>
	);
};

export const Topic = ({ match }) => {
	//first load categories
	// const categories = mock_categories;
	const { posts, topics, categories, category_focus, setCategory_Focus, getPosts, getTopic } = useContext(ForumContext);
	const { user } = useContext(AuthContext);

	const createRef = useRef(null);
	const [topic, setTopic] = useState(null);

	useEffect(() => {
		const arr = match.params.topic.split('-');
		const i = arr[arr.length - 1];

		getTopic(i).then((d) => {
			setTopic(d);
			getPosts(d.id);
		});

		// getTopics().then((d) => {
		// 	const cid = topics.reduce((tot, i) => {
		// 		if (slugify(`${i.title}-${i.id}`) === match.params.topic) setTopic(i);
		// 		return slugify(`${i.title}-${i.id}`) === match.params.topic ? i.id : tot;
		// 	}, 0);
		// 	const loadPosts = () => getPosts(cid);
		// 	loadPosts();
		// });
	}, [match.params.topic]);

	const currentCategory = categories.filter((e) => e.id === Number(match.params.topic.split('-').slice(-1).pop()))[0];

	const executeScroll = () => createRef && createRef.current.scrollIntoView();

	const catpid = (i) => categories.reduce((tot, el) => (el.id === i ? el.parentid : tot), 0);

	return (
		<>
			<ForumNav />
			<div className="container">
				<div className="row">
					<div className="col s12 m8 l8">
						<h2 className="JCLP-blue-text">{topic && topic.title}</h2>
						<div className="forumTopicList card white">
							<div className="forumEntry card-content">{posts && posts.filter((c) => (user && user.permission > 0) || c.pending === 0 || (c.pending === 1 && user && user.id === c.author)).map((c, i1) => <PostItem hasaccess={hasAccess(topic ? catpid(topic.categoryid) : 0, user)} key={i1} {...c} executeScroll={executeScroll} />)}</div>
						</div>

						<div ref={createRef}>{user && topic && hasAccess(topic ? catpid(topic.categoryid) : 0, user) && <NewPost topic={topic} />}</div>
					</div>
					<CategoryBox />
				</div>
			</div>
		</>
	);
};

export const PostItem = ({ id, title, quoted, message, timestamp, firstname, lastname, profile_image, likecount, dislikecount, count, pending, preview, executeScroll, email, hasaccess = false, ...rest }) => {
	const { setQuoted, approvePost, deletePost, likePost, dislikePost } = useContext(ForumContext);
	const { user } = useContext(AuthContext);
	const history = useHistory();
	const location = useLocation();

	const store_like = localStorage.getItem('can_like') ? JSON.parse(localStorage.getItem('can_like')) : [];

	const initCanI = store_like.findIndex((e) => e.id === id);
	const initCan = initCanI > -1 ? store_like[initCanI].can : true;

	const [[likes, dislikes, can], setLikes] = useState([likecount, dislikecount, initCan]);

	const onQuoteClick = () => {
		if (user) {
			setQuoted({ id, title, message, firstname, lastname });
			executeScroll();
		} else {
			history.push({
				pathname: '/login',
				state: {
					redir: location.pathname,
					detail: location.pathname,
				},
			});
		}
	};

	const handleLikePost = () => {
		setLikes([likes + 1, dislikes, false]);
		likePost(id, user).then((d) => {
			!d.success && setLikes([likes - 1, dislikes, true]);
		});
	};

	const handleDislikePost = () => {
		setLikes([likes, dislikes + 1, false]);
		dislikePost(id, user).then((d) => !d.success && setLikes([likes, dislikes - 1, true]));
	};

	useEffect(() => {
		let current = localStorage.getItem('can_like') ? JSON.parse(localStorage.getItem('can_like')) : [];
		const index = current.findIndex((e) => e.id === id);
		const item = { id, can };

		if (index > -1) {
			current[index] = item;
		} else {
			current = current.concat([item]);
		}

		localStorage.setItem('can_like', JSON.stringify(current));
	}, [can]);

	return (
		<div className="forumEntryBox">
			<div className="forumEntryAuthorInfo">
				<div style={{ width: '50px' }}>
					<ProfileImage image={profile_image} email={email} />
				</div>

				<div className="forumEntryStatus">
					<div>
						<i className="fas fa-calendar-day" style={{ marginLeft: '15px' }}></i> {moment(timestamp).format('MMM D, YYYY')}
					</div>
				</div>
			</div>
			<div className="card-content">
				<p className="small forumTopicAuthor">
					By{' '}
					<span>
						{firstname} {lastname}
					</span>
				</p>
				<div>
					{quoted && 'id' in quoted && quoted.id && (
						<div className="quoted">
							<div className="quoted-from">
								<b>
									{quoted.firstname} {quoted.lastname}
								</b>{' '}
								wrote:
							</div>
							<div dangerouslySetInnerHTML={{ __html: quoted.message }} />
						</div>
					)}
					<div className="forumTopicException" dangerouslySetInnerHTML={{ __html: message }} />
				</div>
			</div>
			<div className="card-action">
				<div className="forumEntryActionInfo">
					{!preview && hasaccess && (
						<>
							{pending === 1 && user && user.permission === 0 && <span style={{ marginRight: 'auto' }}>PENDING</span>}
							{user && user.permission > 0 && hasaccess && (
								<div style={{ marginRight: 'auto' }}>
									<button onClick={() => deletePost(id, user)} className="forumEntryStatus red white-text">
										<div className="valign-wrapper">
											<i className="material-icons">delete</i>
										</div>
									</button>
									{pending === 1 && user && user.permission > 0 && (
										<button onClick={() => approvePost(id, user)} className="forumEntryStatus green white-text">
											<div className="valign-wrapper">
												<i className="material-icons">check</i>
											</div>
										</button>
									)}
								</div>
							)}
							<button disabled={!can && 'disabled'} onClick={handleLikePost} className="forumEntryStatus" style={{ marginLeft: 'auto' }}>
								<div>
									<i className="fas fa-thumbs-up green-text"></i> {likes}
								</div>
							</button>
							<button disabled={!can && 'disabled'} onClick={handleDislikePost} className="forumEntryStatus">
								<div>
									<i className="fas fa-thumbs-down red-text"></i> {dislikes}
								</div>
							</button>
							<button onClick={onQuoteClick} className="forumEntryStatus">
								QUOTE
							</button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export const CreateTopic = () => {
	const { topics, categories, getTopics } = useContext(ForumContext);
	// const { category } = match.params;

	useEffect(() => {
		if (categories && categories.length > 0) {
			const loadTopics = () => getTopics(10);
			loadTopics();
		}
	}, [categories]);
	return (
		<>
			<ForumNav noBack />
			<div className="container">
				<div className="row">
					<div className="s12">
						<NewTopic />
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col s12 m8 l8">
						<h2 className="JCLP-blue-text">Category</h2>
						<div className="forumTopicList card white">
							<div className="forumEntry card-content">{topics && topics.map((c, i1) => <TopicItem key={i1} {...c} />)}</div>
						</div>
					</div>
					<CategoryBox />
				</div>
			</div>
			{/* <MainForumSection /> */}
		</>
	);
};

const MainForumSection = () => {
	const { categories, category_focus, setCategory_Focus } = useContext(ForumContext);
	return (
		<div className="container">
			<div className="row">
				<div className="col s12 m8 l8">
					{categories &&
						categories
							.filter((e) => e.id === -1)
							.map((c, i1) => (
								<div key={i1}>
									<h2 className="JCLP-blue-text">{c.title}</h2>
									<div className="forumTopicList card white">
										<div className="forumEntry card-content">
											{categories
												.filter((e) => e.parentid === c.id)
												.map((cc, i2) => (
													<CategoryItem key={i2} {...cc} />
												))}
										</div>
									</div>
								</div>
							))}
					{categories &&
						categories
							.filter((e) => e.parentid === 0 && e.id === category_focus)
							.map((c, i1) => (
								<div key={i1}>
									<h2 className="JCLP-blue-text">{c.title}</h2>
									<div className="forumTopicList card white">
										<div className="forumEntry card-content">{categories && categories.filter((e) => e.parentid === c.id).map((cc, i2) => <CategoryItem key={i2} {...cc} />)}</div>
									</div>
								</div>
							))}
				</div>
				<CategoryBox />
			</div>
		</div>
	);
};

const ProfileImage = ({ image, email = '' }) => {
	const { user } = useContext(AuthContext);

	const e = email.length > 0 ? email : user && user.email && user.email.length > 0 ? user.email : '';

	const showImage = image && image.length > 0 ? image : e.length > 0 ? `https://www.gravatar.com/avatar/${md5(e)}` : AuthorPic;

	return (
		<div className="square forumEntryAuthorPic">
			<div className="sq-content valign-wrapper circle">
				{/* {image && image.length < 0} */}
				<img src={showImage} className="responsive-img" style={{ minHeight: '100%', minWidth: '100%' }} />
			</div>
		</div>
	);
};

export default Forum;
