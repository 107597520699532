import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { API_URL } from '../../config';
import { CssHero as Parallax } from '../../components/LazyParallax';
// import Image from '../../images/press.jpg';
import Image1 from './brass-300x900.jpg';
import Image2 from './brass-300x2500.jpg';
// imported css for interactive pressPage
import styles from './pressPage.module.css';
import useGtag from '../../hooks/useGtag';
import { Helmet } from 'react-helmet';

const Page = ({ data }) => {
	useGtag();

	const [q, setQ] = useState(null);
	const presses = [
		{ id: 'awwe3', title: 'Columbus Jewish News', content: 'Organization helps Ohio synagogues plan for the future', date: 'October 29, 2020', link: 'https://www.columbusjewishnews.com/news/local_news/organization-helps-ohio-synagogues-plan-for-future-lasting-legacy/article_f12c1b88-033a-11eb-a000-bf19983b547f.html' },
		{ id: 'awwe3', title: 'New Castle News', content: 'JCLP Legacy community continues to give back', date: 'October 27, 2020', link: 'https://www.ncnewsonline.com/news/local_news/former-local-synagogue-continues-to-give-back/article_9162e3ef-f67b-5164-9956-e494a12e6f6b.html' },
		{ id: 'awwe3', title: 'Atlanta Jewish Times', content: 'Small Congregations Get Creative for Holidays', date: 'August 5, 2020', link: 'https://atlantajewishtimes.timesofisrael.com/small-congregations-get-creative-for-holidays/' },
		{ id: 'awwe3', title: 'New Castle News', content: 'Legacy of Temple Hadar Israel endures', date: 'December 10, 2019', link: 'https://www.ncnewsonline.com/news/local_news/defunct-synagogue-giving-back/article_aa43181e-34a2-5767-a2ab-a76f7c8da43c.html' },
		{ id: 'awwe3', title: 'St. Louis Public Radio', content: 'JCLP helped Quincy, Illinois, congregation plan for the future"This Illinois Synagogue Can Be Yours For $150,000', date: 'October 18, 2019', link: 'https://news.stlpublicradio.org/arts/2019-10-18/this-illinois-synagogue-can-be-yours-for-150-000#stream/0' },
		{ id: 'awwe3', title: 'TribLive', content: 'Temple Beth Am Acts Responsibly with Guidance from JCLP', date: 'September 5, 2019', link: 'https://triblive.com/local/westmoreland/monessens-temple-beth-am-closing-estate-sale-friday/' },
		{ id: 'awwe3', title: 'Atlanta Jewish Times', content: 'Temple Donates Aron Kodesh to Orthodox Synagogue', date: 'August 21, 2019', link: 'https://atlantajewishtimes.timesofisrael.com/temple-donates-aron-kodesh-to-orthodox-synagogue/' },
		{ id: 'awwe3', title: 'Illinois Public Media News', content: 'Quincy Synagogue Turns Off the Lights', date: 'May 22, 2019', link: 'https://will.illinois.edu/news/story/quincy-synagogue-turns-off-the-lights-for-the-last-time' },
		{ id: 'awwe3', title: 'Boston Globe', content: 'Last Synagogue in Revere, Massachusetts, prepares to close its doors', date: 'May 21, 2019', link: 'https://www.bostonglobe.com/metro/2019/05/21/last-synagogue-revere-prepares-close-its-doors/FiBZ2S1HLysOWmUzBZT1aN/story.html' },
		{ id: 'awwe3', title: 'St. Louis Jewish Light', content: 'Quincy Temple to Close', date: 'April 25, 2019', link: 'https://www.stljewishlight.com/news/news_schmooze/quincy-temple-to-close-strollathon-for-rett-syndrome-crc-plans/article_80cde116-6794-11e9-bfd5-17c32fc31d99.html' },
		{ id: 'awwe3', title: 'Atlanta Jewish Times', content: 'Camp was a touchstone to small-town Mississippi Jews', date: 'March 22, 2019', link: 'https://atlantajewishtimes.timesofisrael.com/mississippi-jews-cite-camp-as-jewish-touchstone/' },
		{ id: 'awwe3', title: 'Atlanta Jewish Times', content: 'The Little Engine that Could', date: 'January 16, 2019', link: 'https://atlantajewishtimes.timesofisrael.com/romes-rodeph-sholom-is-like-the-little-engine-that-could/' },
		{ id: 'awwe3', title: 'New Castle News', content: "Temple Hadar Israel gone for a year, but synagogue's impact still felt", date: 'December 10, 2018', link: 'https://www.ncnewsonline.com/news/local_news/temple-hadar-israel-gone-for-a-year-but-synagogue-s/article_1a9cbf5e-fed1-54eb-8730-8559e48dc616.html' },
		{ id: 'awwe3', title: 'Atlanta Jewish Times', content: 'Fitzgerald Georgia Hebrew Center', date: 'November 14, 2018', link: 'https://atlantajewishtimes.timesofisrael.com/home-for-the-holidays/' },
		{ id: 'awwe3', title: 'Atlanta Jewish Times', content: 'Atlanta Extends Lifeline for Flooded Jewish Community', date: 'October 5, 2018', link: 'https://atlantajewishtimes.timesofisrael.com/atlanta-extends-lifeline-for-flooded-jewish-community/' },
		{ id: 'awwe3', title: 'Atlanta Jewish Times', content: 'Waycross Georgia Jewish Center', date: 'September 28, 2018', link: 'https://atlantajewishtimes.timesofisrael.com/waycross-jewish-small-town-start/' },
		{ id: 'awwe3', title: 'Atlanta Jewish Times', content: 'Bainbridge Temple Prepares for Its Final Chapter', date: 'August 16, 2018', link: 'https://atlantajewishtimes.timesofisrael.com/the-demise-of-bainbridges-jewish-community/' },
		{ id: 'awwe3', title: 'Ohio Jewish Chronicle Article', content: '', date: 'June 14, 2018', link: 'https://now.dirxion.com/Ohio_Jewish_Chronicle/library/Ohio_Jewish_Chronicle_06_14_2018.pdf#page=1&viewrect=89,63,500,500&highlight=289,406,816,808' },
		{ id: 'awwe3', title: 'Tablet Magazine', content: 'Saying Kaddish for a Rust Belt Congregation', date: 'January 8, 2019', link: 'https://www.tabletmag.com/jewish-life-and-religion/277334/kaddish-for-a-rust-belt-congregation' },
		{ id: 'awwe3', title: 'Jewish Telegraphic Agency', content: "A Rust Belt synagogue 'runs out of people' and gathers to bury its past", date: 'January 4, 2018', link: 'https://www.jta.org/2018/01/04/united-states/a-rust-belt-synagogue-runs-out-of-people-and-gathers-to-bury-its-past?mpweb=1161-2411-27286&utm_source=JTA%20Maropost&utm_campaign=JTA&utm_medium=email' },
		{ id: 'awwe3', title: 'Pittsburgh JewishChronicle', content: 'New Castle congregation closes but offers help to worldwide Jewish communities', date: 'December 6, 2017', link: 'https://jewishchronicle.timesofisrael.com/new-castle-congregation-closes-but-offers-help-to-worldwide-jewish-communities/' },
		{ id: 'awwe3', title: 'Tablet Magazine', content: 'Finding a New Life for Jewish Ritual Objects After a Synagogue Closes', date: 'May 5, 2017', link: 'www.tabletmag.com/jewish-life-and-religion/227821/a-new-life-for-ritual-objects?utm_source=tabletmagazinelist&utm_campaign=a4a8bfd269-EMAIL_CAMPAIGN_2017_05_05&utm_medium=email&utm_term=0_c308bf8edb-a4a8bfd269-206643126' },
		{ id: 'awwe3', title: 'Union for Reform Judaism', content: "One Torah's Journey from Pennsylvania to its New Home in South Carolina", date: 'November 3, 2016', link: 'https://urj.org/blog/2016/11/03/one-torahs-journey-pennsylvania-its-new-home-south-carolina?utm_source=Share&utm_campaign=SC&utm_medium=social&utm_content=Pawlyk' },
		{ id: 'awwe3', title: 'Tablet', content: 'Matchmaker, Matchmaker, Match My Torah', date: 'July 13, 2016', link: 'www.tabletmag.com/jewish-life-and-religion/206815/donating-torahs' },
		{ id: 'awwe3', title: 'Atlanta Jewish Times', content: 'Atlanta Non-profit Helps Polish Torah Get Home', date: 'April 19, 2016', link: 'atlantajewishtimes.com/2016/04/atlanta-nonprofit-helps-polish-torah-get-home/' },
		{ id: 'awwe3', title: 'Pittsburgh Post-Gazette', content: 'From New Castle to Warsaw: A mighty mitzvah', date: 'April 10, 2016', link: 'atlantajewishtimes.com/2016/04/atlanta-nonprofit-helps-polish-torah-get-home/' },
		{ id: 'awwe3', title: 'The Jewish Chronicle', content: "A great mitzvah: New Castle's Torah finds a home in Poland", date: 'April 5, 2016', link: 'thejewishchronicle.net/view/full_story/27147521/article-A-great-mitzvah---New-Castle-s-Torah-finds-a-home-in-Poland?instance=lead_story_left_column' },
		{ id: 'awwe3', title: 'EJewish Philanthropy', content: 'A torah travels from New Castle (PA) to Warsaw (Poland)', date: 'April 4, 2016', link: 'http://ejewishphilanthropy.com/a-torah-travels-from-new-castle-pa-to-warsaw-poland/?utm_source=Tue+Mar+5n***&utm_campaign=Tue+Apr+5&utm_medium=email' },
		{ id: 'awwe3', title: 'The Jerusalem Report', content: 'Perpetuating disappearing congregations', date: 'November 2, 2015', link: 'http://jclproject.org/sites/default/files/Jerusalem-Report-Nov-2015.pdf' },
		{ id: 'awwe3', title: 'TribLive', content: 'Jewish Congregations dwindling, forced to mull viability workshops', date: 'July 3, 2015', link: 'https://archive.triblive.com/local/westmoreland/jewish-congregations-dwindling-forced-to-mull-viability-of-worship-sites/#ixzz3ew3Ds3aL' },
		{ id: 'awwe3', title: 'eJewish Philanthropy', content: 'A Game Plan for Sunsetting Congregations', date: 'October 3, 2013', link: 'http://ejewishphilanthropy.com/a-game-plan-for-sunsetting-congregations/' },
		{ id: 'awwe3', title: 'The Forward', content: 'Struggling To Survive', date: 'July 19, 2011', link: 'https://forward.com/articles/140019/struggling-to-survive/' },
		// { id: 'awwe3', title: 'New York Times', content: 'Small-City Congregations Try to Preserve Rituals of Jewish Life', date: 'December 1, 2010', link: 'https://www.nytimes.com/2010/12/02/us/02wills.html?_r=0' },
	];

	return (
		<div>
			<Helmet>
				<title>Press : Jewish Community Legacy Project</title>
			</Helmet>
			<Parallax source={[Image1, Image2]}>
				<h1>
					PRESS
					{/* <br />
					<span className="subTittle">Lorem ipsum dolor sit amet consectetur</span> */}
				</h1>
			</Parallax>
			<div className="container row">
				<br />
				<br />
				<div className="col s12 m10 offset-m1 l8 offset-l2" style={{ position: 'relative' }}>
					<input type="text" className="rightTagInput" placeholder="Search any key word(s)" name="bla" id="ss" onChange={(e) => setQ(e.target.value)} />
					<button className="inputTag goldBtn">
						<i className="fas fa-search"></i>
					</button>
				</div>
				<div className="clearfix"></div>
				<br />
				{/* <img src={interHome} /> */}
				{/* ------------------------- */}

				{/* <div className="col s6 m4 l3">
					<div className="card square">
						<div className="card-content content">
							<div className={styles.card_content}>
								<span className="card-title JCLP-blue-text ">
									<span style={{ fontWeight: '400' }}>B'nai Brith Magazine Winter 2021</span>
								</span>
								<p style={{ margin: '0' }}>November 30, 2021</p>

								<a className="pressReadMore" href="" target="_blank">
									Read More
								</a>
							</div>
						</div>
					</div>
				</div>

				<div className="col s6 m4 l3">
					<div className="card square">
						<div className="card-content content">
							<div className={styles.card_content}>
								<span className="card-title JCLP-blue-text ">
									<span style={{ fontWeight: '400' }}>Small Local Shuls get an Assist from Jewish Community Legacy Project</span>
								</span>
								<p style={{ margin: '0' }}>08/03/2021</p>

								<a className="pressReadMore" href="" target="_blank">
									Read More
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="col s6 m4 l3">
					<div className="card square">
						<div className="card-content content">
							<span className="card-title JCLP-blue-text ">
								<span style={{ fontWeight: '400' }}>Pittsburgh Jewish Chronicle</span>
							</span>
							<p style={{ margin: '0' }}>08/03/2021</p>

							<p>
								<i>"Romes Rodeph Sholom is Like The Little Engine That Could"</i>
							</p>

							<a className="pressReadMore" href="" target="_blank">
								Read More
							</a>
						</div>
					</div>
				</div>
				<div className="col s6 m4 l3">
					<div className="card square">
						<div className="card-content content">
							<span className="card-title JCLP-blue-text ">
								<span style={{ fontWeight: '400' }}>Pittsburgh Jewish Chronicle</span>
							</span>
							<p style={{ margin: '0' }}>08/03/2021</p>

							<p>
								<i>"Romes Rodeph Sholom is Like The Little Engine That Could"</i>
							</p>

							<a className="pressReadMore" href="" target="_blank">
								Read More
							</a>
						</div>
					</div>
				</div> */}
				{/* ------------------------- */}
				{data
					.filter((p) => {
						if (!q) return true;

						if (p.title.toLowerCase().search(q.toLowerCase()) >= 0 || p.quote.toLowerCase().search(q.toLowerCase()) >= 0) return true;
						return false;
					})
					.map((p, i) => (
						<div className="col s6 m4 l3" key={i}>
							<div className="card square">
								<div className="card-content content">
									<div className={styles.card_content}>
										<span className="card-title JCLP-blue-text ">
											<span style={{ fontWeight: '400' }}>{p.title}</span>
										</span>
										<p style={{ margin: '0' }}>{p.datestring}</p>
										{p.quote && p.quote.length > 0 && (
											<p>
												<i>{p.quote && `"${p.quote}"`}</i>
											</p>
										)}
										<a className="pressReadMore" href={p.link} target="_blank">
											Read More
										</a>
									</div>
								</div>
							</div>
						</div>
					))}
				<div className="clearfix"></div>
				<br />
				<br />
			</div>
		</div>
	);
};

const PageLoader = () => {
	const [data, setData] = useState([]);
	const [fetching, setFetching] = useState(false);
	console.log(data);

	useEffect(() => {
		const get = () => {
			const data = { page: 'press' };

			setFetching(true);
			Axios({
				method: 'POST',
				url: `${API_URL}resources.cfc?method=get`,
				headers: {
					'Content-Type': 'application/json',
				},
				data,
			})
				.then((r) => r.status === 200 && r.data)
				.then((d) => {
					setData(d.data);
					setFetching(false);
				});
		};

		get();
	}, []);

	return fetching ? <>LOADING . . .</> : <Page data={data} />;
};

// export default Page;
export default PageLoader;
