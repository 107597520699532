export const createReducer = () => ForumReducer;

export const ForumReducer = (state, action) => {
	switch (action.type) {
		case 'SET_CATEGORIES':
			return {
				...state,
				categories: action.payload,
			};
		case 'SET_CATEGORY_FOCUS':
			return {
				...state,
				category_focus: Number(action.payload),
			};
		case 'SET_TOPICS':
			return {
				...state,
				topics: action.payload,
			};
		case 'SET_QUOTED':
			return {
				...state,
				quoted: action.payload,
			};

		case 'SET_POSTS':
			console.log('payload', action.payload);

			const posts = action.payload.reduce(
				(f, i) =>
					f.concat([
						{
							...i,
							quoted: {
								message: i.q_message,
								firstname: i.q_firstname,
								lastname: i.q_lastname,
								id: i.q_id,
							},
						},
					]),
				[]
			);

			return {
				...state,
				posts: posts,
			};
		default:
			return state;
	}
};
