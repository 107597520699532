import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import M from 'materialize-css';

const shadow = 'rgba(0,0,0,.6)';

const useProgressiveImage = (src) => {
	const [sourceLoaded, setSourceLoaded] = React.useState(null);

	React.useEffect(() => {
		const img = new Image();
		img.src = src;
		img.onload = () => setSourceLoaded(src);
	}, [src]);

	return sourceLoaded;
};

export const Hero = ({ source, placeholder = 'https://via.placeholder.com/1440x1000.png?text=JCLProject.org', noShade = false, height = 400, children }) => {
	const loaded = useProgressiveImage(source);
	const uuid = uuidv4();

	const style = {
		overflow: 'hidden',
		position: 'relative',
		height: height && `${height}px`,
	};

	return (
		<div className="parallax-container row" style={style}>
			<div className={` parallax-${uuid}`}>
				<img src={loaded || placeholder} alt="" className="responsive-img" style={{ width: '100%' }} />
			</div>
			<div style={{ left: 0, top: 0, width: '100%', height: '100%', backgroundColor: noShade ? 'transparent' : shadow, position: 'absolute' }} className="valign-wrapper">
				{children}
			</div>
		</div>
	);
};

function debounce(fn, ms) {
	let timer;
	return (_) => {
		clearTimeout(timer);
		timer = setTimeout((_) => {
			timer = null;
			fn.apply(this, arguments);
		}, ms);
	};
}

export const CssHero = ({ source = [], placeholder = 'https://via.placeholder.com/1440x1000.png?text=JCLProject.org', noShade = false, height = 400, children }) => {
	const [cssImage, setCssImage] = React.useState({
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		height: '100%',
	});

	const style = { overflow: 'hidden', position: 'relative', height: height && `${height}px` };
	const uuid = uuidv4();

	const handleResize = debounce(() => {
		const _source = source[window.innerWidth < 1200 ? 0 : 1];

		// const loaded = useProgressiveImage(_source);
		const loaded = _source;

		setCssImage({
			...cssImage,
			backgroundImage: `url(${loaded})`,
		});
	}, 1000);

	React.useEffect(() => {
		window.addEventListener('resize', handleResize);

		return (_) => window.removeEventListener('resize', handleResize);
	});

	React.useEffect(() => {
		handleResize();
		// return (_) => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<div className="parallax-container row" style={style}>
			<div className={` parallax-${uuid}`} style={cssImage}>
				{/* <img src={loaded || placeholder} alt="" className="responsive-img" style={{ width: '100%' }} /> */}
			</div>
			<div style={{ left: 0, top: 0, width: '100%', height: '100%', backgroundColor: noShade ? 'transparent' : shadow, position: 'absolute' }} className="valign-wrapper">
				{children}
			</div>
		</div>
	);
};

export const Parallax = ({ source, placeholder = 'https://via.placeholder.com/1440x1000.png?text=JCLProject.org', noShade = false, height = null, children, pushdown = null }) => {
	const loaded = useProgressiveImage(source);
	const uuid = uuidv4();

	React.useEffect(() => {
		M.Parallax.init(document.querySelector(`.parallax-${uuid}`), {});
	}, []);

	return (
		<div className="parallax-container" style={height && { height: `${height}px` }}>
			<div className={`parallax parallax-${uuid}`}>
				<img src={loaded || placeholder} alt="" />
			</div>
			<div style={{ width: '100%', height: '100%', backgroundColor: noShade ? 'transparent' : shadow, position: 'absolute' }} className="valign-wrapper">
				{children}
			</div>
		</div>
	);
};

export default Parallax;
