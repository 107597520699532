import React, { useState } from 'react';
import M from 'materialize-css';

const Form = ({ setEnabled, register, errors, method, setMethod, onSubmit }) => {
	React.useEffect(() => {
		// M.Sidenav.init(document.getElementById('mobile-nav'));
		// const elems = document.querySelectorAll('.collapsible');
		if (method === 'payeezy') {
			const elems = document.querySelectorAll('select');
			const instances = M.FormSelect.init(elems, []);
		}
	}, [method]);

	const [clicked, setClicked] = useState('');

	const handleRadioChange = (e) => {
		// console.log('hi');
		if (e.currentTarget.value === ' Tribute') {
			setClicked(true);
			// console.log('hi');
		} else if (e.currentTarget.value === ' Donation') {
			setClicked(false);
			// console.log('2');
		}
	};

	const year = new Date().getFullYear();
	const years = Array.from(new Array(20), (val, index) => index + year);

	return (
		<>
			<fieldset>
				<legend>Donor Information</legend>
				<div className="row">
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="donor.firstname" id="donor.firstname" ref={register({ required: 'First Name is required' })} />
						<label htmlFor="donor.firstname">First Name *</label>
						{errors && errors.donor && errors.donor.firstname && <span className="red-text helper-text">{errors.donor.firstname.message}</span>}
					</div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="donor.lastname" id="donor.lastname" ref={register({ required: 'Last Name is required' })} />
						<label htmlFor="donor.lastname">Last Name *</label>
						{errors && errors.donor && errors.donor.lastname && <span className="red-text helper-text">{errors.donor.lastname.message}</span>}
					</div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="donor.organization" id="donor.organization" ref={register} />
						<label htmlFor="donor.organization">Organization</label>
						{/* {errors && errors.donor && errors.donor.organization && <span className="red-text helper-text">{errors.donor.organization.message}</span>} */}
					</div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="donor.title" id="donor.title" ref={register} />
						<label htmlFor="donor.title">Title</label>
						{/* {errors && errors.donor && errors.donor.title && <span className="red-text helper-text"> {errors.donor.title.message}</span>} */}
					</div>
				</div>
			</fieldset>
			<br />
			<fieldset>
				<legend>Billing Information</legend>
				<div className="row">
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="billing.firstname" id="billing.firstname" ref={register({ required: 'First Name is required' })} />
						<label htmlFor="billing.firstname">First Name *</label>
						{errors && errors.billing && errors.billing.firstname && <span className="red-text helper-text">{errors.billing.firstname.message}</span>}
					</div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="billing.lastname" id="billing.lastname" ref={register({ required: 'Last Name is required' })} />
						<label htmlFor="billing.lastname">Last Name *</label>
						{errors && errors.billing && errors.billing.lastname && <span className="red-text helper-text">{errors.billing.lastname.message}</span>}
					</div>
					<div className="clearfix"></div>
					<div className="input-field col s12 m6 l6 ">
						<input type="email" name="billing.email" id="billing.email" ref={register({ required: 'Email is required' })} />
						<label htmlFor="billing.email">Email *</label>
						{errors && errors.billing && errors.billing.email && <span className="red-text helper-text">{errors.billing.email.message}</span>}
					</div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="billing.phone" id="billing.phone" ref={register({ required: 'Phone is required' })} />
						<label htmlFor="billing.phone">Phone *</label>
						{errors && errors.billing && errors.billing.phone && <span className="red-text helper-text">{errors.billing.phone.message}</span>}
					</div>
					<div className="clearfix"></div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="billing.address" id="billing.address" ref={register({ required: 'Address is required' })} />
						<label htmlFor="billing.address">Address *</label>
						{errors && errors.billing && errors.billing.address && <span className="red-text helper-text">{errors.billing.address.message}</span>}
					</div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="billing.apt" id="billing.apt" ref={register} />
						<label htmlFor="billing.apt">Apt, Suite, Bldg. (optional)</label>
					</div>
					<div className="clearfix"></div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="billing.city" id="billing.city" ref={register({ required: 'City is required' })} />
						<label htmlFor="billing.city">City *</label>
						{errors && errors.billing && errors.billing.city && <span className="red-text helper-text">{errors.billing.city.message}</span>}
					</div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="billing.state" id="billing.state" ref={register({ required: 'State / Province / Region is required' })} />
						<label htmlFor="billing.state">State / Province / Region *</label>
						{errors && errors.billing && errors.billing.state && <span className="red-text helper-text">{errors.billing.state.message}</span>}
					</div>
					<div className="clearfix"></div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="billing.zip" id="billing.zip" ref={register({ required: 'Postal / Zip Code is required' })} />
						<label htmlFor="billing.zip">Postal / Zip Code *</label>
						{errors && errors.billing && errors.billing.zip && <span className="red-text helper-text">{errors.billing.zip.message}</span>}
					</div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="billing.country" id="billing.country" ref={register({ required: 'Country is required' })} />
						<label htmlFor="billing.country">Country *</label>
						{errors && errors.billing && errors.billing.country && <span className="red-text helper-text">{errors.billing.country.message}</span>}
					</div>
					<div className="clearfix"></div>
				</div>
				<br />
			</fieldset>
			<br />
			<fieldset>
				<legend>Gift Information</legend>
				<div className="row">
					<div className="input-field col s12 m6 l6 ">
						<input type="number" step="0.01" min="0" name="gift.amount" id="gift.amount" ref={register({ required: 'Donation Amount is required' })} />
						<label htmlFor="gift.amount">Donation Amount *</label>
						{errors && errors.gift && errors.gift.amount && <span className="red-text helper-text">{errors.gift.amount.message}</span>}
					</div>
					<div className="input-field col s12 m6 l6">
						<p style={{ fontSize: '16px', margin: '0' }}>
							Gift Type
							<br />
							<label>
								<input ref={register} id="" onChange={handleRadioChange} type="radio" name="gift.type" value=" Donation" />
								<span style={{ margin: '0 15px 0 0px', paddingLeft: '30px' }}>Donation</span>
							</label>
							{/* <label>
												<input ref={register} id="" type="radio" name="gift.type" value=" In-Kind Donation" />
												<span style={{ margin: '0 15px 0 0px', paddingLeft: '30px' }}>In-Kind Donation</span>
											</label> */}
							<label>
								<input ref={register} id="" onChange={handleRadioChange} type="radio" name="gift.type" value=" Tribute" />
								<span style={{ margin: '0 15px 0 0px', paddingLeft: '30px' }}>Tribute</span>
							</label>
						</p>
					</div>
					<div className="clearfix"></div>
					{/* <div className="input-field col s12 m6 l6">
								<p style={{ fontSize: '16px', margin: '0' }}>
									Monthly Recurring Donation?
									<label for="gift.recurring">
										<input id="gift.recurring" ref={register} id="indeterminate-checkbox" type="checkbox" name="gift.recurring" value={true} />
										<span style={{ margin: '0 20px 0 10px' }}>&nbsp;</span>
									</label>
								</p>
							</div> */}
					{/* <div className="input-field col s12 m6 l6">
						<p style={{ fontSize: '16px', margin: '0' }}>
							Send acknowledgement of gift?
							<label>
								<input ref={register} id="indeterminate-checkbox" type="checkbox" name="gift.acknowledgementof" value="" />
								<span style={{ margin: '0 20px 0 10px' }}>&nbsp;</span>
							</label>
						</p>
					</div> */}
					<div className={`${clicked ? 'show' : 'hide'}`}>
						<div className="input-field col s12 m6 l6">
							<p style={{ fontSize: '16px', margin: '0' }}>Please send an acknowledgement of my memorial/honor gift to:</p>
						</div>
						<div className="input-field col s12 m6 l6">
							<p style={{ fontSize: '16px', margin: '0' }}>
								<label>
									<input ref={register} id="" type="radio" name="gift.acknowledgement" value=" in honor of" />
									<span style={{ margin: '0 15px 0 0px', paddingLeft: '30px' }}>In Honor of</span>
								</label>
								<label>
									<input ref={register} id="" type="radio" name="gift.acknowledgement" value=" in memory of" />
									<span style={{ margin: '0 15px 0 0px', paddingLeft: '30px' }}>In Memory of</span>
								</label>
								<label>
									<input ref={register} id="" type="radio" name="gift.acknowledgement" value=" Mazel Tov" />
									<span style={{ margin: '0 15px 0 0px', paddingLeft: '30px' }}>Mazel Tov</span>
								</label>
							</p>
						</div>
						<div className="input-field col s12 m6 l6 ">
							<input type="text" name="memory.firstname" id="memory.firstname" ref={register} />
							<label htmlFor="memory.firstname">For : First Name</label>
						</div>
						<div className="input-field col s12 m6 l6 ">
							<input type="text" id="memory.lastname" name="memory.lastname" ref={register} />
							<label htmlFor="memory.lastname">For : Last Name</label>
						</div>
					</div>
					<div className="input-field col s12 m12 l12">
						<textarea className="materialize-textarea" name="gift.message" style={{ height: '100px' }} ref={register}></textarea>
						<label>Message to Recipient</label>
					</div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="gift.firstname" ref={register} />
						<label htmlFor="gift.firstname">Recipient First Name</label>
					</div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="gift.lastname" ref={register} />
						<label htmlFor="gift.lastname">Recipient Last Name</label>
					</div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="gift.organization" ref={register} />
						<label htmlFor="gift.organization">Organization</label>
					</div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="gift.title" ref={register} />
						<label htmlFor="gift.title">Title</label>
					</div>
					<div className="clearfix"></div>
					<div className="input-field col s12 m6 l6 ">
						<input type="email" name="gift.email" ref={register} />
						<label htmlFor="gift.email">Email</label>
					</div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="gift.phone" ref={register} />
						<label htmlFor="gift.phone">Phone</label>
					</div>
					<div className="clearfix"></div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="gift.address" ref={register} />
						<label htmlFor="gift.address">Address</label>
					</div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="gift.apt" ref={register} />
						<label htmlFor="gift.apt">Apt, Suite, Bldg. (optional)</label>
					</div>
					<div className="clearfix"></div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="gift.city" ref={register} />
						<label htmlFor="gift.city">City</label>
					</div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="gift.state" ref={register} />
						<label htmlFor="gift.state">State / Province / Region</label>
					</div>
					<div className="clearfix"></div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="gift.zip" ref={register} />
						<label htmlFor="gift.zip">Postal / Zip Code</label>
					</div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="gift.country" ref={register} />
						<label htmlFor="gift.country">Country</label>
					</div>
				</div>
			</fieldset>
			<br />
			<fieldset>
				<legend>Matching Gift Information</legend>
				<div className="row">
					<div className="input-field col s12 m12 l12">
						<p style={{ fontSize: '16px', margin: '0' }}>Many companies have matching gift programs that will double or triple the amount of your gift. For details about your company's policy, please check with your personnel office.</p>
					</div>

					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="matchinggift.company" ref={register} />
						<label htmlFor="matchinggift.company">Company Name</label>
					</div>

					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="matchinggift.address" ref={register} />
						<label htmlFor="matchinggift.address">Company Address</label>
					</div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="matchinggift.apt" ref={register} />
						<label htmlFor="matchinggift.apt">Apt, Suite, Bldg. (optional)</label>
					</div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="matchinggift.city" ref={register} />
						<label htmlFor="matchinggift.city">City</label>
					</div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="matchinggift.state" ref={register} />
						<label htmlFor="matchinggift.state">State / Province / Region</label>
					</div>
					<div className="input-field col s12 m6 l6 ">
						<input type="text" name="matchinggift.zip" ref={register} />
						<label htmlFor="matchinggift.zip">Postal / Zip Code</label>
					</div>
				</div>
			</fieldset>
			{/* 
			************************************** NEWSLETTER AND FORUM SIGNUP OPTION
			<br />
			<fieldset>
				<legend>Keep Informed</legend>
				<div className="row">
					<div className="input-field col s12 m6 l6 ">
						<p style={{ fontSize: '16px', margin: '0' }}>
							Would you like to sign up for the Newsletter?
							<label>
								<input id="indeterminate-checkbox" type="checkbox" name="signup.newsletter" ref={register} />
								<span style={{ marginLeft: '10px' }}>&nbsp;</span>
							</label>
						</p>
					</div>
					<div className="input-field col s12 m6 l6 ">
						<p style={{ fontSize: '16px', margin: '0' }}>
							Would you like to register for the Forum?
							<label>
								<input id="indeterminate-checkbox" type="checkbox" name="signup.forum" ref={register} />
								<span style={{ marginLeft: '10px' }}>&nbsp;</span>
							</label>
						</p>
					</div>
				</div>
			</fieldset> */}
			<br />
			<fieldset>
				<legend>Payment Method</legend>
				<div className="flex fill center-align">
					{/*<div>
						<button type="button" onClick={() => setMethod('paypal')} class={`waves-effect waves-light btn-large`} style={method === 'paypal' ? { background: '#002677' } : {}}>
							PAYPAL
						</button>
		</div>*/}
					<div>
						<button type="button" onClick={() => setMethod('payeezy')} class={`waves-effect waves-light btn-large`} style={method === 'payeezy' ? { background: '#002677' } : {}}>
							Credit Card
						</button>
					</div>
				</div>
			</fieldset>

			{method === 'payeezy' && (
				<>
					<br />
					<fieldset>
						<legend>Payment Information</legend>
						<div className="row">
							<div className="input-field col s12">
								<input type="text" id="payment.name" name="payment.name" ref={register({ required: method === 'payeezy' && 'Cardholder Name is required' })} />
								<label htmlFor="payment.name">Cardholder Name *</label>
								{errors && errors.payment && errors.payment.name && <span className="red-text helper-text">{errors.payment.name.message}</span>}
							</div>
							<div className="input-field col s12 m6">
								<select defaultValue="" id="payment.type" name="payment.type" ref={register({ required: method === 'payeezy' && 'Card Type is required' })}>
									<option value="American Express">American Express</option>
									<option value="Visa">Visa</option>
									<option value="Mastercard">Mastercard</option>
									<option value="JCB">JCB</option>
									<option value="Discover">Discover</option>
								</select>
								<label htmlFor="payment.type">Card Type *</label>
								{errors && errors.payment && errors.payment.type && <span className="red-text helper-text">{errors.payment.type.message}</span>}
							</div>
							<div className="input-field col s12 m6 l6">
								<input type="text" name="payment.cc" id="payment.cc" ref={register({ required: method === 'payeezy' && 'Card Number is required' })} />
								<label htmlFor="payment.cc">Card Number *</label>
								{errors && errors.payment && errors.payment.cc && <span className="red-text helper-text">{errors.payment.cc.message}</span>}
							</div>
							<div className="input-field col s12 m4 l4">
								<select defaultValue="" id="payment.month" name="payment.month" ref={register({ required: method === 'payeezy' && 'Expiration Month is required' })}>
									<option value={1}>January</option>
									<option value={2}>February</option>
									<option value={3}>March</option>
									<option value={4}>April</option>
									<option value={5}>May</option>
									<option value={6}>June</option>
									<option value={7}>July</option>
									<option value={8}>August</option>
									<option value={9}>September</option>
									<option value={10}>October</option>
									<option value={11}>November</option>
									<option value={12}>December</option>
								</select>
								<label>Expiration Month *</label>
								<span className="red-text helper-text">{errors && errors.payment && errors.payment.month && errors.payment.month.message}</span>
							</div>
							<div className="input-field col s12 m4 l4">
								<select defaultValue="" id="payment.year" name="payment.year" ref={register({ required: method === 'payeezy' && 'Expiration Month is required' })}>
									{years.map((year, index) => (
										<option key={`year${index}`} value={year}>
											{year}
										</option>
									))}
								</select>
								<label>Expiration Year *</label>
								<span className="red-text helper-text">{errors && errors.payment && errors.payment.year && errors.payment.year.message}</span>
							</div>
							<div className="input-field col s12 m4 l4">
								<input type="text" name="payment.cvv" id="payment.cvv" ref={register({ required: method === 'payeezy' && 'Card Number is required' })} />
								<label htmlFor="payment.cvv">Security Code *</label>
								{errors && errors.payment && errors.payment.cvv && <span className="red-text helper-text">{errors.payment.cvv.message}</span>}
							</div>
							<div className="clearfix"></div>
						</div>
						<div className="row">
							<div className="center-align col s12">
								<button class="waves-effect waves-light btn-large">SEND Donation</button>
							</div>
						</div>
					</fieldset>
				</>
			)}
		</>
	);
};

export default Form;
