import React from 'react';
import { Link } from 'react-router-dom';
import img01 from '../../images/conection01.jpg';
import img02 from '../../images/conection02.jpg';
import Parallax from '../../components/LazyParallax';
import PaImg from '../../images/cong-conn.jpg';
import useGtag from '../../hooks/useGtag';
import { Helmet } from 'react-helmet';

const Page = () => {
	useGtag();

	return (
		<div>
			<Helmet>
				<title>Congregation Connections : Jewish Community Legacy Project</title>
			</Helmet>
			<Parallax source={PaImg}>
				<h1>
					CONGREGATION
					<br />
					<span className="JCLP-green-text">CONNECTIONS</span>
				</h1>
			</Parallax>
			<div className="container content800">
				<br />
				<br />
				{/* <img src={interHome} /> */}

				{/* <h2 className="JCLP-blue-text">CONGREGATION CONNECTIONS</h2> */}
				<p className="black-text text500">Facilitated, Peer Congregation Network Collaboration</p>
				<p>
					With <b className="black-text">Congregation</b> <b className="JCLP-green-text">Connections</b>, JCLP is the convener, facilitator and provider of expert resources and solution-oriented support for peer congregation networks through ongoing, collaborative forums. These regional congregation cohorts are THE destination for congregations looking for a peer community, networking
					opportunities, operational resources and problem-solving support. Monthly cohort meetings provide the opportunity for congregation leadership – both professional and lay - to build relationships with other like congregations, share challenges and successes, engage in collaborative programming, and learn from JCLP-provided resources and thought leadership on best practices.
				</p>
				<p>An example of a typical monthly cohort meeting agenda may include:</p>
				<ol>
					<li>Introductions and congregation updates</li>
					<li>Featured Speaker on a relevant topic of mutual interest</li>
					<li>Round Table Discussion on topic at hand </li>
					<li>JCLP-facilitated discussion on congregation challenges and solution-oriented discussion on best practices</li>
				</ol>
				<p>Should topics fall outside of JCLP’s traditional expertise, JCLP will leverage its extensive partner network with complementary core competencies and bring in needed experts and resources to help consult on relevant topics at hand.</p>
				<p className="center">
					<img src={img01} />
				</p>
				<p>
					The result is proven, real time mentoring from JCLP, associated experts and peer congregations. And, as congregations bond, learn from and become resources to one another, this facilitation also minimizes any isolation that may be felt by small Jewish congregations, searching for colleagues experiencing similar challenges and situations. Finally, a networked ecosystem made up
					of a myriad number of regional cohorts itself is an intensely valuable resource to all individual regional cohorts as they learn and share from one other, all managed by JCLP at the core.
				</p>
				<p className="center">
					<img src={img02} />
				</p>
				<p>
					Click <Link to="/Case-Studies">here</Link> to read a recent case study that illustrates the successful outcomes of JCLP’s <b className="black-text">Congregation</b> <b className="JCLP-green-text">Connections</b> cohort network or <Link to="/Clients">here</Link> to see a list of client congregations. Please <Link to="/Contact">reach out</Link> to learn more about how JCLP can
					help best support your congregation.
				</p>
				<br />
				<br />
			</div>
		</div>
	);
};

export default Page;
