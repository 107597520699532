import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Parallax from '../../components/LazyParallax';
import useGtag from '../../hooks/useGtag';
import Image from '../../images/services.jpg';

const Page = () => {
	useGtag();

	return (
		<div>
			<Helmet>
				<title>Services : Jewish Community Legacy Project</title>
			</Helmet>
			<Parallax source={Image}>
				<h1>
					SERVICES
					<br />
					<span className="subTittle">PLanning for SMALL CONGREGATIONS</span>
				</h1>
			</Parallax>
			<div className="container content800">
				<br />
				<br />
				{/* <img src={interHome} /> */}
				<p>JCLP supports small Jewish congregations through two unique programs, that are offered free of charge. At their core, both CONGREGATION CONTINUITY and CONGREGATION CONNECTIONS help drive increased congregational sustainability and growth in today’s communities. What a powerful legacy.</p>
				<h2>
					<span className="black-text text500">CONGREGATION</span>{' '}
					<Link to="/Congregation-Continuity" className="JCLP-orange-text text500">
						CONTINUITY
					</Link>
				</h2>
				<p>Individualized, One-on-One Congregation Collaboration</p>
				<p>
					With Congregation Continuity, JCLP collaborates with congregations on an individual basis to address a range of needs through to resolution. JCLP’s deep expertise provides an effective one-stop solution for congregations anywhere along the congregational life cycle spectrum, whether in a period of growth, that wishes to be maintained, a stage of decline, that is in need of
					turnaround, or facing ultimate closure, and in need of legacy planning. With experience in daily and long-term congregation operations, fiduciary and asset management, congregation engagement and more, JCLP is THE destination for congregations focused on continuity.
				</p>
				<p>
					<Link className="btn JCLP-orange" to="/Congregation-Continuity">
						Read More
					</Link>
				</p>
				<br />
				<br />
				<h2>
					<span className="black-text text500">CONGREGATION</span>{' '}
					<Link to="/Congregation-Connections" className="JCLP-green-text text500">
						CONNECTIONS
					</Link>
				</h2>
				<p>Facilitated, Peer Congregation Network Collaboration</p>
				<p>
					With Congregation Connections, JCLP is the convener, facilitator and provider of expert resources and solution-oriented support for peer congregation networks through ongoing, collaborative forums. The Congregation Connections regional cohorts are THE destination for congregations looking for a peer community, networking opportunities, operational resources and problem-solving
					support. Monthly cohort meetings provide the opportunity for members to build relationships with other like congregations, share challenges and successes, engage in collaborative programming, and learn from JCLP-provided resources and thought leadership on best practices.
				</p>
				<p>
					<Link className="btn JCLP-green" to="/Congregation-Connections">
						Read More
					</Link>
				</p>

				<br />
				<br />
			</div>
		</div>
	);
};

export default Page;
