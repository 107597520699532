import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { AuthContext } from '../../context/auth-provider';
import { ForumContext } from '../../context/forum-context';
import { PostItem } from './page';

import { useForm } from 'react-hook-form';

const slugify = (string) => {
	const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
	const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
	const p = new RegExp(a.split('').join('|'), 'g');

	return string
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
		.replace(/&/g, '-and-') // Replace & with 'and'
		.replace(/[^\w\-]+/g, '') // Remove all non-word characters
		.replace(/\-\-+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, ''); // Trim - from end of text
};

const CustomEditor = ({ onChange, value, placeholder = '' }) => {
	const modules = {
		toolbar: [[{ header: '1' }, { header: '2' }, { font: [] }], [{ size: [] }], ['bold', 'italic', 'underline', 'strike', 'blockquote'], [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }], ['link', 'image'], ['clean']],
		clipboard: {
			// toggle to add extra line breaks when pasting HTML:
			matchVisual: false,
		},
	};

	const formats = ['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image'];

	return <ReactQuill theme="snow" value={value} onChange={onChange} modules={modules} formats={formats} placeholder={placeholder} />;
};

export const NewPost = ({ topic }) => {
	const { categories, quoted, createPost } = useContext(ForumContext);
	const { user } = useContext(AuthContext);
	const { register, handleSubmit, errors, getValues } = useForm();

	const [preview, setPreview] = useState(null);
	const [message, setMessage] = useState('');

	const onSubmit = (values) => {
		console.log('submitting');
		createPost(
			{
				message: message,
				author: user.id,
				pending: 0,
				topicid: topic.id,
				quoted: quoted && 'id' in quoted ? quoted.id : 0,
			},
			user
		);
		return false;
	};

	let p = '';
	const select_categories = categories.reduce((f, i) => {
		p = i.parentid === 0 ? i.title : p;
		const n = {
			...i,
			title: `${p}: ${i.title}`,
		};

		return i.parentid === 0 ? f : f.concat([n]);
	}, []);

	return (
		<>
			<hr />
			{preview && (
				<>
					<div className="forumTopicList card white">
						<div className="card-content" style={{ padding: '15px 30px 0' }}>
							<h5 style={{ marginBottom: '0' }}>PREVIEW</h5>
						</div>
						<div className="forumEntry card-content">
							<PostItem {...preview} />
						</div>
					</div>
				</>
			)}
			<div className="card" id="create-post">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="card-content">
						{quoted && (
							<>
								<div className="row">
									<div className="col s12">
										<div className="quoted">
											<div className="quoted-from">
												<b>
													{quoted.firstname} {quoted.lastname}
												</b>{' '}
												wrote:
											</div>
											<div dangerouslySetInnerHTML={{ __html: quoted.message }} />
										</div>
									</div>
								</div>
							</>
						)}
						<div className="row">
							<div className="col s12 inpust-field">
								{/* <textarea name="message" id="message" cols="30" rows="10"></textarea> */}
								{message.length > 12 && <label htmlFor="message">New Topic Title</label>}
								<CustomEditor theme="snow" value={message} onChange={setMessage} placeholder="New Post" />
								<span>{errors.title && errors.title}</span>
							</div>
						</div>
					</div>
					<div className="card-action" style={{ display: 'flex' }}>
						<button style={{ marginRight: '10px', backgroundColor: 'transparent' }} className="black-text bttn btn-flat" type="button" onClick={() => user && setPreview({ message, ...getValues(), preview: true, ...user })}>
							preview
						</button>
						<div style={{ marginLeft: 'auto', display: 'flex', justifyContent: 'space-between' }}>
							<button
								className="btn red"
								type="button"
								onClick={() => {
									setMessage('');
								}}
								style={{ marginRight: '10px' }}
							>
								clear
							</button>
							<button className="btn btn-" type="submit">
								POST
							</button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export const NewTopic = () => {
	const { categories, createTopic } = useContext(ForumContext);
	const { user } = useContext(AuthContext);
	const { register, handleSubmit, errors, getValues, reset } = useForm();

	const [message, setMessage] = useState('');

	const history = useHistory();

	const onSubmit = (values) => {
		createTopic(
			{
				...values,
				message,
				category: 10,
				categoryid: 10,
				author: user.id,
			},
			user
		).then((d) => history.push(`/forum/t/${slugify(d.topic.title + '-' + d.topic.id)}`));
		return false;
	};

	let p = '';
	const select_categories = categories.reduce((f, i) => {
		p = i.parentid === 0 ? i.title : p;
		const n = {
			...i,
			title: `${p}: ${i.title}`,
		};

		return i.parentid === 0 ? f : f.concat([n]);
	}, []);

	return (
		<>
			<hr />
			<div className="card" id="create-post">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="card-content">
						<div className="row">
							<div className="col s12 input-field">
								<input type="text" name="title" id="title" ref={register({ required: 'Topic title is required' })} />
								<label htmlFor="title">New Topic Title</label>
								<span className="red-text helper-text">{errors.title && errors.title.message}</span>
							</div>
							{/* <div className="col s12 indut-field">
								<label htmlFor="category">Category</label>
								<select className="browser-default" name="categoryid" ref={register({ required: 'Category is required' })}>
									<option key={'asdasd'} value="">
										SELECT A CATEGORY
									</option>
									{select_categories &&
										select_categories.map((c, i) => (
											<option key={i} value={c.id}>
												{c.title}
											</option>
										))}
								</select>
								<span className="red-text helper-text">{errors.categoryid && errors.categoryid.message}</span>
							</div> */}
						</div>
						<div className="row">
							<div className="col s12 inpust-field">
								{/* <textarea name="message" id="message" cols="30" rows="10"></textarea> */}
								{message.length > 12 && <label htmlFor="message">First Post Content</label>}
								<CustomEditor theme="snow" value={message} onChange={setMessage} placeholder="Start your discussion here. . ." />
							</div>
						</div>
					</div>
					<div className="card-action" style={{ display: 'flex' }}>
						<Link
							to="/forum"
							className="btn red"
							onClick={() => {
								setMessage('');
								reset();
							}}
						>
							cancel
						</Link>
						<div style={{ marginLeft: 'auto', display: 'flex', justifyContent: 'space-between' }}>
							<button
								className="btn red"
								type="button"
								onClick={() => {
									setMessage('');
									reset();
								}}
								style={{ marginRight: '10px' }}
							>
								clear
							</button>
							{/* <button style={{ marginRight: '10px', backgroundColor: 'transparent' }} className="black-text bttn btn-flat" type="button" onClick={() => user && setPreview({ message, ...getValues(), preview: true, ...user })}>
								preview
							</button> */}
							<button className="btn btn-" type="submit">
								POST
							</button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};
