import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useGtag = () => {
	const location = useLocation();

	useEffect(() => {
		window.gtag('event', 'screen_view', { app_name: 'JCLProject.org', screen_name: location.pathname });
		window.gtag('event', 'page_view', { page_path: location.pathname });
	}, []);

	return true;
};

export default useGtag;
