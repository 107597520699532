import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { API_URL } from '../../config';
import { Parallax as SmallParallax, Hero as Parallax } from '../../components/LazyParallax';
// import { Hero as Parallax } from '../../components/LazyParallax';
import PaImg from '../../images/tribute.jpg';
import { Link } from 'react-router-dom';
import useGtag from '../../hooks/useGtag';
import { Helmet } from 'react-helmet';

const Page = ({ data }) => {
	useGtag();

	return (
		<div>
			<Helmet>
				<title>Testimonials : Jewish Community Legacy Project</title>
			</Helmet>
			{window.innerWidth < 706 ? (
				<SmallParallax source={PaImg} height={400}>
					<h1>TESTIMONIALS</h1>
				</SmallParallax>
			) : (
				<Parallax source={PaImg} height={400}>
					<h1>TESTIMONIALS</h1>
				</Parallax>
			)}
			<div className="container content800">
				{/* <div className="center-align">
					<Link to="/Testimonials/videos" className="btn">
						Videos
					</Link>
				</div> */}
				<br />
				{/* <img src={interHome} /> */}
				{data.map((t, i) => (
					<div className="card">
						<div className="card-content">
							<p>
								<i className="material-icons">format_quote</i>
								{t.quote}
								<i className="material-icons">format_quote</i>
							</p>
							<span className="right-align card-title JCLP-blue-text text500">
								<small className="">{t.title}</small>
							</span>
						</div>
					</div>
				))}

				<br />
				<br />
			</div>
		</div>
	);
};

const PageLoader = () => {
	const [data, setData] = useState([]);
	const [fetching, setFetching] = useState(false);

	useEffect(() => {
		const get = () => {
			const data = { page: 'testimonials' };

			setFetching(true);
			Axios({
				method: 'POST',
				url: `${API_URL}resources.cfc?method=get`,
				headers: {
					'Content-Type': 'application/json',
				},
				data,
			})
				.then((r) => r.status === 200 && r.data)
				.then((d) => {
					setData(d.data);
					setFetching(false);
				});
		};

		get();
	}, []);

	return fetching ? <>LOADING . . .</> : <Page data={data} />;
};

export default PageLoader;
