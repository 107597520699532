import React, { useState } from 'react';
import RegisterForm from './register';
import LoginForm from './login';

import './login.scss';

const LoginPage = () => {
	// const [username, setUsername] = useState("");
	// const [password, setPassword] = useState("");
	const [form, setForm] = useState('login');
	const toggle = {
		login: 'register',
		register: 'login',
	};

	const openLogin = (e) => {
		if (e.target.id !== form) setForm(toggle[form]);
	};

	return (
		<div className="login-box">
			<div className="container">
				<div className="stripes">
					<div className="stripe stripe1"></div>
					<div className="stripe stripe2"></div>
					<div className="stripe stripe3"></div>
					<div className="stripe stripe4"></div>
					<div className="stripe stripe5"></div>
					<div className="stripe stripe6"></div>
					<div className="stripe stripe7"></div>
				</div>
				<div className="form-div">
					<div className="form-structor">
						<div className={form === 'login' ? 'signup slide-up' : 'signup'}>
							<p className="form-title" id="signup" onClick={openLogin}>
								<span>or</span>
								Sign Up
							</p>
							<RegisterForm />
							<p style={{ fontSize: '13px', lineHeight: '1.5' }}>
								<b>
									Sign Up Process:
									<br />
								</b>
								1. User fills in information and hit "Sign Up" button.
								<br />
								2. JCLP will review and approve the user before they can login.
								<br />
								3. User will receive an email notifying of approval, then they can login.
							</p>
						</div>
						<div className={form === 'register' ? 'login slide-up' : 'login'}>
							<div className="centerx">
								<p className="form-title" id="login" onClick={openLogin}>
									<span>or</span>Log in
								</p>
								<LoginForm />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
