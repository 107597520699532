import React from 'react';
import { Link } from 'react-router-dom';
import M from 'materialize-css';

// const topStyle = {};

export default class Home extends React.Component {
	componentDidMount() {
		M.Sidenav.init(document.getElementById('mobile-nav'));
	}

	render() {
		return (
			<div className="JCLP-blue">
				<div className="container center white-text" style={{ position: 'relative' }}>
					<footer className="row" style={{ padding: '5px 0', margin: '0' }}>
						<ul className="hide-on-med-and-down">
							<li>
								<Link className="" to="/About">
									About JCLP
								</Link>
								<ul className="foSubNav">
									<li>
										<Link className="" to="/About">
											Mission
										</Link>
									</li>
									<li>
										<Link className="" to="/Board-Advisors">
											Board and Advisors
										</Link>
									</li>
									<li>
										<Link className="" to="/Staff">
											Staff
										</Link>
									</li>
									<li>
										<Link className="" to="/Clients">
											Clients
										</Link>
									</li>
									<li>
										<Link className="" to="/Partners">
											Partners
										</Link>
									</li>
								</ul>
							</li>
							<li>
								<Link className="" to="/Services">
									SERVICES
								</Link>
								<ul className="foSubNav">
									<li>
										<Link to="/Congregation-Continuity">CONGREGATION CONTINUITY</Link>
									</li>
									<li>
										<Link to="/Congregation-Connections">CONGREGATION CONNECTIONS</Link>
									</li>
									<li>
										<Link to="/Faq">FAQ</Link>
									</li>
								</ul>
							</li>
							<li>
								{/* <Link className="" to="/"> */}
								<b>RESOURCES</b>
								{/* </Link> */}
								<ul className="foSubNav">
									<li>
										<Link to="/Blog">Blog</Link>
									</li>
									<li>
										<Link to="/Case-Studies">Case Studies</Link>
									</li>
									<li>
										<Link to="/Newsletters">Newsletters</Link>
									</li>
									<li>
										<Link to="/Press">Press</Link>
									</li>
									<li>
										<Link to="/Testimonials">Testimonials</Link>
									</li>
									<li>
										<Link to="/Video-Programs">Video Programs</Link>
									</li>
									<li>
										<Link to="/Webinars">Webinars</Link>
									</li>
								</ul>
							</li>
							<li>
								<Link className="" to="/Forum">
									FORUM
								</Link>
								{/* <ul className="foSubNav">
									<li>
										<Link to="/">Login</Link>
									</li>
									<li>
										<Link to="/">Register</Link>
									</li>
								</ul> */}
							</li>
							<li>
								<Link className="" to="/Contact">
									CONTACT
								</Link>
								<ul className="foSubNav">
									<li>
										<Link to="/Contact">Get In Touch</Link>
									</li>
									<li>
										<Link to="/login">Forum Login/Register</Link>
									</li>
								</ul>
							</li>

							<li>
								<Link className="deep-orange-text text-darken-1" to="/Donation">
									DONATE
								</Link>
								{/* <ul className="foSubNav">
									<li>
										<Link to="/">Donation</Link>
									</li>
									<li>
										<Link to="/">In-Kind Support</Link>
									</li>
									<li>
										<Link to="/">Pledge</Link>
									</li>
									<li>
										<Link to="/">Tribute</Link>
									</li>
								</ul> */}
							</li>
						</ul>
						<br />
						<p>
							<b>FOR MORE INFORMATION</b>
							<br />
							The Jewish Community Legacy Project
							<br />
							678-429-8895
							<br />
							<a href="mailto:info@jclproject.org" className="white-text">
								info@jclproject.org
							</a>
						</p>
						<br />

						<p
							style={{
								fontSize: '14px',
								marginTop: '25px',
							}}
						>
							&copy; 2021 Jewish Community Legacy Project is a 501 (c)(3) corporation | Crafted and Hosted by{' '}
							<a href="https://thesabresolution.com/" rel="noopener noreferrer" target="_blank" className="white-text">
								Sabre Creative
							</a>
						</p>
					</footer>
				</div>
			</div>
		);
	}
}
